import { ServerAPIUrl } from "./Env";
const uploadFile = async (file, token) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('token', token);

    return fetch(ServerAPIUrl + "/api/file/upload", {
        method: 'POST',
        body: formData,
    })
        .then(res => res.json())
        .catch(err => {
            console.error(err);
        });
}


export {
   uploadFile
}