
import React, { useEffect, useState } from 'react';
import { getDatasets, deleteDatasets } from '../Request/Datasets';
import { Link } from 'react-router-dom';
import { ConfirmationModel } from '../../components/modal';
import { toast } from 'react-toastify';
import { RequestErrorHandler } from '../Request/ErrorHandler';
export function Datasets(props) {
    const { user } = props;
    const [datasets, setDatasets] = useState([]);
    useEffect(() => {
        initTable();
    }, [])

    async function initTable() {
        const res = await getDatasets({
            id: "",
            token: user.token
        });
        if (res.result.code == 1) {
            setDatasets(res.result.data);
        } else {
            RequestErrorHandler(res.result.code);
        }

    }

    const onDeleteDatasets = async (id) => {
        const res = await deleteDatasets({
            id: id,
            token: user.token
        });
        if (res.result.code == 1) {
            toast.success('Brain Deleted', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            initTable();
        } else {
            RequestErrorHandler(res.result.code);
        }

    }

    return (
        <>
            <div className="content-wrapper" id="avatar-page">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">CSA Brains</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid">
                        <div className='row'>
                            <div className="col-lg-8">
                                <div className="card">
                                    <div className="card-body">
                                        <table class="table ">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col"># of Entries</th>
                                                    <th scope="col">Created</th>
                                                    <th scope="col">Updated</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {datasets.map((row, index) => (
                                                    row.data_entries ?
                                                        <tr>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{row.name}</td>
                                                            <td>{row.data_entries.length}</td>
                                                            <td>{row.created_at}</td>
                                                            <td>{row.updated_at}</td>
                                                            <td>

                                                                <button onClick={() => {
                                                                    ConfirmationModel(`Are you sure you want to delete ${row.name} brain?`,
                                                                        () => {
                                                                            onDeleteDatasets(row.datasets_id)
                                                                        }, () => {

                                                                        })
                                                                }} type="button" class="btn btn-danger  float-end btn-sm" >Delete</button>
                                                                <Link to={`/datasets/${row.datasets_id}`}><button type="button" class="btn btn-primary  float-end btn-sm" >Edit</button></Link>
                                                            </td>
                                                        </tr> : <></>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
