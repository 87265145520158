
import DataTable from 'react-data-table-component';
export function DocumentAnalyzerTable(props) {
    const { documentAnalyzerData, onEdit, onDeleteEntry, pending, setDocumentAnalyzerSelectedTableEntries, documentAnalyzerSelectedTableEntries,setDocumentAnalyzerData } = props;
    const columns = [
        {
            name: 'Prompt',
            selector: row => row.prompt,
            sortable: true,
            width: '300px',
            maxWidth: '300px',
            minWidth: '200px',
            wrap: true
        },
        {
            name: 'Content',
            selector: row => row.content,
            sortable: true,
            width: '600px',
            maxWidth: '600px',
            minWidth: '300px',
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };
    const onSelectRow = ({ selectedRows }) => {
        setDocumentAnalyzerSelectedTableEntries(selectedRows)
    };
    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Generated Entries</h5>
                <p className="card-text">
                    This is a visual representation of a collection of data, organized into rows and columns, that provides users with an overview of the data and allows them to interact with it.
                </p>
                <DataTable
                    columns={columns}
                    data={documentAnalyzerData}
                    pagination
                    customStyles={customStyles}
                    progressPending={pending}
                    responsive
                    selectableRows
                    highlightOnHover
                    pointerOnHover
                    expandOnRowClicked
                    expandableRows
                    expandableRowsComponent={(props) => TableExpandableComponent(props, documentAnalyzerData, onEdit, onDeleteEntry)}
                    onSelectedRowsChange={onSelectRow}
                />
            </div>
        </div>
    )
}


const TableExpandableComponent = (props, data, onEdit, onDeleteEntry) => {
    const { prompt, content, id } = props.data;
    const index = data.findIndex(obj => obj.id === id);
    return (
        <div className='table-expand-container'>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p class="prompt">
                            {prompt}
                        </p>
                        <p class="content">
                            {content}
                        </p>
                    </div>
                </div>
            </div>

        </div>
    );
}