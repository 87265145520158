import { ServerBaseAPIUrl } from "../../../pages/Request/Env";

export class analystWebSocket {
    constructor(userToken, analystId = '') {
        this.userToken = userToken;
        this.analystId = analystId;
        this.apiPath = `wss://${ServerBaseAPIUrl}/api/stream-analysis?token=${userToken}${analystId ? `&id=${analystId}` : ''}`
        this.ws = null;
        this.callbacks = {
            onMessagesReceived: null,
            onMessageReceived: null,
            onEventReceived: null,
            onTextReceived: null,
            onTextDoneReceived: null,
            messageDoneReceived: null,
            onFileReceived: null,
            onErrorReceived: null,
            onChartReceived: null,
            onConnected: null, 
        };
    }

    connect(onLoadMessages) {
        this.ws = new WebSocket(this.apiPath);

        this.ws.onopen = () => {
            console.log('Connected to WebSocket');
            if (this.callbacks.onConnected) {
                this.callbacks.onConnected();
            }
        };

        this.ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            this.handleMessage(data);
        };

        this.ws.onclose = () => {
            console.log('Disconnected from WebSocket');
        };

        this.ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };
    }

    disconnect() {
        if (this.ws) {
            this.ws.close();
        }
    }


    handleMessage(data) {
        if (data.messages && this.callbacks.onMessagesReceived) {
            this.callbacks.onMessagesReceived(data.messages);
        } else if (data.message && this.callbacks.onMessageReceived) {
            this.callbacks.onMessageReceived(data.message);
        } else if (data.data) {
            if (data.data.event && this.callbacks.onEventReceived) {
                this.callbacks.onEventReceived(data.data.event);
            } else if (data.data.text && this.callbacks.onTextReceived) {
                this.callbacks.onTextReceived(data.data.text);
            } else if (data.data.textDone && this.callbacks.onTextDoneReceived) {
                this.callbacks.onTextDoneReceived(data.data.textDone);
            } else if (data.data.messageDone && this.callbacks.messageDoneReceived) {
                this.callbacks.messageDoneReceived(data.data.messageDone);
            } else if (data.data.chart && this.callbacks.onChartReceived) {
                this.callbacks.onChartReceived(data.data.chart);
            } else if (data.data.file && this.callbacks.onFileReceived) {
                this.callbacks.onFileReceived(data.data.file);
            } else if (data.data.error && this.callbacks.onErrorReceived) {
                this.callbacks.onErrorReceived(data.data.error);
            }
        }


    }

    setCallback(type, callback) {
        if (this.callbacks[type] !== undefined) {
            this.callbacks[type] = callback;
        } else {
            console.error('Invalid callback type');
        }
    }

    sendMessage(message) {
        this.ws.send(JSON.stringify(message))
    }

}