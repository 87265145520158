import { useEffect } from "react";

const AccountLogout = (props) => {
    const { user } = props;
    useEffect(()=>{
        localStorage.removeItem('gs-token');
        localStorage.removeItem('gs-name');
        window.location.reload();

    })

    return (<p>Please wait, trying to logout...</p>)
}

export {
    AccountLogout
}