import { toast, ToastContainer } from 'react-toastify';

export const RequestErrorHandler = (code) => {
    switch (code) {
        case 0:
            toast.error('Something went wrong! Please try again later.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            break;
        case -1:
            toast.error('Login Session Expired!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            localStorage.removeItem('gs-token');
            localStorage.removeItem('gs-name');
            window.location.reload();
            break;
        case 500:
            toast.error('Error Occured! Please contact admin.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            break;
    }
}