
import React, { useEffect, useState } from 'react';
import { getScene, deleteScene } from '../Request/Scene';
import { Link } from 'react-router-dom';
import { ConfirmationModel } from '../../components/modal';
import { toast } from 'react-toastify';
import { RequestErrorHandler } from '../Request/ErrorHandler';
export function SceneList(props) {
    const { user } = props;
    const [scenes, setScenes] = useState([]);
    const [selectedScene, setSelectedScene] = useState();
    useEffect(() => {
        initTable();
    }, [])

    async function initTable() {
        const res = await getScene({
            id: "",
            token: user.token
        });
        if (res.result.code == 1) {
            setScenes(res.result.data);
        } else {
            RequestErrorHandler(res.result.code);
        }
        
    }

    const onDeleteScene = async (id) => {
        const res = await deleteScene({
            id: id,
            token: user.token
        });
        if (res.result.code == 1) {
            toast.success('Body Deleted', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            initTable();
        } else {
            RequestErrorHandler(res.result.code);
        }
    }

    return (
        <>
            <div className="content-wrapper" id="avatar-page">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">CSA Bodies</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid">
                        <div className='row'>
                            <div className="col-lg-8">
                                <div className="card">
                                    <div className="card-body">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Created</th>
                                                    <th scope="col">Updated</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {scenes.map((row, index) => (
                                                    <tr>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{row.name}</td>
                                                        <td>{row.created_at}</td>
                                                        <td>{row.updated_at}</td>
                                                        <td>
                                                            <button type="button" class="btn btn-danger float-end btn-sm " onClick={() => {
                                                                ConfirmationModel(`Are you sure you want to delete ${row.name} body?`,
                                                                    () => {
                                                                        onDeleteScene(row.scene_id)
                                                                    }, () => {

                                                                    })
                                                            }}>Delete</button>
                                                            <Link to={`/scene/${row.scene_id}`}> <button type="button" className="btn btn-primary float-end btn-sm me-1">Edit</button></Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-5">
                                <div className="card">
                                    <div className="card-body">
                                        {selectedScene ? <dl class="row">

                                            <dd class="col-sm-12">
                                                <button type="button" class="btn btn-danger float-end btn-sm ">Delete</button>
                                                <Link to={`/scene/${selectedScene.scene_id}`}> <button type="button" className="btn btn-primary float-end btn-sm me-1">Edit</button></Link>
                                            </dd>
                                            <dt class="col-sm-3">Scene ID:</dt>
                                            <dd class="col-sm-9">{selectedScene.scene_id}</dd>

                                            <dt class="col-sm-3">Name:</dt>
                                            <dd class="col-sm-9">{selectedScene.name}</dd>

                                            <dt class="col-sm-3">Description:</dt>
                                            <dd class="col-sm-9">{selectedScene.description}</dd>

                                            <dt class="col-sm-3">Camera Attributes:</dt>
                                            <dd class="col-sm-9">
                                                <dl class="row">
                                                    <dt class="col-sm-4">Position</dt>
                                                    <dd class="col-sm-8">{JSON.stringify(JSON.parse(selectedScene.attributes).position)}</dd>
                                                    <dt class="col-sm-4">Rotation</dt>
                                                    <dd class="col-sm-8">{JSON.stringify(JSON.parse(selectedScene.attributes).rotation)}</dd>
                                                </dl>
                                            </dd>
                                            <dt class="col-sm-3">Avatar Model:</dt>
                                            <dd class="col-sm-9">
                                                <dl class="row">
                                                    <dt class="col-sm-4">Model</dt>
                                                    <dd class="col-sm-8">{JSON.stringify(JSON.parse(selectedScene.attributes)["gltf-model"])}</dd>

                                                </dl>
                                            </dd>
                                        </dl>

                                            :
                                            <h4 class="text-center">No Scene Selected</h4>}
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
