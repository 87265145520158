import { ServerAPIUrl } from "./Env";
const addDatasets = (data, callback) => {
    fetch(ServerAPIUrl + "/api/datasets/add", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            datasets: data.datasets,
            datasets_id: data.datasetsId,
            token: data.token,
            name: data.datasetsName,
            description: data.datasetsDescription,
        })
    })
        .then(res => res.json())
        .then(result => {
            callback(result)
        })
        .catch(err => {
            console.error(err);
        });
}

const getDatasets = (data) => {
    return fetch(ServerAPIUrl + "/api/datasets/get", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        // .then(result => {
        //     callback(result)
        // })
        .catch(err => {
            console.error(err);
        });
}

const documentAnalyzer = (data) => {
    return fetch(ServerAPIUrl + "/api/v1/document-analyzer", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        // .then(result => {
        //     callback(result)
        // })
        .catch(err => {
            console.error(err);
        });
}

const deleteDatasets = (data) => {
    return fetch(ServerAPIUrl + "/api/delete/datasets", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(err => {
            console.error(err);
        });
}

const deleteDatasetdocument = (data) => {
    return fetch(ServerAPIUrl + "/api/delete/datasets/document", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(err => {
            console.error(err);
        });
}

const uploadDocument = (data, onResult, onError) => {

    var formdata = new FormData();
    formdata.append("file", data.file, data.file.name);
    formdata.append("dataset_id", data.datasetId);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };


    return fetch(ServerAPIUrl + "/api/dataset/upload/docV2", requestOptions)
        .then(res => {
            console.log(res);
            return res.json()})
        .then(result => {
            onResult(result)
        })
        .catch(err => {
            onError(err);
        });
}

const getDocument = (data) => {
    return fetch(ServerAPIUrl + "/api/datasets/get/doc", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        // .then(result => {
        //     callback(result)
        // })
        .catch(err => {
            console.error(err);
        });
}

const webScrape = async (data) => {
    try {
        const response = await fetch(ServerAPIUrl + "/api/datasets/upload/webscrape", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        const jsonData = await response.json();
        return jsonData;
    } catch (err) {
        console.error(err);
        // Depending on your use case, you might want to rethrow the error or return a default value.
        throw err;  // or return some default/error value
    }
}

export {
    addDatasets, getDatasets, documentAnalyzer, deleteDatasets, uploadDocument, getDocument, webScrape, deleteDatasetdocument
}