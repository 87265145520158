import React, { useEffect, useState } from 'react';
export function Chat(props) {
    const { name, description, messageList, suggestions, showSuggestionList, setShowSuggestionList, onSendMessage, inputMessageValue, setInputMessageValue, isMessageLoading, themeConfig } = props;
    const [isHovered, setIsHovered] = useState(false);
    const setTheme = (role) => {
        switch (role) {
            case "header":
                return { backgroundColor: themeConfig.background, color: themeConfig.fontColor }
                break;
            case "assistant":
                return { backgroundColor: themeConfig.background, color: themeConfig.fontColor, fontSize: `${themeConfig.fontSize}px`, borderRadius: `${themeConfig.borderRadius}px` }
                break;
            case "user":
                return { borderColor: themeConfig.background, color: themeConfig.background, fontSize: `${themeConfig.fontSize}px`, borderRadius: `${themeConfig.borderRadius}px` }
                break;
            case "suggestion":
                return { borderColor: themeConfig.background, fontSize: `${themeConfig.fontSize}px`, borderRadius: `${themeConfig.borderRadius}px` }
                break;
        }
    }
    return <>
        <div className='chat-header-container' style={{ ...setTheme("header"), borderRadius: `${themeConfig.borderRadius}px` }} >
            <p className='title' style={setTheme("header")}>{name ? name : "[Your Chatbot Title]"}</p>
            <p className='description' style={setTheme("header")}>{description ? description : "[Your Chatbot Description]"}</p>
        </div>
        <div className='chat-message-container' id="style-4">
            <ul>
                {
                    messageList?.map((row, index) => (
                        <li >
                            {/* {row.role === "assistant" ? <span class="badge text-start text-bg-secondary ms-2 mb-1">
                                                                        {
                                                                            row.info.predicted_by === 'nlp' ?
                                                                                <>
                                                                                    {row.info ? `Predicted Using: ${row.info.predicted_by}` : ""}<br />
                                                                                    {row.info ? `Similarity: ${row.info.predict_similarity.toFixed(2)}` : ""}

                                                                                </> :
                                                                                <>
                                                                                    {row.info ? `Predicted Using: ${row.info.predicted_by}` : ""}<br />
                                                                                    {row.info ? `Usage Token: ${row.info.usage.total_tokens}` : ""}
                                                                                </>
                                                                        }

                                                                    </span> : ""} */}


                            <p className={row.role} style={setTheme(row.role)}>

                                {row.content}</p>
                        </li>
                    ))
                }
                {
                    showSuggestionList ?
                        <li className='suggestion-list-container'>
                            {
                                suggestions.some((item) => Boolean(item.trim())) ? <p>Select Conversation Starter</p> : <></>
                            }
                            {
                                suggestions?.map((text, index) => (
                                    text ? <SuggestionButtonComponent
                                        setInputMessageValue={setInputMessageValue}
                                        onSendMessage={onSendMessage}
                                        setShowSuggestionList={setShowSuggestionList}
                                        setTheme={setTheme}
                                        themeConfig={themeConfig}
                                        text={text}
                                    /> : <></>
                                ))
                            }

                        </li> : <></>
                }

            </ul>
        </div >
        <div className='chat-input-container'>
            <input type="email" onKeyUp={(event) => {
                if (event.key === 'Enter') {
                    onSendMessage(event.target.value)
                }
            }} disabled={isMessageLoading}
                style={{
                    borderRadius: `${themeConfig.borderRadius}px`,
                }}
                value={inputMessageValue} onChange={(e) => setInputMessageValue(e.target.value)} class="chat-input-form" id="exampleFormControlInput1" placeholder="Type your message here" />
        </div>
    </>
}


const SuggestionButtonComponent = (props) => {
    const { setInputMessageValue, onSendMessage, setShowSuggestionList, themeConfig, setTheme, text } = props;
    const [isHovered, setIsHovered] = useState(false);

    return (
        <button
            type="button"
            onClick={() => {
                setInputMessageValue(text)
                onSendMessage(text)
                setShowSuggestionList(false);
            }}
            class="btn btn-sm suggestion-btn"
            style={{
                ...setTheme("suggestion"),
                backgroundColor: isHovered ? themeConfig.background : "#fff",
                color: isHovered ? themeConfig.fontColor : themeConfig.background,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >{text}</button>
    );
};