import React, { useEffect, useState, useCallback } from 'react';
import { FileUploader } from "react-drag-drop-files";
import csvtojson from 'csvtojson';
import { FileToText } from '../../utils/FileToText';
import { documentAnalyzer } from '../../pages/Request/Datasets';
export function DocumentAnalyzerForms(props) {
    const { setDocumentAnalyzerData,
        setPending, pending, documentResult,
        setDocumentResult, documentAnalyzerData,
        previousDataEntriesResult, setPreviousDataEntriesResult,
        documentAnalyzerSelectedTableEntries, setDocumentAnalyzerSelectedTableEntries,
        datasets,
        setDataset } = props;

    const [isUploadError, setIsUploadError] = useState(false);
    const fileTypes = ["PDF"];


    const handleChange = (file) => {
        setIsUploadError(false);
        FileToText(file, (result) => {
            setDocumentResult(result);
            console.log(result);
        }, (message, error) => {
            console.log(message, error);
        });
    };

    const onGenerate = async () => {
        setPending(true);
        const result = await documentAnalyzer({
            content: documentResult.content,
            context: previousDataEntriesResult
        });
        console.log(result);
        setPending(false);
        setPreviousDataEntriesResult(result.result)
        setDocumentAnalyzerData(result.result);

    }

    return (
        <div>
            <div className="card">
                <div className="card-body">
                    {
                        documentResult.pages ?
                            <AnalyzerFormComponent setDocumentAnalyzerData={setDocumentAnalyzerData} pending={pending} onGenerate={onGenerate} document={documentResult} setDocumentResult={setDocumentResult} />
                            :
                            <FileUploaderComponent handleChange={handleChange} fileTypes={fileTypes} isUploadError={isUploadError} />
                    }

                </div>
            </div>
            {
                documentAnalyzerData?.length ?
                    <div className="card">
                        <div className="card-body">
                            <AddToDatasetsComponent
                                documentAnalyzerSelectedTableEntries={documentAnalyzerSelectedTableEntries}
                                setDocumentAnalyzerSelectedTableEntries={setDocumentAnalyzerSelectedTableEntries}
                                datasets={datasets}
                                setDataset={setDataset}
                                documentAnalyzerData={documentAnalyzerData}
                                setDocumentAnalyzerData={setDocumentAnalyzerData} />

                        </div>
                    </div> :
                    <></>
            }

        </div>
    )
}


const FileUploaderComponent = (props) => {
    const { handleChange, fileTypes, isUploadError } = props;
    return (
        <>
            <FileUploader handleChange={handleChange} classes="upload-drop-file" label="Drop Your PDF File Here" name="file" types={fileTypes} />
            <p className={`error ${isUploadError ? '' : 'hide'}`}>An error occurred while uploading the file. Please ensure that you have followed the instructions provided at the bottom of this page.</p>
            <ol>
                <li>Prepare your PDF: Your PDF should contain all necessary personal and relevant information required for analysis. This includes but is not limited to your contact number, address, etc. Please note that the system will generate any prompts or content needed, so there's no need to include these in your file.</li>
                <li>Upload the PDF: Find the designated drop zone on top of this instructions. Click, hold, and drag your PDF file into this area to upload it.</li>
                <li>File Format: Please ensure your file is in the PDF format. Our system only accepts PDF files. If your document is in a different format, please convert it to PDF before uploading.</li>
                <li>Data Processing: Keep in mind that the time it takes to process your document depends on the amount of information you provide. More data may lead to a longer processing time.</li>
                <li>Review and Submit: Once your file is uploaded and you've reviewed the information, go ahead and hit the generate button. You'll receive a confirmation message when your submission is successful.</li>
                <li>Check the Generated Entries Section: Once your PDF has been processed, the system will generate the necessary prompts and content. You can find these results in the "Generated Entries" section of our website. Please note that the results will be displayed in a table format for easy reference and understanding.</li>
            </ol>

        </>
    );
}

const AnalyzerFormComponent = (props) => {
    const { document, setDocumentResult, onGenerate, setDocumentAnalyzerData, pending } = props;
    return (
        <>
            <div class="row g-3">
                <div class="col-md-6">
                    <label for="inputEmail4" class="form-label">Number of Pages</label>
                    <input type="number" class="form-control" value={document.pages} disabled />
                </div>
                <div class="col-md-6">
                    <label for="inputPassword4" class="form-label">Number of Words / Tokens</label>
                    <input type="number" class="form-control" value={document.content.trim().split(' ').length} disabled />
                </div>

                <div class="col-12">
                    <button class="btn btn-primary" onClick={() => onGenerate()} disabled={pending}>{pending ? "Generating..." : "Generate"}</button>
                    <button class="btn btn-danger" onClick={() => {
                        setDocumentResult({})
                        setDocumentAnalyzerData([])
                    }} >Cancel</button>
                </div>
            </div>
        </>
    );
}

const AddToDatasetsComponent = (props) => {
    const { documentAnalyzerSelectedTableEntries,setDocumentAnalyzerSelectedTableEntries, datasets, setDataset,documentAnalyzerData,setDocumentAnalyzerData
     } = props;
    const onAddToDatasets = () => {
        setDataset([...datasets, ...documentAnalyzerSelectedTableEntries])
        setDocumentAnalyzerSelectedTableEntries([])
        const filteredData = documentAnalyzerData.filter(obj1 => !documentAnalyzerSelectedTableEntries.some(obj2 => obj2.prompt === obj1.prompt));
        setDocumentAnalyzerData(filteredData)
    }
    return (
        <>
            <div class="row g-3">
                <div class="col-md-6 d-flex align-items-center">
                    <div>
                        <h6 className='mb-0'>{(documentAnalyzerSelectedTableEntries.length > 0) ? `${documentAnalyzerSelectedTableEntries.length} Dataset Entries Selected` : "No Dataset Entries Selected"}</h6>
                    </div>
                </div>
                <div class="col-md-6 text-right">
                    <button class="btn btn-primary" onClick={onAddToDatasets} disabled={(documentAnalyzerSelectedTableEntries.length > 0) ? "" : "disabled"}>Move To Datasets</button>
                </div>
            </div>
        </>
    );
}