
import React, { useEffect, useState } from 'react';
import { RegiterComponent } from '../../AFRAME';
import { useNavigate } from 'react-router-dom';
import { getScene } from '../Request/Scene';
import { getDatasets } from '../Request/Datasets';
import { useParams } from 'react-router-dom';
import { sentTestMessage, saveChatbot, getChatbot } from '../Request/Chatbot';
import { ServerAPIUrl } from '../Request/Env';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { RequestErrorHandler } from '../Request/ErrorHandler';
import { generateToken } from '../../utils/helpers';


import { PublishChatbotModal, ConfirmationModal } from '../../components/modal';

import { ChatbotInfoForm } from '../../components/chatbot/chatbotInfoForm';
import { AIConfigForm } from '../../components/chatbot/AIConfigForm';
import { AppearanceForm } from '../../components/chatbot/AppearanceForm';
import { Chat } from '../../components/chatbot/Chat';
import { Deployment } from '../../components/chatbot/Deployment';
import { AdvancedSettings } from '../../components/chatbot/Advanced';
import { Integrations } from '../../components/chatbot/Integrations';
export function CreateChatbot(props) {
    const { user } = props;
    const navigate = useNavigate();
    const { id } = useParams();
    const [camera, setCamera] = useState();
    const [cameraAttributes, setCameraAttributes] = useState({
        position: { x: -0.01, y: 1.13, z: -1.78 },
        rotation: { x: 0.00, y: 0, z: 0.00 }
    });
    const [avatarFileName, setAvatarFileName] = useState("");

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [datasets, setDatasets] = useState([]);
    const [selectedDatasets, setSelectedDatasets] = useState("");
    const [scenes, setScenes] = useState([]);
    const [NLPSimilarity, setNLPSimilarity] = useState(98);
    const [selectedScene, setSelectedScene] = useState("");
    const [isChatGPTEnable, setIsChatGPTEnable] = useState(false);
    const [AIBehaviour, setAIBehaviour] = useState("");
    const [avatarModelComponent, setAvatarModelComponent] = useState({});
    const [token, setToken] = useState("");
    const [status, setStatus] = useState("");
    const [domain, setDomain] = useState("");
    const [chatbotIconUrl, setChatbotIconUrl] = useState("/assets/img/no-image-placeholder.png");
    const [chatbotBackground, setChatbotBackground] = useState("/assets/img/no-image-placeholder.png");

    const [inputMessageValue, setInputMessageValue] = useState("");
    const [messageList, setMessageList] = useState([]);
    const [isMessageLoading, setIsMessageLoading] = useState(false);
    const [animations, setAnimations] = useState({});
    const [formMenuSelected, setFormMenuSelected] = useState(0);
    const [suggestions, setSuggestions] = useState(['']);
    const [showSuggestionList, setShowSuggestionList] = useState(false);
    const [selectedVoiceId, setSelectedVoiceId] = useState("21m00Tcm4TlvDq8ikWAM");
    const [advancedConfig, setAdvancedConfig] = useState({});
    const [integrationsConfig, setIntegrationsConfig] = useState({});
    const [themeConfig, setThemeConfig] = useState({
        background: "#0b1354",
        fontColor: "#ffffff",
        fontSize: 14,
        borderRadius: 0
    });
    const toastId = React.useRef(null);


    useEffect(() => {
        initData();


    }, [])

    async function initData() {
        const resultDatasets = await getDatasets({
            id: "",
            token: user.token
        });
        if (resultDatasets.result.code == 1) {
            setDatasets(resultDatasets.result.data);
        } else {
            RequestErrorHandler(resultDatasets.result.code);
        }


        const resultScenes = await getScene({
            id: "",
            token: user.token
        });
        if (resultScenes.result.code == 1) {
            setScenes(resultScenes.result.data);
        } else {
            RequestErrorHandler(resultScenes.result.code);
        }


        initRequest(resultDatasets.result.data, resultScenes.result.data);
    }

    const initRequest = async (datasets, scenes) => {

        if (id) {
            if (id === "new") {
                setAvatarFileName("amy.glb")
            } else {
                const res = await getChatbot({
                    id: id,
                    token: user.token
                });
                if (!res.result.code == 1) {
                    RequestErrorHandler(res.result.code);
                }
                const chatbot = res.result.data[0];


                setName(chatbot.name);
                setDescription(chatbot.description);
                setNLPSimilarity(chatbot.min_similarity);
                setIsChatGPTEnable(chatbot.enable_openai);
                setAIBehaviour(chatbot.ai_behaviour);
                setToken(chatbot.token);
                setStatus(chatbot.status);
                setDomain(chatbot.domain);

                // Extra Config Init
                const extra_config = JSON.parse(chatbot.config_attributes);
                console.log(extra_config);
                setSuggestions(extra_config.info.suggestionList ? extra_config.info.suggestionList : [""]);
                if (extra_config.appearance) {
                    setThemeConfig(extra_config.appearance);
                }
                if (extra_config.avatar) {
                    setSelectedVoiceId(extra_config.avatar.voice);
                }
                if (extra_config.advanced) {
                    setAdvancedConfig(extra_config.advanced)
                }

                if (extra_config.advanced) {
                    setIntegrationsConfig(extra_config.integrations)
                }





                const datasetsIndex = datasets.findIndex(obj => obj.datasets_id === chatbot.datasets_id);
                const sceneIndex = scenes.findIndex(obj => obj.scene_id === chatbot.scene_id);

                if (datasetsIndex == -1) {
                    toast.error("Datasets selected not exist!", {
                        position: "top-right",
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                    });
                } else {
                    setSelectedDatasets(datasetsIndex)
                }
                if (sceneIndex == -1) {
                    toast.error("Scene selected not exist!", {
                        position: "top-right",
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                    });
                } else {
                    const sceneAttributes = JSON.parse(scenes[sceneIndex].attributes);
                    setAvatarFileName(sceneAttributes['gltf-model'])
                    setCameraAttributes({ position: sceneAttributes.position, rotation: sceneAttributes.rotation })
                    setSelectedScene(sceneIndex)
                }

                // startIntroMessage(datasetsIndex, datasets, chatbot.min_similarity, chatbot.enable_openai, chatbot.ai_behaviour);
            }
        } else {
            navigate("/")
        }
    }

    RegiterComponent("avatar-model", {
        schema: {},
        init: function () {
            setAvatarModelComponent(this.el);
            this.el.addEventListener('model-loaded', () => {
                this.el.object3D.traverse(obj => obj.frustumCulled = false)
                const animationList = this.el.components['gltf-model'];
                console.log(animationList.model.animations[0].name);
                initAnimations(animationList.model.animations);
                this.el.setAttribute('animation-mixer', "clip:idle");
            })
        }
    })

    const initAnimations = (_animations) => {
        if (_animations.length > 0) {
            var talking = [];
            _animations.map((row, index) => {
                if (row.name.includes("talking")) {
                    talking.push(row.name);
                }
            })
            setAnimations({ ...animations, talking });
        }
    }

    const onSelectDatasets = (e) => {
        setSelectedDatasets(e.target.value);
        console.log(e.target.value);
    }

    const onSelectScene = (index) => {
        if (index) {
            setSelectedScene(index);
            console.log();
            setAvatarFileName(JSON.parse(scenes[index].attributes)["gltf-model"]);
            avatarModelComponent.setAttribute('animation-mixer', "clip:dance_001");
            setCameraAttributes({
                position: JSON.parse(scenes[index].attributes).position,
                rotation: JSON.parse(scenes[index].attributes).rotation
            })
        }
    }

    const startIntroMessage = async (selectedDatasetsIndex, datasets, NLPSimilarity, isChatGPTEnable, AIBehaviour) => {
        if (selectedDatasetsIndex >= 0) {
            setIsMessageLoading(true);
            const selectedDatasets = datasets[selectedDatasetsIndex];
            const dataToSent = {
                "datasets_id": selectedDatasets.datasets_id,
                "message": "intro",
                "min_similarity": NLPSimilarity,
                "ai_enable": isChatGPTEnable,
                "ai_behaviour": AIBehaviour,
                "message_log": [{
                    role: "user",
                    content: "intro"
                }]
            };
            const result = await sentTestMessage(dataToSent)

            console.log(dataToSent, result);
            addMessageToUI(result.message, result);
            setShowSuggestionList(true);
        }
    }

    const onSendMessage = async (message) => {
        // if (event.key === 'Enter') { // Check if the pressed key is Enter
        console.log(message);
        setIsMessageLoading(true);
        const newMessage = messageList;
        newMessage.push({
            role: "user",
            content: message
        })

        const _selectedDatasets = datasets[selectedDatasets];
        const _selectedScene = scenes[selectedScene];
        const dataToSent = {
            "datasets_id": _selectedDatasets.datasets_id,
            "message": message,
            "min_similarity": NLPSimilarity,
            "ai_enable": isChatGPTEnable,
            "ai_behaviour": AIBehaviour,
            "message_log": messageList.slice(-4)
        };
        console.log(dataToSent);
        const result = await sentTestMessage(dataToSent)
        // newMessage.push({
        //     role: "assistant",
        //     content: result.message,
        //     info: result
        // })
        addMessageToUI(result.message, result);

        const animationToPlay = animations.talking[Math.floor(Math.random() * animations.talking.length)];
        avatarModelComponent.setAttribute('animation-mixer', { clip: animationToPlay });
        avatarModelComponent.setAttribute('animation-mixer', { crossFadeDuration: getTalkingDuration(result.message), clip: "idle" });
        setMessageList(newMessage)

        // }
    }
    function getTalkingDuration(message) {
        const durationPerCharacter = 0.1; // 0.1 seconds per character
        return message.length * durationPerCharacter; // Convert to milliseconds
    }

    const addMessageToUI = (message, result) => {
        const regex = /(?<=[.?!])\s+/;
        const sentences = message.split(regex);
        let i = 0;

        const addNextMessage = () => {
            if (i < sentences.length) {
                const newMessage = messageList;
                newMessage.push({
                    role: "assistant",
                    content: sentences[i],
                    info: result
                });
                setMessageList(newMessage);
                i++;
                addNextMessage();
            } else {
                setInputMessageValue("");
                setIsMessageLoading(false);

            }
        };
        addNextMessage();
    };

    const onSave = async (status) => {
        console.log(user.token);
        toastId.current = toast("Saving Chatbot Avatar", {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
        });
        generateToken(64, new Date().getTime()).then(async (newToken) => {
            const result = await saveChatbot({
                device_token: user.token,
                name: name,
                description: description,
                scene_id: scenes[selectedScene].scene_id,
                datasets_id: datasets[selectedDatasets].datasets_id,
                min_similarity: NLPSimilarity,
                enable_openai: isChatGPTEnable,
                ai_behaviour: AIBehaviour,
                status: status,
                domain: domain,
                token: token ? token : newToken,
                extra_config: {
                    info: {
                        suggestionList: suggestions.some((item) => Boolean(item.trim())) ? suggestions.filter(item => item.trim() !== '') : [],
                    },
                    appearance: themeConfig,
                    avatar: {
                        voice: selectedVoiceId,
                        voice_settings: {
                            stability: 0.5,
                            similarity_boost: 0.5
                        }
                    },
                    advanced: advancedConfig,
                    integrations: integrationsConfig
                },
                chatbot_id: (id != 'new') ? id : "",
            });
            toast.update(toastId.current, { render: "Saving Chatbot Successful", type: toast.TYPE.SUCCESS, autoClose: 5000 });
            navigate("/chatbot/" + result.chatbot_id)
        })
            .catch(error => console.error(error));

    }

    const onChangeStatus = () => {
        setStatus("published");
        onSave("published");
    }



    return (
        <>
            <div className="content-wrapper" id="avatar-page">
                <div className="content-header">
                    <nav class="navbar bg-body-tertiary">
                        <div class="container-fluid">
                            <h4 className="m-0">Create A CS Avatar</h4>
                            <div className='d-flex justify-content-end'>
                                <div class="col d-flex justify-content-center align-items-center me-3">
                                    <span class={`text-center ${status === 'published' ? "text-success" : "text-secondary"}`}>{status === 'published' ? "Published" : "Unpublished"}</span>
                                </div>
                                <button type="button" onClick={() => onChangeStatus()} class="btn btn-primary me-2"> Save</button>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="content">
                    <div className="container-fluid">
                        <ToastContainer
                            position="top-left"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                        />


                        <div className="row">
                            <div className="col-lg-5">
                                <div className="card">
                                    <div className="row">
                                        <div className="col-lg-12" id="ascene-container">
                                            <div className="card-body p-0" id="ascene-container">
                                                <a-scene embedded vr-mode-ui="enabled: false" transparent>
                                                    {/* <a-sky color="#ECECEC"></a-sky> */}
                                                    <a-camera camera-listener
                                                        position={`${cameraAttributes.position.x} ${cameraAttributes.position.y} ${cameraAttributes.position.z}`}
                                                        rotation={`${cameraAttributes.rotation.x} ${cameraAttributes.rotation.y} ${cameraAttributes.rotation.z}`}
                                                        look-controls={`enabled:false;pointerLockEnabled: true`} wasd-controls={`enabled:false;acceleration:5;fly: true`} ></a-camera>

                                                    {avatarFileName ? <a-entity
                                                        gltf-model={`${ServerAPIUrl}/avatar/${avatarFileName}`}
                                                        animation-mixer="clip:idle"
                                                        avatar-model
                                                        id="3dmodel" position="0 0 -2.5"></a-entity> : ""}
                                                    <a-entity light="type: ambient"></a-entity>
                                                    <a-entity light="color: #fff; angle: 60.84; shadowCameraVisible: true" visible="" rotation="-180 1.656993943518341 136.22931015928017" position="-1 0.93612 5.61602"></a-entity>
                                                </a-scene>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-5" >
                                            <div className="card-body chat-container">
                                                <Chat name={name}
                                                    description={description}
                                                    messageList={messageList}
                                                    suggestions={suggestions}
                                                    showSuggestionList={showSuggestionList}
                                                    setShowSuggestionList={setShowSuggestionList}
                                                    onSendMessage={onSendMessage}
                                                    inputMessageValue={inputMessageValue}
                                                    setInputMessageValue={setInputMessageValue}
                                                    isMessageLoading={isMessageLoading}
                                                    themeConfig={themeConfig}
                                                />
                                            </div>
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="card full pb-2">
                                    <div className="card-body">
                                        <nav class="navbar round-nav navbar-expand-lg ">
                                            <div class="container-fluid p-0">
                                                <div class="navbar-collapse">
                                                    <div class="navbar-nav border">
                                                        <a class={`nav-link ${formMenuSelected == 0 ? 'active' : ''}`} onClick={() => setFormMenuSelected(0)}>Chatbot Info</a>
                                                        <a class={`nav-link ${formMenuSelected == 1 ? 'active' : ''}`} onClick={() => setFormMenuSelected(1)}>AI Configuration</a>
                                                        <a class={`nav-link ${formMenuSelected == 2 ? 'active' : ''}`} onClick={() => setFormMenuSelected(2)}>Appearance</a>
                                                        <a class={`nav-link ${formMenuSelected == 3 ? 'active' : ''}`} onClick={() => setFormMenuSelected(3)}>Advanced</a>
                                                        <a class={`nav-link ${formMenuSelected == 4 ? 'active' : ''}`} onClick={() => setFormMenuSelected(4)}>Integrations</a>
                                                        <a class={`nav-link ${formMenuSelected == 5 ? 'active' : ''}`} onClick={() => setFormMenuSelected(5)}>Deployment</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </nav>
                                        {
                                            (formMenuSelected == 0) ? <ChatbotInfoForm name={name} setName={setName} description={description} setDescription={setDescription} selectedScene={selectedScene} onSelectScene={onSelectScene} scenes={scenes} selectedDatasets={selectedDatasets} setSelectedDatasets={setSelectedDatasets} datasets={datasets}
                                                suggestions={suggestions} setSuggestions={setSuggestions} /> : <></>
                                        }
                                        {
                                            (formMenuSelected == 1) ? <AIConfigForm NLPSimilarity={NLPSimilarity} setNLPSimilarity={setNLPSimilarity} isChatGPTEnable={isChatGPTEnable} setIsChatGPTEnable={setIsChatGPTEnable} AIBehaviour={AIBehaviour} setAIBehaviour={setAIBehaviour} selectedVoiceId={selectedVoiceId} setSelectedVoiceId={setSelectedVoiceId} /> : <></>
                                        }
                                        {
                                            (formMenuSelected == 2) ? <AppearanceForm
                                                themeConfig={themeConfig}
                                                user={user}
                                                setThemeConfig={setThemeConfig}
                                                chatbotIconUrl={chatbotIconUrl}
                                                setChatbotIconUrl={setChatbotIconUrl}
                                                chatbotBackground={chatbotBackground}
                                                setChatbotBackground={setChatbotBackground}
                                            /> : <></>
                                        }
                                        {
                                            (formMenuSelected == 3) ? <AdvancedSettings advancedConfig={advancedConfig} user={user} setAdvancedConfig={setAdvancedConfig} /> : <></>
                                        }
                                        {
                                            (formMenuSelected == 4) ? <Integrations chatbotToken={token} user={user} integrationsConfig={integrationsConfig} setIntegrationsConfig={setIntegrationsConfig} /> : <></>
                                        }
                                        {
                                            (formMenuSelected == 5) ? <Deployment token={token} /> : <></>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
