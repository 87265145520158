import CryptoJS from 'crypto-js';

function generateToken(length, secret = '') {
    return new Promise((resolve, reject) => {
        const randomBytes = CryptoJS.lib.WordArray.random(length).toString();
        const token = CryptoJS.SHA256(randomBytes + secret).toString();
        resolve(token);
    });
}

function humanizeDateDiff(strDate) {
    const date = new Date(strDate);
    const now = new Date();

    const diff = now - date;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);

    if (months > 0) {
        return `${months} months ${days % 30} days`
    } else if (days > 0) {
        return (`${days} days ${hours % 24} hrs`);
    } else if (hours > 0) {
        return (`${hours} hrs ${minutes % 60} mins`);
    } else {
        return (`${minutes} mins ${seconds % 60} secs`);
    }
}

function isValidURL(url) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name and extension
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return pattern.test(url);
  }
export {
    generateToken, humanizeDateDiff, isValidURL
}