import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ReactECharts from 'echarts-for-react';
import { barChart, echartDefault } from '../../plugins/echarts';

export function Overview(props) {
    const { records,onClickCreateAnalysis } = props


    const [dailyThreadsEchart, setDailyThreadsEchart] = useState([]);
    const [dailyThemeTopicsSum, setDailyThemeTopicsSum] = useState([]);
    const [dailyThemeTopicsSumEChartOption, setDailyThemeTopicsSumEChartOption] = useState({});


    const [numberOfCustomerCounter, setNumberOfCustomerCounter] = useState(0);
    const [numberOfRegisteredCustomerCounter, setNumberOfRegisteredCustomerCounter] = useState(0);

    useEffect(() => {
        console.log("records", records);

        const data = createOptions(records, new Date('2023-11-13'), new Date('2023-12-14'))
        console.log("data", data);
        if (!data) return;
        initDailyThreadsChart(data)
        initCounter(data)
        initThemeTopics(data)


    }, [records])

    const initDailyThreadsChart = (data) => {
        let dates = []
        let series = Object.entries(data).map(([key, value]) => {
            dates = value.dates;
            return {
                name: key,
                type: 'bar',
                data: value.dailyThreads,
                barGap: 0,
                emphasis: {
                    focus: 'series'
                },
            }
        })
        setDailyThreadsEchart(barChart(
            Object.keys(data),
            dates,
            series
        ))
    }

    const initCounter = (data) => {
        let totalOfCustomer = 0;
        let totalOfRegisteredCustomer = 0;
        Object.entries(data).map(([key, value]) => {
            totalOfCustomer += value.dailyThreads.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            totalOfRegisteredCustomer += value.dailyEmailSentToUser.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        })
        setNumberOfCustomerCounter(totalOfCustomer)
        setNumberOfRegisteredCustomerCounter(totalOfRegisteredCustomer)

    }

    const initThemeTopics = (data) => {
        let themeTopicSum = [];
        Object.entries(data).map(([chatbot_key, chatbot_value]) => {
            if (chatbot_value.themeTopic) {
                Object.entries(chatbot_value.themeTopic).map(([theme_topic_key, theme_topic_value]) => {
                    if (theme_topic_key === "data") {
                        Object.entries(theme_topic_value).map(([single_topic_key, single_topic_value]) => {
                            themeTopicSum.push({
                                key: single_topic_key,
                                chatbot_id: chatbot_key,
                                value: single_topic_value.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                            });
                        });
                    }
                });
            }
        });

        // Modify the themeTopicSum array directly to retain only the top 10 items
        themeTopicSum = themeTopicSum
            .sort((a, b) => b.value - a.value) // Sort in descending order of value
            .slice(0, 10);

        setDailyThemeTopicsSum(themeTopicSum)

        //     setDailyThemeTopicsSumEChartOption({
        //         xAxis: {
        //             type: 'category',
        //             data: themeTopicSum.map(item => item.key),

        //   axisTick: { show: false },
        //         },
        //         yAxis: {
        //             type: 'value'
        //         },
        //         series: [
        //             {

        //                 type: 'bar',
        //                 stack: 'total',
        //                 label: {
        //                     show: true
        //                 },
        //                 emphasis: {
        //                     focus: 'series'
        //                 },
        //                 data: themeTopicSum.map(item => item.value),
        //                 showBackground: true,
        //                 backgroundStyle: {
        //                     color: 'rgba(180, 180, 180, 0.2)'
        //                 }
        //             }
        //         ]
        //     });

        setDailyThemeTopicsSumEChartOption({
            tooltip: {
                trigger: 'item'
            },
            series: [
                {
                    name: 'Access From',
                    type: 'pie',
                    radius: '50%',
                    data: Object.entries(themeTopicSum).map(([key, value]) => {
                        return {
                            name: value.key,
                            value: value.value
                        }
                    }),
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        });



    }




    return (
        <div >
            {
                records.length > 0 ?
                    <>
                        <div className='row '>
                            <div class="d-grid gap-2 d-md-flex justify-content-md-end pt-2">
                                <button class="btn btn-primary me-md-2" type="button" onClick={()=>{onClickCreateAnalysis()}} disabled>Create Analysis Report</button>
                            </div>
                        </div>
                        <div className='row border-b border-t mt-2'>
                            <div className="col border-r">
                                <div className='text-report'>
                                    <p className='label'>Number Of Customers</p>
                                    <p className='value text-center'>{numberOfCustomerCounter}</p>
                                </div>
                            </div>
                            <div className="col border-r">
                                <div className='text-report'>
                                    <p className='label'>Registered Customer</p>
                                    <p className='value text-center'>{numberOfRegisteredCustomerCounter}</p>
                                </div>
                            </div>
                            <div className="col border-r">
                                <div className='text-report'>
                                    <p className='label'>Anonymous Customer</p>
                                    <p className='value text-center'>{numberOfCustomerCounter - numberOfRegisteredCustomerCounter}</p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-12 p-3">
                                <b>Topic Theme: A Comparative Analysis</b>
                            </div>
                            <div className="col-6">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">Rank</th>
                                            <th scope="col" width="50%">Keywords</th>
                                            <th scope="col">Query Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.entries(dailyThemeTopicsSum).map(([key, obj], index) => (

                                                <tr key={index}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{obj.key}</td>
                                                    <td className='text-center'>{obj.value}</td>
                                                    {/* Add more <td> here if you want to display more properties */}
                                                </tr>
                                            ))
                                        }


                                    </tbody>
                                </table>

                            </div>
                            <div className="col-6">
                                {dailyThemeTopicsSumEChartOption ?
                                    <ReactECharts option={dailyThemeTopicsSumEChartOption} /> : ""}
                            </div>
                        </div>
                        <div className='row border-b'>
                            <div className="col-12">
                                <div className='row mt-3'>
                                    <div className="col-12">
                                        <b>Topic Theme: A Comparative Analysis</b>
                                        <div className='mt-3'>
                                            {dailyThreadsEchart ?
                                                <ReactECharts option={dailyThreadsEchart} /> : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-12">
                                        <b>Topic vs Customer Groups: A Comparative Analysis</b>
                                        <div className='mt-3'>
                                            {/* <ReactECharts option={option2} /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-6">
                                        <b>Customer Engagement: Chat vs. Registration</b>
                                        <div className='mt-3'>
                                            {/* <ReactECharts option={customerEngagementOption} /> */}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <b>Customer Sentiment Analysis</b>
                                        <div className='mt-3'>
                                            {/* <ReactECharts option={sentimentAnalysisOption} /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div class="alert alert-light mt-4" role="alert">
                        Under Maintenance - Coming Soon
                    </div>
            }
            {/* */}


        </div>
    )
}

// const createOptions = (records, startDate, endDate) => {
//     const initializeDateArrays = (start, end) => {
//         let currentDate = new Date(start);
//         end = new Date(end);
//         const dates = [];

//         while (currentDate <= end) {
//             dates.push(currentDate.toLocaleDateString());
//             currentDate.setDate(currentDate.getDate() + 1);
//         }

//         return dates;
//     };

//     const initializeDataStructure = (dates) => {
//         return dates.reduce((acc, date) => {
//             acc[date] = {};
//             return acc;
//         }, {});
//     };

//     const getTopKeys = (dataObject, topCount) => {
//         const keyCounts = {};
//         Object.values(dataObject).forEach(dateObject => {
//             Object.keys(dateObject).forEach(key => {
//                 keyCounts[key] = (keyCounts[key] || 0) + dateObject[key];
//             });
//         });

//         return Object.entries(keyCounts)
//             .sort((a, b) => b[1] - a[1])
//             .slice(0, topCount)
//             .map(entry => entry[0]);
//     };

//     const formatOutput = (dataObject, dates, keys, isTop10 = false) => {
//         const output = { dates, data: {} };
//         const finalKeys = isTop10 ? getTopKeys(dataObject, 10) : keys;

//         finalKeys.forEach(key => {
//             output.data[key] = Array(dates.length).fill(0);
//         });

//         dates.forEach((date, index) => {
//             finalKeys.forEach(key => {
//                 output.data[key][index] = dataObject[date][key] || 0;
//             });
//         });

//         return output;
//     };

//     const dates = initializeDateArrays(startDate, endDate);

//     const groupedData = records.reduce((acc, { chatbot_id, threads }) => {
//         acc[chatbot_id] = acc[chatbot_id] || {
//             reaction: initializeDataStructure(dates),
//             sentiment: initializeDataStructure(dates),
//             themeTopic: initializeDataStructure(dates),
//             listOfKeywords: initializeDataStructure(dates)
//         };

//         threads.forEach(thread => {
//             const date = new Date(thread.created_at).toLocaleDateString();
//             const { reaction, sentiment, themeTopic, listOfKeywords } = thread.analysis_result;

//             // Increment counts
//             acc[chatbot_id].reaction[date][reaction] = (acc[chatbot_id].reaction[date][reaction] || 0) + 1;
//             acc[chatbot_id].sentiment[date][sentiment] = (acc[chatbot_id].sentiment[date][sentiment] || 0) + 1;
//             if (themeTopic) acc[chatbot_id].themeTopic[date][themeTopic] = (acc[chatbot_id].themeTopic[date][themeTopic] || 0) + 1;
//             listOfKeywords.forEach(keyword => {
//                 acc[chatbot_id].listOfKeywords[date][keyword] = (acc[chatbot_id].listOfKeywords[date][keyword] || 0) + 1;
//             });
//         });

//         return acc;
//     }, {});

//     // Collecting unique keys for each category
//     const reactionKeys = new Set();
//     const sentimentKeys = new Set();
//     const themeTopicKeys = new Set();
//     const listOfKeywordsKeys = new Set();

//     records.forEach(({ threads }) => {
//         threads.forEach(thread => {
//             reactionKeys.add(thread.analysis_result.reaction);
//             sentimentKeys.add(thread.analysis_result.sentiment);
//             if (thread.analysis_result.themeTopic) {
//                 themeTopicKeys.add(thread.analysis_result.themeTopic);
//             }
//             thread.analysis_result.listOfKeywords.forEach(keyword => {
//                 listOfKeywordsKeys.add(keyword);
//             });
//         });
//     });

//     // Format output
//     Object.keys(groupedData).forEach(chatbot_id => {
//         groupedData[chatbot_id].reaction = formatOutput(groupedData[chatbot_id].reaction, dates, reactionKeys);
//         groupedData[chatbot_id].sentiment = formatOutput(groupedData[chatbot_id].sentiment, dates, sentimentKeys);
//         groupedData[chatbot_id].themeTopic = formatOutput(groupedData[chatbot_id].themeTopic, dates, themeTopicKeys, true);
//         groupedData[chatbot_id].listOfKeywords = formatOutput(groupedData[chatbot_id].listOfKeywords, dates, listOfKeywordsKeys, true);
//     });

//     console.log(groupedData);
// };

const createOptions = (records, startDate, endDate) => {
    const initializeDateArrays = (start, end) => {
        let currentDate = new Date(start);
        end = new Date(end);
        const dates = [];

        while (currentDate <= end) {
            dates.push(currentDate.toLocaleDateString());
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    };

    const initializeDataStructure = (dates) => {
        return dates.reduce((acc, date) => {
            acc[date] = {};
            return acc;
        }, {});
    };

    const getTopKeys = (dataObject, topCount) => {
        const keyCounts = {};
        Object.values(dataObject).forEach(dateObject => {
            Object.keys(dateObject).forEach(key => {
                keyCounts[key] = (keyCounts[key] || 0) + dateObject[key];
            });
        });

        return Object.entries(keyCounts)
            .sort((a, b) => b[1] - a[1])
            .slice(0, topCount)
            .map(entry => entry[0]);
    };

    const formatOutput = (dataObject, dates, keys, isTop10 = false) => {
        const output = { dates, data: {} };
        const finalKeys = isTop10 ? getTopKeys(dataObject, 10) : keys;

        finalKeys.forEach(key => {
            output.data[key] = Array(dates.length).fill(0);
        });

        dates.forEach((date, index) => {
            finalKeys.forEach(key => {
                output.data[key][index] = dataObject[date][key] || 0;
            });
        });

        return output;
    };

    const dates = initializeDateArrays(startDate, endDate);

    const groupedData = records.reduce((acc, { chatbot_id, threads }) => {
        acc[chatbot_id] = acc[chatbot_id] || {
            reaction: initializeDataStructure(dates),
            sentiment: initializeDataStructure(dates),
            themeTopic: initializeDataStructure(dates),
            listOfKeywords: initializeDataStructure(dates),
            dailyThreads: {},
            dailyEmailRegistered: {},
            dailyContactNumberRegistered: {},
            dailyNameRegistered: {},
            dailyEmailSentToUser: {},
            dailyEmailSentToAdmin: {},
            dates: dates
        };

        dates.forEach(date => {
            acc[chatbot_id].dailyThreads[date] = 0;
            acc[chatbot_id].dailyEmailRegistered[date] = 0;
            acc[chatbot_id].dailyContactNumberRegistered[date] = 0;
            acc[chatbot_id].dailyNameRegistered[date] = 0;
            acc[chatbot_id].dailyEmailSentToUser[date] = 0;
            acc[chatbot_id].dailyEmailSentToAdmin[date] = 0;
        });

        threads.forEach(thread => {
            const date = new Date(thread.created_at).toLocaleDateString();
            const { reaction, sentiment, themeTopic, listOfKeywords, userEmailAddress, userContactNumber, userName, emailMessageForClient, emailMessageForAdmin } = thread.analysis_result;

            // Increment counts
            // acc[chatbot_id].reaction[date] = acc[chatbot_id].reaction[date] || {};
            // acc[chatbot_id].sentiment[date] = acc[chatbot_id].sentiment[date] || {};
            acc[chatbot_id].themeTopic[date] = acc[chatbot_id].themeTopic[date] || {};
            acc[chatbot_id].listOfKeywords[date] = acc[chatbot_id].listOfKeywords[date] || {};


            if (!acc[chatbot_id].reaction[date]) {
                acc[chatbot_id].reaction[date] = {}
            }

            if (!acc[chatbot_id].sentiment[date]) {
                acc[chatbot_id].sentiment[date] = {}
            }

            acc[chatbot_id].reaction[date][reaction] = (acc[chatbot_id].reaction[date][reaction] || 0) + 1;
            acc[chatbot_id].sentiment[date][sentiment] = (acc[chatbot_id].sentiment[date][sentiment] || 0) + 1;
            if (themeTopic) acc[chatbot_id].themeTopic[date][themeTopic] = (acc[chatbot_id].themeTopic[date][themeTopic] || 0) + 1;
            listOfKeywords.forEach(keyword => {
                acc[chatbot_id].listOfKeywords[date][keyword] = (acc[chatbot_id].listOfKeywords[date][keyword] || 0) + 1;
            });

            // Daily counts
            acc[chatbot_id].dailyThreads[date]++;
            if (userEmailAddress && userEmailAddress !== 'N/A') acc[chatbot_id].dailyEmailRegistered[date]++;
            if (userContactNumber && userContactNumber !== 'N/A') acc[chatbot_id].dailyContactNumberRegistered[date]++;
            if (userName && userName !== 'N/A') acc[chatbot_id].dailyNameRegistered[date]++;
            if (emailMessageForClient && emailMessageForClient.body && emailMessageForClient.body !== 'N/A') acc[chatbot_id].dailyEmailSentToUser[date]++;
            if (emailMessageForAdmin && emailMessageForAdmin.body && emailMessageForAdmin.body !== 'N/A') acc[chatbot_id].dailyEmailSentToAdmin[date]++;
        });


        return acc;
    }, {});



    // Format output
    Object.keys(groupedData).forEach(chatbot_id => {
        console.log("test", groupedData[chatbot_id].reaction);
        groupedData[chatbot_id].reaction = formatOutput(groupedData[chatbot_id].reaction, dates, new Set(), true);
        groupedData[chatbot_id].sentiment = formatOutput(groupedData[chatbot_id].sentiment, dates, new Set(), true);
        groupedData[chatbot_id].themeTopic = formatOutput(groupedData[chatbot_id].themeTopic, dates, new Set(), true);
        groupedData[chatbot_id].listOfKeywords = formatOutput(groupedData[chatbot_id].listOfKeywords, dates, new Set(), true);

        // For daily counts
        ['dailyThreads', 'dailyEmailRegistered', 'dailyContactNumberRegistered', 'dailyNameRegistered', 'dailyEmailSentToUser', 'dailyEmailSentToAdmin'].forEach(key => {
            groupedData[chatbot_id][key] = dates.map(date => groupedData[chatbot_id][key][date] || 0);
        });
    });
    return groupedData
};