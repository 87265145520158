import React, { useEffect, useState } from 'react';
import { DocumentAnalyzerForms } from "./documentAnalyzerForms";
import { DocumentAnalyzerTable } from "./documentAnalyzerTable";
export const DocumentAnalyzer = (props) => {

    const [pending, setPending] = React.useState(false);
    const {
        datasets, setDataset,
        onEdit, onDeleteEntry,
        documentResult, setDocumentResult, previousDataEntriesResult, setPreviousDataEntriesResult,
        documentAnalyzerData, setDocumentAnalyzerData,
        documentAnalyzerSelectedTableEntries, setDocumentAnalyzerSelectedTableEntries } = props;


    return (
        <div className="row">
            <div className="col-lg-8">
                <DocumentAnalyzerTable
                    setDocumentAnalyzerSelectedTableEntries={setDocumentAnalyzerSelectedTableEntries}
                    documentAnalyzerSelectedTableEntries={documentAnalyzerSelectedTableEntries}
                    // datasets={pending}
                    // setDataset={pending}
                    pending={pending}
                    documentAnalyzerData={documentAnalyzerData}
                    setDocumentAnalyzerData={setDocumentAnalyzerData}
                    onEdit={onEdit}
                    onDeleteEntry={onDeleteEntry}
                />
            </div>
            <div className="col-lg-4">
                <DocumentAnalyzerForms
                    datasets={datasets} 
                    setDataset={setDataset}
                    pending={pending}
                    setPending={setPending}
                    documentAnalyzerData={documentAnalyzerData}
                    setDocumentAnalyzerData={setDocumentAnalyzerData}
                    documentAnalyzerSelectedTableEntries={documentAnalyzerSelectedTableEntries}
                    setDocumentAnalyzerSelectedTableEntries={setDocumentAnalyzerSelectedTableEntries}
                    documentResult={documentResult}
                    setDocumentResult={setDocumentResult}
                    previousDataEntriesResult={previousDataEntriesResult}
                    setPreviousDataEntriesResult={setPreviousDataEntriesResult}
                />
            </div>
        </div>
    );
}