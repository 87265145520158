import React, { useEffect, useState, useCallback } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { uploadDocument, webScrape } from '../../pages/Request/Datasets';
import { FileToText } from '../../utils/FileToText';
import { isValidURL } from '../../utils/helpers';
export function UploadFileForm(props) {
    const { datasetId, documents, setDocuments, user, reloadTable } = props
    const fileTypes = ["PDF","DOCX", "CSV", "ZIP"];
    const [isUploadError, setIsUploadError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [document, setDocument] = useState([]);
    const [uploadedFile, setUploadedFile] = useState();
    const [fileType, setFileType] = useState("");

    const maxDocumentAttributes = {
        page: 2,
        length: 15000
    }

    const handleChange = (file) => {
        const fileExtension = file.name.split('.').pop().toLowerCase()
        setFileType(fileExtension)
        if (fileExtension === 'pdf') {
            FileToText(file, (result) => {
                setDocument(result);
                setUploadedFile(file);
            }, (message, error) => {
                console.log(message, error);
            });
        } else if (fileExtension === 'zip' || fileExtension === 'csv' || fileExtension === 'docx') {
            console.log(file,fileExtension);
            setUploadedFile(file);
            

        }



    };

    const onSubmit = () => {
        setLoading(true)
        const onResult = (result) => {
            setLoading(false)
            setDocuments(result.data)
            setUploadedFile();
            setDocument([]);
            setFileType("")

            reloadTable();
        }

        const onError = (err) => {
            console.log(err);
        }

        uploadDocument({
            file: uploadedFile,
            datasetId: datasetId
        }, onResult, onError)
    }

    const onSubmitWebUrl = async (url) => {
        const res = await webScrape({
            token: user.token,
            dataset_id: datasetId,
            url: url
        })
        reloadTable();
        return res;
    }

    const onCancel =  () => {
        setLoading(false)
        setUploadedFile();
        setDocument([]);
        setFileType("")
    }

    

    return (
        <div>
            <div className="card">
                <div className="card-body">
                    {
                        uploadedFile ?
                            <AnalyzerFormComponent fileType={fileType} uploadedFile={uploadedFile} document={document} onSubmit={onSubmit}  onCancel={onCancel} isLoading={isLoading} /> :
                            <FileUploaderComponent handleChange={handleChange} fileTypes={fileTypes} onSubmitWebUrl={onSubmitWebUrl} isLoading={isLoading} isUploadError={isUploadError} />
                    }
                </div>
            </div>
        </div>
    )
}

const FileUploaderComponent = (props) => {
    const [formMenuSelected, setFormMenuSelected] = useState(0);

    const { handleChange, fileTypes, isUploadError, isLoading, onSubmitWebUrl } = props;
    return (
        <>
            <nav class="navbar navbar-expand-lg round-nav ">
                <div class="container-fluid center p-0">
                    <div class="navbar-collapse ">
                        <div class="navbar-nav border">
                            <a class={`nav-link ${formMenuSelected == 0 ? 'active' : ''}`} onClick={() => setFormMenuSelected(0)}>Upload File</a>
                            <a class={`nav-link ${formMenuSelected == 1 ? 'active' : ''}`} onClick={() => setFormMenuSelected(1)}>Website Url</a>
                        </div>
                    </div>
                </div>
            </nav>
            {
                (formMenuSelected == 0) ?
                    <>
                        <FileUploader handleChange={handleChange} classes="upload-drop-file" label="Drop Your PDF File Here" name="file" types={fileTypes} />
                        <p className={`error ${isUploadError ? '' : 'hide'}`}>An error occurred while uploading the file. Please ensure that you have followed the instructions provided at the bottom of this page.</p>
                        <ol>
                            <li>To upload your file, simply drag and drop it into the designated area or click the ‘Drop file here’ link. This upload form supports PDF, CSV, DOCX, and Google Docs files in a .zip format. Please note that if you are uploading a Google Docs file, the .zip file must be downloaded directly from the Google Docs website.</li>
                        </ol></> :
                    <>
                        <WebUrlComponent onSubmitWebUrl={onSubmitWebUrl} isLoading={isLoading} />
                    </>
            }


        </>
    );
}
const WebUrlComponent = (props) => {
    const { onSubmitWebUrl, isLoading } = props;
    const [inputList, setInputList] = React.useState(['']); // Maintain state for input list
    const [isButtonDisabled, setIsButtonDisabled] = React.useState(true); // Maintain state for input list
    const [isProcessing, setIsProcessing] = React.useState(false); // Maintain state for input list
    const [isCompleted, setIsCompleted] = React.useState(false); // Maintain state for input list
    const [websiteUrl, setWebsiteUrl] = React.useState([]); // Maintain state for input list

    const handleInputChange = (index, value) => {
        const list = [...inputList];
        list[index] = value;
        setInputList(list);

    };

    const handleBlur = (index) => {
        verifyEmail(index);
        if (inputList[index].trim() === '') {
            const list = [...inputList];
            list.splice(index, 1); // Remove the blurred empty input
            setInputList(list);
        }

        const areAllValid = inputList.slice(0, -1).every((url) => url.length && isValidURL(url));
        if (areAllValid) {
            setIsButtonDisabled(false)
        } else {
            setIsButtonDisabled(true)
        }

    };

    const handleFocus = (index) => {
        if (inputList[index] === '') {
            setInputList([...inputList, '']); // Add a new input with an empty string when any input is focused
        }
    };

    const verifyEmail = (index) => {
        const isValid = isValidURL(inputList[index]);
        const inputElements = document.querySelectorAll('.form-control');
        if (inputElements && inputElements.length > index) {
            inputElements[index].classList.toggle('valid', isValid);
        }
    };

    const updateWebsiteStatus = (index, newStatus, newClass) => {
        setWebsiteUrl(prevWebsiteUrl => {
            const updatedWebsiteUrl = [...prevWebsiteUrl]; // Copy the previous state

            if (updatedWebsiteUrl[index]) {
                updatedWebsiteUrl[index].status = newStatus; // Update the status using the index
                updatedWebsiteUrl[index].class = newClass; // Update the status using the index
            }

            return updatedWebsiteUrl; // Return the updated array
        });
    }


    const onSave = async () => {
        const areAllValid = inputList.slice(0, -1).every((url) => url && isValidURL(url));
        if (areAllValid) {
            setIsProcessing(true);
            setIsCompleted(false);
            const urls = inputList.slice(0, -1)
            const newWebsiteUrl = [...websiteUrl];

            urls.forEach((url) => {
                newWebsiteUrl.push({ website: url, status: "Waiting", class: "bg-secondary" });
            });

            setWebsiteUrl(newWebsiteUrl);

            for (let index = 0; index < urls.length; index++) {
                const url = urls[index];
                updateWebsiteStatus(index, "Processing...", "bg-primary")
                const res = await onSubmitWebUrl(url)
                if (res.error) {
                    updateWebsiteStatus(index, res.message, "bg-danger")
                } else {
                    updateWebsiteStatus(index, "Completed", "bg-success")
                }
            }

            setIsCompleted(true);
        }
    }

    const onCompleteScrape = () => {
        if (isCompleted) {
            setIsProcessing(false)
            setWebsiteUrl([])
            setInputList([''])
        }
    }

    if (isProcessing) {
        return (
            <div class="row g-3">
                <ul class="list-group">
                    {
                        websiteUrl.map((url, key) =>
                            <li class="list-group-item d-flex justify-content-between align-items-center" key={key}>
                                <a target="_blank" href={url.website}>{url.website}</a>
                                <span class={`badge ${url.class} rounded-pill `}> {url.status}</span>
                            </li>
                        )
                    }

                </ul>
                <div class="col-12">
                    <button class="btn btn-primary" onClick={() => onCompleteScrape()} disabled={!isCompleted}>{!isCompleted ? "Processing..." : "Finish"}</button>
                </div>
            </div>
        );
    } else {
        return (
            <div class="row g-3">
                <h5 className="card-title mt-4 mb-0 ml-2">Enter Website URL</h5>
                {inputList.map((input, index) => (
                    <div className="col-md-12 mt-2" key={index}>
                        <input
                            type="text"
                            className={`form-control ${inputList[index].trim() !== '' ? (isValidURL(inputList[index]) ? 'valid' : 'invalid') : ''}`}
                            value={input}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            onBlur={() => {
                                handleBlur(index);
                            }}
                            onFocus={() => handleFocus(index)}
                        />
                    </div>
                ))}
                <div class="col-12">
                    <button class="btn btn-primary" onClick={() => onSave()} disabled={isLoading || isButtonDisabled}>
                        {isLoading ? "Saving..." : "Save"}
                    </button>
                </div>
            </div>
        );
    }

};



const AnalyzerFormComponent = (props) => {
    const { document, onSubmit, onCancel, isLoading, fileType,uploadedFile } = props;

    if (fileType === "pdf") {
        return (
            <>
                <div class="row g-3">
                    <div class="col-md-6">
                        <label for="inputEmail4" class="form-label">Number of Pages</label>
                        <input type="number" class="form-control" value={document.pages} disabled />
                    </div>
                    <div class="col-md-6">
                        <label for="inputPassword4" class="form-label">Lenght of Document</label>
                        <input type="number" class="form-control" value={document.content.length} disabled />
                    </div>

                    <div class="col-12">
                        <button class="btn btn-primary" onClick={() => onSubmit()} disabled={isLoading}>{isLoading ? "Upload..." : "Upload"}</button>
                        <button class="btn btn-danger" >Cancel</button>
                    </div>
                </div>
            </>
        );
    }

    if (fileType === "zip" || fileType === "csv" || fileType === "docx") {
        return (
            <>
                <div class="row g-3">
                    <div class="col-md-6">
                        <label for="inputEmail4" class="form-label">File Name:</label>
                        <input type="text" class="form-control" value={uploadedFile.name} disabled />
                    </div>
                    <div class="col-md-6">
                        <label for="inputEmail4" class="form-label">File Size:</label>
                        <input type="text" class="form-control" value={`${(uploadedFile.size / 1024).toFixed(2) }kb`} disabled />
                    </div>
                    <div class="col-12">
                        <button class="btn btn-primary" onClick={() => onSubmit()} disabled={isLoading}>{isLoading ? "Upload..." : "Upload"}</button>
                        <button class="btn btn-danger"  onClick={() => onCancel()}>Cancel</button>
                    </div>
                </div>
            </>
        );
    }
}