
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getChatbotMessages, getChatbotMessageEntries, chatbotAnalyzeConversation } from '../Request/Chatbot';
import { Link } from 'react-router-dom';
import { humanizeDateDiff } from '../../utils/helpers';
import { SelectAnalysisFormat } from '../../components/select';
import { TextDivPlaceHolder } from '../../components/text';

export function ChatbotMessages(props) {
    const { user } = props;
    const { id } = useParams();
    const [messages, setMessages] = useState([]);
    const [messageEntries, setMessageEntries] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState();
    const [isAnalyzingConversation, setIsAnalyzingConversation] = useState(false);
    const messageEntriesRef = useRef(null);
    const [analysisResult, setAnalysisResult] = useState();
    const [defaultFormat, setDefaultFormat] = useState([
        { value: 'name', label: 'name' },
        { value: 'age', label: 'age' },
        { value: 'birthday', label: 'birthday' },
        { value: 'contact_number', label: 'contact_number' },
        { value: 'email_address', label: 'email_address' },
        { value: 'complete_address', label: 'complete_address' },
        { value: 'purpose', label: 'purpose' },
        { value: 'summary', label: 'summary' }
    ]);

    const [selectedFormat, setSelectedFormat] = useState([
        defaultFormat[0], defaultFormat[1], defaultFormat[2], defaultFormat[3], defaultFormat[4], defaultFormat[5], defaultFormat[6], defaultFormat[7]
    ]);
    useEffect(() => {
        messageEntriesRef.current.scrollTop = messageEntriesRef.current.scrollHeight;
    }, [messageEntries])

    useEffect(() => {
        initMessagesTable();
    }, [])

    async function initMessagesTable() {
        const result = await getChatbotMessages({
            token: id,
        });
        setMessages(result);
    }

    async function getMessageEntries(token) {
        const result = await getChatbotMessageEntries({
            device_token: token,
        });
        setMessageEntries(result);
        setAnalysisResult(result.analysis.data);
        setSelectedMessage(token);
    }

    const onChangeAnalysisFormat = async () => {
        var selected = [];
        selectedFormat.forEach((option, index) => {
            selected.push(option.value)
        })
        setIsAnalyzingConversation(true);
        const result = await chatbotAnalyzeConversation({
            device_token: selectedMessage,
            format: selected
        });
        console.log(result);
        setAnalysisResult(result);
        setIsAnalyzingConversation(false);
    }

    const assistantMessageNextLine = (message) => {
        const regex = /(?<=[.?!])\s+/;
        const sentences = message.split(regex);
        const sentenceList = sentences.map((sentence, index) => (
            <li key={index}>
                <p className={`assistant reverse`}>{sentence}</p>
            </li>
        ));
        return sentenceList;
    };

    return (
        <>
            <div className="content-wrapper" id="avatar-page">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Conversations</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid">
                        <div className='row'>
                            <div className="col-lg-4">
                                <div className="card full message-list-container">
                                    <ul class="list-group ">
                                        {
                                            messages?.map((row, index) => (
                                                ((row.message_entries) > 2) ? (<li class="list-group-item list-group-item-action d-flex justify-content-between align-items-start" onClick={() => getMessageEntries(row.device_token)}>
                                                    <div class="ms-2 me-auto">
                                                        {
                                                            (row.data) ? <div class="fw-bold">From: {row.data.name ? (row.data.name === 'Not Found' ? 'No Name' : row.data.name) : "Unknown"}</div> : <div class="fw-bold">From: Unknown (Not Analyzed)</div>
                                                        }
                                                        {humanizeDateDiff(row.last_created_at)}
                                                    </div>
                                                    <span class="badge bg-primary rounded-pill">{row.message_entries}</span>
                                                </li>) : <></>

                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card full" ref={messageEntriesRef}>
                                    <div className="card-body">
                                        {(selectedMessage) ?
                                            <div className='chat-message-container' >
                                                <ul  >
                                                    {
                                                        messageEntries?.messages?.map((row, index) => (
                                                            (row.role === "assistant") ? assistantMessageNextLine(row.content) : <li >
                                                                <p className={`${row.role} reverse`}>
                                                                    {row.content}
                                                                </p>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div> : <TextDivPlaceHolder style={{ paddingTop: '100px' }} header="No Message Selected" content="Select the message from the message panel" />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card full">
                                    <div className="card-body">
                                        {(selectedMessage) ? <><div className='row'>
                                            <div className='massage-analysis-header'>
                                                <SelectAnalysisFormat
                                                    defaultFormat={defaultFormat}
                                                    selectedFormat={selectedFormat}
                                                    onChange={setSelectedFormat}
                                                />
                                                <button type="button" class="btn btn-outline-secondary" onClick={onChangeAnalysisFormat} disabled={isAnalyzingConversation ? true : false}>{isAnalyzingConversation ? "Analyzing" : "Analyze"}</button>
                                            </div>
                                        </div>
                                            <div className='row mt-4'>
                                                {
                                                    (!isAnalyzingConversation) ? <dl class="row">
                                                        {
                                                            (analysisResult) ? Object.entries(analysisResult).map(([key, value]) => (
                                                                (value != 'Not Found') ? <>
                                                                    <dt class="col-sm-4">{key.split('_')
                                                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                        .join(' ')}</dt>
                                                                    <dd class="col-sm-8 mb-4">{analysisResult[key]}</dd>
                                                                </> : ""
                                                            )) : <TextDivPlaceHolder style={{ paddingTop: '100px' }} header="Conversation not analyzed." content='You can select an item from the dropdown list, or enter a custom item by typing it and hitting enter. Then, click the "Analyze" button to find or analyze the item in the conversation.' />
                                                        }
                                                    </dl> : <TextDivPlaceHolder style={{ paddingTop: '100px' }} header="Analyzing Conversation" content="Please wait while our AI analyzes the conversation. This may take a while." />
                                                }

                                            </div></> : <TextDivPlaceHolder style={{ paddingTop: '100px' }} header="No Message Selected" content="Select the message from the message panel" />}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
