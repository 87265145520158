import { DatasetsForms } from "./datasetsForms";
import { DatasetsTable } from "./datasetsTable";
export const MyDatasets = (props) => {
    const {
        promptField, setpromptField, contentField, setContentField, addDataset, datasetsName,
        setDatesetsName, datasetsDescription, setDatasetsDescription, saveDataSet, datasets, setDataset, onEdit, onDeleteEntry } = props;
    return (
        <div className="row">
            <div className="col-lg-8">
                <DatasetsTable data={datasets} onEdit={onEdit} onDeleteEntry={onDeleteEntry} />
            </div>
            <div className="col-lg-4">
                <DatasetsForms
                    promptField={promptField}
                    setpromptField={setpromptField}
                    contentField={contentField}
                    setContentField={setContentField}
                    addDataset={addDataset}
                    datasetsName={datasetsName}
                    setDatesetsName={setDatesetsName}
                    datasetsDescription={datasetsDescription}
                    setDatasetsDescription={setDatasetsDescription}
                    saveDataSet={saveDataSet}
                    datasets={datasets}
                    setDataset={setDataset}
                />
            </div>
        </div>
    );
}