import logo from './logo.svg';
import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { CreateScene } from './pages/scene/create-scene';
import { CreateDatasets } from './pages/datasets/create-datasets';
import { Datasets } from './pages/datasets/datasets';
import { SceneList } from './pages/scene/list';
import { CreateChatbot } from './pages/chatbot/create-chatbot';
import { ChatbotList } from './pages/chatbot/list';
import { ChatbotMessages } from './pages/chatbot/messages';
import { AccountLogout } from './pages/account/logout';
import { Records } from './pages/records/records';
import Header from './components/header'
import SideBar from './components/sidebar'
import { Template } from './components/template';
import { Login } from './pages/login';
import { useEffect, useState } from 'react';
import startLoginStatusCheck from './services/socket';
import { CustomDataAnalysis } from './pages/records/customDataAnalysis';

function App() {
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem('gs-token') || '');
  const [name, setName] = useState(localStorage.getItem('gs-name') || '');
  useEffect(() => {
    if (!token || !name) {
      navigate("/login")
    } else {
      console.log("App Reset");
      startLoginStatusCheck(token)
    }
  }, [])

  const USER = {
    token: token,
    name: name,
  };




  return <>


    <Routes>

      <Route path="login" element={<Login user={USER} />}></Route>
      {
        token ? <Route element={<Template user={USER} />}>
          <Route path="chatbot/messages/:id" element={<ChatbotMessages user={USER} />}></Route>
          <Route path="chatbot/:id" element={<CreateChatbot user={USER} />}></Route>
          <Route path="chatbot/list" element={<ChatbotList user={USER} />}></Route>
          <Route path="datasets/:id" element={<CreateDatasets user={USER} />}></Route>
          <Route path="datasets/list" element={<Datasets user={USER} />}></Route>
          <Route path="scene/:id" element={<CreateScene user={USER} />}></Route>
          <Route path="scene/list" element={<SceneList user={USER} />}></Route>
          <Route path="account/logout" element={<AccountLogout user={USER} />}></Route>
          <Route path="records/:id" element={<Records user={USER} />}></Route>
          <Route path="custom-data-analysis/:id" element={<CustomDataAnalysis user={USER} />}></Route>

          <Route path="*" element={<h1>Not Found</h1>}></Route>
        </Route> : ""
      }
    </Routes>
  </>
}

export default App;
