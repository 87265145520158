
import React, { useEffect, useState } from 'react';
import { RegiterComponent } from '../../AFRAME';
import { useNavigate } from 'react-router-dom';
import { uploadAsset, saveScene, getScene } from '../Request/Scene';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ServerAPIUrl } from '../Request/Env';
import { RequestErrorHandler } from '../Request/ErrorHandler';


export function CreateScene(props) {
    const { user } = props;
    const navigate = useNavigate();
    const { id } = useParams();
    const [camera, setCamera] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [cameraAttributes, setCameraAttributes] = useState({
        position: { x: -0.01, y: 1.13, z: -1.78 },
        rotation: { x: 0.00, y: 0, z: 0.00 }
    });
    const [avatarModelComponent, setAvatarModelComponent] = useState({});
    const [avatarAnimations, setAvatarAnimations] = useState([]);
    const [selectedAnimation, setSelectedAnimation] = useState(0);
    const [avatarFileName, setAvatarFileName] = useState("");
    const [enableEditCamera, setEnableEditCamera] = useState(false);


    const toastId = React.useRef(null);


    const [sceneName, setSceneName] = useState("");
    const [sceneDescription, setSceneDescription] = useState("");

    useEffect(() => {
        initRequest();
    }, [])

    const initRequest = async () => {

        if (id) {
            if (id === "new") {
                setAvatarFileName("amy.glb")
            } else {
                const res = await getScene({
                    id: id,
                    token: user.token
                });
                if (!res.result.code == 1) {
                    RequestErrorHandler(res.result.code);
                }
                if (res.result.data.length == 1) {
                    const data = res.result.data[0]
                    const attributes = JSON.parse(data.attributes);
                    console.log(data);
                    setAvatarFileName(attributes['gltf-model'])
                    setSceneName(data.name);
                    setSceneDescription(data.description);
                    setCameraAttributes({
                        position: attributes.position,
                        rotation: attributes.rotation
                    })
                } else {
                    navigate("/")
                }
            }
        } else {
            navigate("/")
        }
    }

    RegiterComponent("avatar-model", {
        schema: {},
        init: function () {
            setAvatarModelComponent(this.el);
            this.el.addEventListener('model-loaded', () => {
                this.el.object3D.traverse(obj => obj.frustumCulled = false)
                const animationList = this.el.components['gltf-model'];
                console.log(animationList.model.animations[0].name);
                setAvatarAnimations(animationList.model.animations);
                this.el.setAttribute('animation-mixer', "clip:" + animationList.model.animations[0].name);
            })
        }
    })


    RegiterComponent("camera-listener", {
        schema: {},
        init: function () {
            const position = this.el.object3D.position;
            const rotation = this.el.object3D.rotation;

            setCameraAttributes({
                position: { x: position.x.toFixed(2), y: position.y.toFixed(2), z: position.z.toFixed(2) },
                rotation: { x: radToDeg(rotation.x).toFixed(2), y: radToDeg(rotation.y).toFixed(2), z: radToDeg(rotation.z).toFixed(2) }
            })
        },
        tick: function () {
            const position = this.el.object3D.position;
            const rotation = this.el.object3D.rotation;
            setCameraAttributes({
                position: { x: position.x.toFixed(2), y: position.y.toFixed(2), z: position.z.toFixed(2) },
                rotation: { x: radToDeg(rotation.x).toFixed(2), y: radToDeg(rotation.y).toFixed(2), z: radToDeg(rotation.z).toFixed(2) }
            })
        }
    })

    function onSelectFile(event) {
        setSelectedFile(event.target.files[0]);
    }

    function upload() {
        if (selectedFile) {
            uploadAsset(selectedFile, (response) => {
                setAvatarFileName(response.filename);
            });
        } else {
            console.log('No file selected');
        }
    }

    function setPlayAnimation(animationIndex) {
        setSelectedAnimation(animationIndex);
        var selectedAnimation = avatarAnimations[animationIndex];
        avatarModelComponent.setAttribute('animation-mixer', " clip:" + selectedAnimation.name);
    }

    function radToDeg(angleRad) {
        const angleDeg = angleRad * (180 / Math.PI);
        return angleDeg;
    }

    function onSaveScene() {
        toastId.current = toast("Saving Scene", {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
        });
        cameraAttributes['gltf-model'] = avatarFileName;
        const sceneAttributes = {
            token: user.token,
            scene_id: (id != "new") ? id : null,
            name: sceneName,
            description: sceneDescription,
            attributes: cameraAttributes
        }
        saveScene(sceneAttributes, (result) => {
            toast.update(toastId.current, { render: "Saving Scene Successful", type: toast.TYPE.SUCCESS, autoClose: 5000 });
            navigate("/scene/" + result.scene_id)
        })
    }


    return (
        <>
            <div className="content-wrapper" id="avatar-page">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Create A Body</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid">
                        <ToastContainer
                            position="top-left"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                        />
                        <div className="row">
                            <div className="col-lg-6" id="ascene-container">
                                <div className="card">
                                    <div className="card-body p-0" id="ascene-container">
                                        <a-scene embedded vr-mode-ui="enabled: false" test>
                                            <a-sky color="#ECECEC"></a-sky>
                                            <a-camera camera-listener
                                                position={`${cameraAttributes.position.x} ${cameraAttributes.position.y} ${cameraAttributes.position.z}`}
                                                rotation={`${cameraAttributes.rotation.x} ${cameraAttributes.rotation.y} ${cameraAttributes.rotation.z}`}
                                                look-controls={`enabled:${enableEditCamera};pointerLockEnabled: true`} wasd-controls={`enabled:${enableEditCamera};acceleration:5;fly: true`} ></a-camera>

                                            {avatarFileName ? <a-entity
                                                gltf-model={`${ServerAPIUrl}/avatar/${avatarFileName}`}
                                                animation-mixer={`clip:${setAvatarAnimations[selectedAnimation]}`}
                                                avatar-model
                                                id="3dmodel" position="0 0 -2.5"></a-entity> : ""}
                                        </a-scene>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">Camera Attributes</h5>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Attributes</th>
                                                            <th scope="col">x</th>
                                                            <th scope="col">y</th>
                                                            <th scope="col">z</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.keys(cameraAttributes).map((row, index) => (
                                                            <tr>
                                                                <th scope="row" key={index}>{row}</th>
                                                                <td>{cameraAttributes[row].x}</td>
                                                                <td>{cameraAttributes[row].y}</td>
                                                                <td>{cameraAttributes[row].z}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <button type="button" onClick={(e) => setEnableEditCamera(!enableEditCamera)} className={`btn ${enableEditCamera ? "btn-success" : "btn-secondary"} mt-3`} >Edit Camera </button>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                <h5>Animation List</h5>
                                                <table className="table table-striped-columns table-hover mt-3">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Animations</th>
                                                            <th scope="col">Duration</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {avatarAnimations.map((row, index) => (
                                                            <tr
                                                                key={index}
                                                                onClick={() => { setPlayAnimation(index) }}
                                                                className={`${selectedAnimation == index ? "table-active" : ""}  `}
                                                            >
                                                                <th scope="row">{row.name}</th>
                                                                <td>{row.duration.toFixed(3)}</td>
                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                {/* <input type="file" onChange={onSelectFile} />
                                                <button onClick={upload}>Upload</button> */}
                                                <div className="input-group">
                                                    <input type="file" className="form-control" id="inputGroupFile04" onChange={onSelectFile} aria-describedby="inputGroupFileAddon04" aria-label="Upload" />
                                                    <button className="btn btn-outline-secondary" onClick={upload} type="button" id="inputGroupFileAddon04">Upload</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 >Save Scene</h5>
                                                <div className="mb-3">
                                                    <label className="form-label">Name</label>
                                                    <input value={sceneName} onChange={(event) => setSceneName(event.target.value)} type="text" className="form-control" placeholder="Enter Scene Name" />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Description</label>
                                                    <textarea value={sceneDescription} onChange={(event) => setSceneDescription(event.target.value)} className="form-control" rows="3"></textarea>
                                                </div>
                                                <button type="button" onClick={onSaveScene} className="btn btn-primary float-end">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
