import { getDocument } from 'pdfjs-dist/webpack';
export const FileToText = (file, callback, onErrorCallback) => {
    pdfToText(file, callback, onErrorCallback);
}

const pdfToText = (file, callback, onErrorCallback) => {
    let reader = new FileReader();
    
    reader.onload = async function (evt) {
        try {
            let uint8Array = new Uint8Array(evt.target.result);
            let pdf = await getDocument({ data: uint8Array }).promise;
            let numPages = pdf.numPages;
            let textContent = '';

            for (let i = 1; i <= numPages; i++) {
                let page = await pdf.getPage(i);
                let text = await page.getTextContent();
                textContent += text.items.map(item => item.str).join(' ');
            }
            const result = {
                pages: numPages,
                content: textContent
            }

            callback(result);
        } catch (error) {
            console.log("An error occurred while reading the PDF: ", error);
            onErrorCallback("An error occurred while reading the PDF: ", error);
        }
    };
    reader.onerror = function (evt) {
        console.log("An error occurred while reading the file: ", evt);
        onErrorCallback("An error occurred while reading the file: ", evt);
    };
    reader.readAsArrayBuffer(file);
};