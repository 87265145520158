import React, { useEffect, useState } from 'react';

import { NavLink,Link } from 'react-router-dom';
export default function SideBar(props) {
    const { user } = props;
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        const menuList = [
            {
                text: "CS Avatar (CSA)",
                to: "/chatbot/new",
                reload: true,
                child: [
                    {
                        text: "Create New CS Avatar",
                        icon: "fas fa-plus",
                        to: "/chatbot/new",
                        reload: true
                    },
                    {
                        text: "Storage",
                        icon: "fas fa-list",
                        to: "/chatbot/list",
                        reload: true
                    }
                ]
            },
            {
                text: "CSA Brain Factory",
                icon: "fas fa-database",
                to: "/dataset",
                child: [
                    {
                        text: "Train New Brain",
                        icon: "fas fa-plus",
                        to: "/datasets/new",
                        reload: true
                    },
                    {
                        text: "Storage",
                        icon: "fas fa-list",
                        to: "/datasets/list",
                        reload: true
                    }
                ]
            },
            {
                text: "CSA Bodies",
                icon: "fas fa-user-tie",
                to: "#",
                child: [
                    {
                        text: "Create New CSA Body",
                        icon: "fas fa-plus",
                        to: "/scene/new",
                        reload: true,
                    },
                    {
                        text: "Storage",
                        icon: "fas fa-list",
                        to: "/scene/list",
                        reload: true
                    }
                ]
            },
            {
                text: "Insights",
                icon: "fas fa-user-tie",
                to: "#",
                child: [
                    {
                        text: "On-Going Data Analysis",
                        icon: "fas fa-solid fa-users",
                        to: "/records/all",
                        reload: true,
                    },
                    {
                        text: "Custom Data Analysis",
                        icon: "fas fa-solid fa-users",
                        to: "/custom-data-analysis/create",
                        reload: true,
                    }
                ]
            },
            {
                text: "Management",
                icon: "fas fa-user-tie",
                to: "#",
                child: [
                    {
                        text: "Users",
                        icon: "fas fa-solid fa-users",
                        to: "/account/#",
                        reload: true,
                    }
                ]
            },
            {
                text: "Account",
                icon: "fas fa-user-tie",
                to: "#",
                child: [
                    {
                        text: "Profile",
                        icon: "fas fa-solid fa-user",
                        to: "/account/#",
                        reload: true,
                    },
                    {
                        text: "Logout",
                        icon: "fas fa-sign-out-alt",
                        to: "/account/logout",
                        reload: true,
                    }
                ]
            }
        ]
        setMenu(menuList)
    }, [])

    const onSelectMenu = (selectedIndex) => {
        const updatedMenu = menu.map((entry, i) => {
            if (i === selectedIndex) {
                return { ...entry, isActive: (entry.isActive ? false : true) };
            } else {
                return { ...entry, isActive: false };
            }
        });
        console.log(updatedMenu);
        setMenu(updatedMenu)
    }

    return (
        <div className="main-sidebar sidebar-dark-primary elevation-4">
            <a href="index3.html" className="brand-link">
                <img src="/assets/img/AI-CSA-Logo.png" alt="AdminLTE Logo" className="brand-image" />
                <span className="brand-text font-weight-light ">AI CS Avatar</span>
            </a>

            <div className="sidebar">
                {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src="/dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
                    </div>
                    <div className="info">
                        <a href="#" className="d-block">{user.name}</a>
                    </div>
                </div> */}

                <div className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" role="menu" >

                        {/* {menu.map((row, index) => (
                            <NavLink className={`nav-item ${row.isActive ? "menu-open" : ""}`}>
                                <a onClick={() => onSelectMenu(index)} className={`nav-link ${row.isActive ? "active" : ""}`}>
                                    <i className={`nav-icon  ${row.icon}`}></i>
                                    <p>
                                        {row.text}
                                        {(row.child) ? <i class="fas fa-angle-left right"></i> : ""}
                                    </p>
                                </a>
                                {
                                    (row.child) ?
                                        <ul class="nav nav-treeview">
                                            {row.child.map((childRow, index) => (
                                                <li class={`nav-item ${childRow.isActive ? "menu-open" : ""}`}>
                                                    <a href="pages/layout/fixed-footer.html" class={`nav-link ${childRow.isActive ? "active" : ""}`}>
                                                        <i class={`nav-icon  ${childRow.icon}`}></i>
                                                        <p>{childRow.text}</p>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                        : ""
                                }
                            </NavLink>
                        ))} */}

                        {menu.map((row, index) => (
                            <>
                                {
                                    (!row.child) ?
                                        <NavLink key={index} to={row.to} className={({ isActive }) => {
                                            return isActive ? "nav-item menu-open nav-link active" : "nav-item nav-link"
                                        }} reloadDocument={row.reload}>
                                            <i className={`nav-icon  ${row.icon}`}></i>
                                            <p>
                                                {row.text}
                                            </p>
                                        </NavLink> :
                                        <>
                                            <li class="nav-header">{row.text}</li>
                                                {row.child.map((childRow, index) => (
                                                    <NavLink key={index} to={childRow.to} className={({ isActive }) => {
                                                        return isActive ? "nav-item menu-open nav-link active" : "nav-item nav-link"
                                                    }} reloadDocument={childRow.reload}>
                                                        <i className={`nav-icon  ${childRow.icon}`}></i>
                                                        <p>
                                                            {childRow.text}
                                                        </p>
                                                    </NavLink>
                                                ))}
                                        </>
                                }
                            </>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

