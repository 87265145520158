import React, { useEffect, useState, useRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select'
const SelectAnalysisFormat = (props) => {
    var { onChange, defaultFormat, selectedFormat } = props;


    const components = {
        DropdownIndicator: null,
    };



    return <CreatableSelect
        options={defaultFormat}
        defaultValue={selectedFormat}
        components={components}
        classNamePrefix="react-select"
        className='select me-1'
        isMulti
        onChange={onChange}
    />
}

const SelectV2 = (props) => {
    var { onChange, defaultFormat, selectedFormat, placeholder  } = props;

    const components = {
        DropdownIndicator: null,
    };

    return <CreatableSelect
        options={defaultFormat}
        defaultValue={selectedFormat}
        components={components}
        classNamePrefix="react-select"
        className='select me-1'
        isMulti
        onChange={onChange}
        placeholder={placeholder}
    />
}

const SelectV3 = (props) => {
    var { onChange, defaultFormat, selectedFormat, placeholder } = props;

    const components = {
        DropdownIndicator: null,
    };

    return <Select
        options={defaultFormat}
        defaultValue={selectedFormat}
        components={components}
        classNamePrefix="react-select"
        className='select me-1'
        placeholder={placeholder}
        isMulti
        onChange={onChange}
    />
}

export { SelectAnalysisFormat, SelectV2, SelectV3 }