
import React, { useEffect, useState } from 'react';
import { getChatbot, deleteChatbot } from '../Request/Chatbot';
import { Link } from 'react-router-dom';
import { ConfirmationModel } from '../../components/modal';
import Swal from 'sweetalert2'
import { toast, ToastContainer } from 'react-toastify';
import { RequestErrorHandler } from '../Request/ErrorHandler';
export function ChatbotList(props) {
    const { user } = props;
    const [chatbotList, setChatbotList] = useState([]);
    const [selectedChatbot, setSelectedChatbot] = useState();
    useEffect(() => {
        initTableScene();
    }, [])

    async function initTableScene() {
        const res = await getChatbot({
            id: "",
            token: user.token
        });
        if (res.result.code == 1) {
            console.log(res.result.data);
            setChatbotList(res.result.data);
        } else {
            RequestErrorHandler(res.result.code);
        }

    }

    const onDeleteChatbot = async (id) => {
        const res = await deleteChatbot({
            id: id,
            token: user.token
        });
        if (res.result.code == 1) {
            toast.success('Chatbot Deleted', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            initTableScene();
        } else {
            RequestErrorHandler(res.result.code);
        }
    }





    return (
        <>
            <div className="content-wrapper" id="avatar-page">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Customer Service Avatars</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid">
                        <div className='row'>
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">ChatGPT</th>
                                                    <th scope="col">Min. Similarity</th>
                                                    <th scope="col">Datasets</th>
                                                    <th scope="col">Scene</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Updated</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {chatbotList.map((row, index) => (
                                                    <tr
                                                    // onClick={() => setSelectedScene(row)}
                                                    // className={`${row.scene_id == (selectedScene ? selectedScene.scene_id : "") ? "table-active" : ""}  `}
                                                    >
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{row.name}</td>
                                                        <td>{row.enable_openai ? "Enabled" : "Disabled"}</td>
                                                        <td>{row.min_similarity}</td>
                                                        <td>{row.dataset_name}</td>
                                                        <td>{row.scene_name}</td>
                                                        <td>{row.status === 'published' ? 'Published' : 'Unpublished'}</td>
                                                        <td>{row.chatbot_updated_at}</td>
                                                        <td>
                                                            <Link to={`/chatbot/messages/${row.token}`}><button type="button" class="btn btn-primary btn-sm me-1">Conversations</button></Link>
                                                            <Link to={`/chatbot/${row.chatbot_id}`}><button type="button" class="btn btn-primary btn-sm me-1">Edit</button></Link>
                                                            <button type="button" onClick={() => {
                                                                ConfirmationModel(`Are you sure you want to delete ${row.name} chatbot?`,
                                                                    () => {
                                                                        onDeleteChatbot(row.chatbot_id);
                                                                    }, () => {

                                                                    })
                                                            }} class="btn btn-danger btn-sm">Delete</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-5">
                                <div className="card">
                                    <div className="card-body">
                                        {selectedScene ? <dl class="row">
                                            <dt class="col-sm-3">Scene ID:</dt>
                                            <dd class="col-sm-9">{selectedScene.scene_id}</dd>

                                            <dt class="col-sm-3">Name:</dt>
                                            <dd class="col-sm-9">{selectedScene.name}</dd>

                                            <dt class="col-sm-3">Description:</dt>
                                            <dd class="col-sm-9">{selectedScene.description}</dd>

                                            <dt class="col-sm-3">Camera Attributes:</dt>
                                            <dd class="col-sm-9">
                                                <dl class="row">
                                                    <dt class="col-sm-4">Position</dt>
                                                    <dd class="col-sm-8">{JSON.stringify(JSON.parse(selectedScene.attributes).position) }</dd>
                                                    <dt class="col-sm-4">Rotation</dt>
                                                    <dd class="col-sm-8">{JSON.stringify(JSON.parse(selectedScene.attributes).rotation) }</dd>
                                                </dl>
                                            </dd>
                                            <dt class="col-sm-3">Avatar Model:</dt>
                                            <dd class="col-sm-9">
                                                <dl class="row">
                                                    <dt class="col-sm-4">Model</dt>
                                                    <dd class="col-sm-8">{JSON.stringify(JSON.parse(selectedScene.attributes)["gltf-model"]) }</dd>
                                                    
                                                </dl>
                                            </dd>
                                            <Link to={`/scene/${selectedScene.scene_id}`}> <button type="button" className="btn btn-primary float-end">Edit</button></Link>
                                        </dl>
                                      
                                            :
                                            <h4 class="text-center">No Scene Selected</h4>}
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
