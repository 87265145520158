import { LoginForm } from "../../components/forms";
import { useNavigate } from 'react-router-dom';
import { AccountLogin } from "../Request/Login";
export function Login(props) {

    const navigate = useNavigate();
    async function onSubmit(email, password) {
        const account = await AccountLogin({
            username: email,
            password: password,
        });
        if (account.token) {
            localStorage.setItem('gs-token', account.token);
            localStorage.setItem('gs-name', account.name);
            navigate("/chatbot/list")
            window.location.reload();
        }
    }

    return <section class="login" id="login-page">
        <div class="login_box">
            <div class="left">
                <div class="contact">
                    <div className="form-container">
                        <img src="/assets/img/AI-CSA-Logo.png" alt="AdminLTE Logo" className="brand-image mb-2" />
                        <h3>SIGN IN</h3>
                        <LoginForm Submit={onSubmit} />
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="right-text">
                    <h3>GS-CUSTOMER SERVICE AVATAR</h3>
                    <h5>SG’s first ChatGPT enabled 3D-Avatar Chatbot.</h5>
                </div>
                <div class="right-inductor"><img src="https://lh3.googleusercontent.com/fife/ABSRlIoGiXn2r0SBm7bjFHea6iCUOyY0N2SrvhNUT-orJfyGNRSMO2vfqar3R-xs5Z4xbeqYwrEMq2FXKGXm-l_H6QAlwCBk9uceKBfG-FjacfftM0WM_aoUC_oxRSXXYspQE3tCMHGvMBlb2K1NAdU6qWv3VAQAPdCo8VwTgdnyWv08CmeZ8hX_6Ty8FzetXYKnfXb0CTEFQOVF4p3R58LksVUd73FU6564OsrJt918LPEwqIPAPQ4dMgiH73sgLXnDndUDCdLSDHMSirr4uUaqbiWQq-X1SNdkh-3jzjhW4keeNt1TgQHSrzW3maYO3ryueQzYoMEhts8MP8HH5gs2NkCar9cr_guunglU7Zqaede4cLFhsCZWBLVHY4cKHgk8SzfH_0Rn3St2AQen9MaiT38L5QXsaq6zFMuGiT8M2Md50eS0JdRTdlWLJApbgAUqI3zltUXce-MaCrDtp_UiI6x3IR4fEZiCo0XDyoAesFjXZg9cIuSsLTiKkSAGzzledJU3crgSHjAIycQN2PH2_dBIa3ibAJLphqq6zLh0qiQn_dHh83ru2y7MgxRU85ithgjdIk3PgplREbW9_PLv5j9juYc1WXFNW9ML80UlTaC9D2rP3i80zESJJY56faKsA5GVCIFiUtc3EewSM_C0bkJSMiobIWiXFz7pMcadgZlweUdjBcjvaepHBe8wou0ZtDM9TKom0hs_nx_AKy0dnXGNWI1qftTjAg=w1920-h979-ft" alt="" /></div>
            </div>
        </div>
    </section>
}