import React, { useEffect, useState } from 'react';
import { SelectV2 } from '../select';
import { FileUploader } from 'react-drag-drop-files';
import { uploadFile } from '../../pages/Request/Global';
export function AdvancedSettings(props) {
    const { user, setAdvancedConfig, advancedConfig } = props
    const [suggestions, setSuggestions] = useState(["Hmm, let me think...", "Interesting, let me see...", "Let me check..."]);
    const [emailList, setEmailList] = useState(advancedConfig?.email?.adminRecipient ? advancedConfig?.email?.adminRecipient.map(value => ({ value, label: value })) : []);
    const [emailConfig, setEmailConfig] = useState({});
    const [enabledInputs, setEnabledInputs] = useState([]);
    const [selectedFormat, setSelectedFormat] = useState([]);
    const [enableDocumentGenerator, setEnableDocumentGenerator] = useState(advancedConfig?.documentGenerator?.isEnabled || false);
    const [userInformationFields, setUserInformationFields] = useState([
        {
            isEnable: false,
            "name": "name",
            "type": "text",
            "label": "Name",
            "isRequired": false,
            "placeholder": "Enter Your Name"
        },
        {
            isEnable: false,
            "name": "email",
            "type": "email",
            "label": "Email Address",
            "isRequired": false,
            "placeholder": "Enter Your Email Address"
        },
        {
            isEnable: false,
            "name": "contact_number",
            "type": "text",
            "label": "Contact Number",
            "isRequired": false,
            "placeholder": "Enter Your Contact Number"
        },
        {
            isEnable: false,
            "name": "userid",
            "type": "number",
            "label": "User ID",
            "isRequired": false,
            "placeholder": "Enter Your User ID"
        }
    ]);

    useEffect(() => {
        if (advancedConfig) {
            console.log(advancedConfig);
            setSuggestions(advancedConfig.loadingResponse ? advancedConfig.loadingResponse : suggestions)
            if (advancedConfig.inputs) {
                const updatedFields = userInformationFields.map(field => {
                    // Find the matching new field
                    const newField = advancedConfig.inputs.find(newItem => newItem.name === field.name);

                    // If found, update the relevant properties, otherwise return the original field
                    return newField
                        ? {
                            ...field,
                            isEnable: newField.isEnable,
                            isRequired: newField.isRequired,
                            placeholder: newField.placeholder
                        }
                        : field;
                });

                setUserInformationFields(updatedFields);
            }



        }
    }, [])

    const suggestionLimit = 5;
    const handleInputChange = (index, value) => {
        const newSuggestions = [...suggestions];

        if (index === suggestions.length - 1 && value.trim() !== '') {
            if (suggestions.length < suggestionLimit) {
                newSuggestions.push('');
            }
        } else if (index === suggestions.length - 2 && value.trim() === '') {
            newSuggestions.pop();
        }

        newSuggestions[index] = value;
        setSuggestions(newSuggestions);
        setAdvancedConfig({
            ...advancedConfig,
            loadingResponse: newSuggestions
        })
    };

    const handleInputBlur = (index) => {
        if (suggestions.length < suggestionLimit) {
            if (index > 0 && index < suggestions.length - 1 && suggestions[index].trim() === '') {
                const newSuggestions = [...suggestions];
                newSuggestions.splice(index, 1);
                setSuggestions(newSuggestions);
                setAdvancedConfig({
                    ...advancedConfig,
                    loadingResponse: newSuggestions
                })
            }
        }
    };

    const onEmailListChange = (email) => {
        var validEmails = [];
        email.map((row, index) => {
            validEmails.push(row.value)
        })
        setAdvancedConfig({
            ...advancedConfig,
            email: {
                ...advancedConfig?.email,
                adminRecipient: validEmails
            }
        })
        setEmailList(email)
    }

    const handleUserInformationFieldsChange = (e, index, field) => {
        const newFields = userInformationFields.map((item, i) => {
            if (i === index) {
                if (e.target.type === "checkbox") {
                    return {
                        ...item,
                        [field]: !item[field]
                    };
                } else {
                    return {
                        ...item,
                        [field]: e.target.value
                    };
                }
            }
            return item;
        });

        const filteredEnabledInputs = newFields.filter(item => item.isEnable);
        setUserInformationFields(newFields);
        setAdvancedConfig({
            ...advancedConfig,
            inputs: filteredEnabledInputs
        })
    };

    const handleEmaiSettingsOnChange = (e, field) => {

        if (field === 'sendMessageToUser') {

            setAdvancedConfig({
                ...advancedConfig,
                email: {
                    ...advancedConfig?.email,
                    enableSendMessageToUser: !advancedConfig?.email?.enableSendMessageToUser
                }
            })

        } else if (field === 'sendMessageToAdmin') {
            setAdvancedConfig({
                ...advancedConfig,
                email: {
                    ...advancedConfig?.email,
                    enableSendMessageToAdmin: !advancedConfig?.email?.enableSendMessageToAdmin
                }
            })
        }
    };

    const onUploadDocumentTemplate = async (file) => {
        try {
            const res = await uploadFile(file, user.token);
            if (res.path) {
                console.log(res);
                setAdvancedConfig({
                    ...advancedConfig,
                    documentGenerator: {
                        ...advancedConfig?.documentGenerator,
                        documentTemplatePath: res
                    }
                })
                return false
            }
        } catch (error) {
            console.log("error uploading template: ", error);
        }
    }

    const onUploadSourceMaterials = async (files) => {
        console.log(files, files[0]);
        let listFilePath = [];

        for (const file of Object.values(files)) {
            try {
                const res = await uploadFile(file, user.token);
                if (res.path) {
                    console.log(res);
                    listFilePath.push(res);
                }
            } catch (error) {
                console.log("error uploading template: ", error);
            }
        }


        setAdvancedConfig({
            ...advancedConfig,
            documentGenerator: {
                ...advancedConfig?.documentGenerator,
                sourceMaterials: listFilePath
            }
        })

    }

    return (
        <>
            <div className='row justify-content-center mt-3'>
                <div class="row">
                    <div className='col'>
                        <h4>Advanced Settings</h4>
                        <p>The advanced settings are designed for experienced users; if you're unsure about these options, it's best to consult with a knowledgeable individual.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="pb-3 form-group">
                            <h6>Waiting Messages</h6>
                            <p>A prompt indicating that the chatbot is processing your request and will respond momentarily.</p>
                            {suggestions.map((suggestion, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    className="form-control mb-2"
                                    placeholder="Enter your waiting message here"
                                    value={suggestion}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    onBlur={() => handleInputBlur(index)}
                                />
                            ))}
                            <button type="button" onClick={() => setSuggestions([...suggestions, ''])} class={`btn btn-link ${suggestions.length >= suggestionLimit ? 'disabled' : ''}`}>Add New Field</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="pb-3 form-group">
                            <h6>Customer Registration (Optional)</h6>
                            <p>Configure these fields to collect essential details from users before initiating a chat, ensuring a personalized and informed interaction with the bot.</p>
                            <div class="row form-group m-2">
                                <div class="col">
                                    <table class="table table-hover table-bordered align-middle">
                                        <thead>
                                            <tr>
                                                <th scope="col" width={"15%"}>Enable</th>
                                                <th scope="col" width={"15%"}>Is Required</th>
                                                <th scope="col">Field Name</th>
                                                <th scope="col">Input Type</th>
                                                <th scope="col">Placeholder</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userInformationFields.map((field, index) => (
                                                <tr >
                                                    <td >
                                                        <div class="form-check">
                                                            <input class="form-check-input" checked={field.isEnable} id={`checkbox1-${index}`} type="checkbox" onChange={(e) => handleUserInformationFieldsChange(e, index, 'isEnable')} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <input class="form-check-input" checked={field.isRequired} type="checkbox" id={`checkbox2-${index}`} onChange={(e) => handleUserInformationFieldsChange(e, index, 'isRequired')} />
                                                        </div>
                                                    </td>
                                                    <td>{field.label}</td>
                                                    <td>{field.type}</td>
                                                    <td> <input type="email" class="form-control" value={field.placeholder} placeholder={field.placeholder} onChange={(e) => handleUserInformationFieldsChange(e, index, 'placeholder')} /></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="pb-3 form-group">
                            <h6>Notification And Alert Settings</h6>
                            <p>In this section, you can activate the feature to send automated emails, enhancing your engagement and follow-up process.</p>
                            <div class="row form-group m-2">
                                <div class="col">
                                    <div class="">
                                        <div class="form-check">
                                            <label for="exampleInputEmail1" class="form-label">From / Sender:</label>
                                            <div class="input-group mb-3">
                                                <input type="text" class="form-control" value={advancedConfig?.email?.from ? advancedConfig?.email?.from : "no-reply"} onChange={(e) => setAdvancedConfig((advancedConfig) => ({ ...advancedConfig, email: { ...advancedConfig.email, from: e.target.value } }))} placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                <span class="input-group-text" id="basic-addon2">@synergybot.io</span>
                                            </div>

                                            {/* <input type="number" class="form-control"} /> */}
                                            <div class="form-text">
                                                Enter the email address you want to use as the sender. This is the address your recipients will see as the source of the email.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="">
                                        <div class="form-check">
                                            <label for="exampleInputEmail1" class="form-label">Reply-to:</label>
                                            <input type="text" class="form-control" value={advancedConfig?.email?.replyto ? advancedConfig?.email?.replyto : ""} onChange={(e) => setAdvancedConfig((advancedConfig) => ({ ...advancedConfig, email: { ...advancedConfig.email, replyto: e.target.value } }))} />
                                            <div class="form-text">
                                                Enter the 'Reply-To' email address. When recipients hit 'Reply' to your email, their responses will automatically go to this address.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group m-2">
                                <div class="col">
                                    <div class="form-check">
                                        <input class="form-check-input" checked={advancedConfig?.email?.enableSendMessageToUser} onChange={(e) => { handleEmaiSettingsOnChange(e, "sendMessageToUser") }} type="checkbox" id="sendMessageToUser" />
                                        <label class="form-check-label" for="sendMessageToUser">
                                            <b>Enable External Customer Notifications</b>
                                        </label>
                                        <div class="form-text">
                                            After the conversation concludes, if the user has provided their email address, a thank-you email will be sent. This email, thoughtfully crafted post-conversation analysis by our AI, will include a message tailored to the discussed topic. This ensures a personalized and considerate touch, reinforcing our commitment to meaningful user engagement.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group m-2">
                                <div class="col">
                                    <div class="form-check">
                                        <input class="form-check-input" checked={advancedConfig?.email?.enableSendMessageToAdmin} onChange={(e) => { handleEmaiSettingsOnChange(e, "sendMessageToAdmin") }} type="checkbox" id="sendMessageToAdmin" />
                                        <label class="form-check-label" for="sendMessageToAdmin">
                                            <b>Enable Internal Admin Alerts</b>
                                        </label>
                                        <div class="form-text">
                                            Upon the conclusion of a conversation with a user, an informative email will be dispatched to the admin. This email, generated after analyzing the conversation, will provide a detailed summary and insights related to the discussion topics. This process ensures that the admin stays well-informed about user interactions and their content.
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-3 pl-4 mt-3">
                                    <label for="exampleInputEmail1" class="form-label">Recipient:</label>
                                    <SelectV2
                                        defaultFormat={emailList}
                                        selectedFormat={emailList}
                                        placeholder={"Enter the recipient's email address and press Enter to add more."}
                                        onChange={(e) => { onEmailListChange(e) }} />
                                    <div id="emailHelp" class="form-text">We'll never share these email with anyone else.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="pb-3 form-group">
                            <h6>Document Generator</h6>
                            <p>This feature automatically creates documents based on the input data and templates provided. It streamlines the document creation process, allowing for consistent formatting and efficient generation of content across various formats. Use this tool to produce documents tailored to your specific needs with minimal manual effort.</p>
                            <div class="row form-group m-2">
                                <div class="col">
                                    <div class="form-check">
                                        <input class="form-check-input" checked={enableDocumentGenerator} onChange={(e) => {
                                            setEnableDocumentGenerator(!enableDocumentGenerator)
                                            setAdvancedConfig({
                                                ...advancedConfig,
                                                documentGenerator: {
                                                    ...advancedConfig?.documentGenerator,
                                                    isEnabled: !enableDocumentGenerator
                                                }
                                            })
                                        }} type="checkbox" id="enableDocumentGenerator" />
                                        <label class="form-check-label" for="enableDocumentGenerator">
                                            <b>Enable Document Generator</b>
                                        </label>
                                        <div class="form-text">
                                           Check this box to activate the document generation feature. When enabled, the system will allow you to generate documents based on the content and instructions provided. This feature must be turned on to utilize the document creation tools and automated processes.
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 pl-4 mt-3">
                                    <div class="pb-3 form-group">
                                        <div class="mb-3">
                                            <label class="form-check-label" for="sendMessageToUser">
                                                <b>Trigger Criteria</b>
                                            </label>
                                            <div class="form-text mb-2">
                                                This process should be triggered when the user requests a document generation task. The criteria include specific keywords like ‘generate report,’ ‘create document,’ or ‘produce file.’ The process should also activate if the user specifies the need for a structured output. The trigger ensures that the document creation process is only initiated when these specific conditions are met, avoiding unnecessary activation.
                                            </div>
                                            <input type="email" class="form-control"
                                                onChange={(e) => {
                                                    setAdvancedConfig({
                                                        ...advancedConfig,
                                                        documentGenerator: {
                                                            ...advancedConfig?.documentGenerator,
                                                            triggerCriteria: e.target.value
                                                        }
                                                    })
                                                }}
                                                value={advancedConfig?.documentGenerator?.triggerCriteria}
                                                disabled={!enableDocumentGenerator}
                                                id="exampleFormControlInput1" placeholder="Generate document report for..." />
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-check-label" for="sendMessageToUser">
                                                <b>Generation Instructions</b>
                                            </label>
                                            <div class="form-text mb-2">
                                                In this section, you can write down clear instructions for the AI to help it create the document just the way you want. Think about how you want the document to look—like what sections it should have, what information it should include, and any special formatting, like bold or italic text. The more details you provide, the better the AI will understand how to make your document just right!
                                            </div>
                                            <textarea class="form-control" id="exampleFormControlTextarea1"
                                                onChange={(e) => {
                                                    setAdvancedConfig({
                                                        ...advancedConfig,
                                                        documentGenerator: {
                                                            ...advancedConfig?.documentGenerator,
                                                            generationInstructions: e.target.value
                                                        }
                                                    })
                                                }}
                                                value={advancedConfig?.documentGenerator?.generationInstructions}
                                                disabled={!enableDocumentGenerator}
                                                rows="3"></textarea>
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-check-label" for="sendMessageToUser">
                                                <b>Layout Instructions</b>
                                            </label>
                                            <div class="form-text mb-2">
                                                In this section, you can give instructions on how you want the layout of your document to look. For example, if you want certain parts of the text to be in a table, or if you want headings to stand out, let the AI know here. Describe how you want different sections to be organized on the page so the AI can arrange everything neatly, just the way you like it!
                                            </div>
                                            <textarea class="form-control" id="exampleFormControlTextarea12"
                                                onChange={(e) => {
                                                    setAdvancedConfig({
                                                        ...advancedConfig,
                                                        documentGenerator: {
                                                            ...advancedConfig?.documentGenerator,
                                                            layoutInstructions: e.target.value
                                                        }
                                                    })
                                                }}
                                                value={advancedConfig?.documentGenerator?.layoutInstructions}
                                                disabled={!enableDocumentGenerator}
                                                rows="3"></textarea>
                                        </div>

                                        <div class="mb-3">
                                            <label class="form-check-label" for="sendMessageToUser">
                                                <b>Upload Document Template</b>
                                            </label>
                                            <div class="form-text mb-2">
                                                This is where you can upload a DOCX template that the system will use for generating documents. The template should be formatted according to your preferred styles, but it must be empty, without any pre-existing content. The system will populate this template with the appropriate data during the document generation process.
                                            </div>
                                            <FileUploader handleChange={onUploadDocumentTemplate}
                                                disabled={!enableDocumentGenerator}
                                                dropMessageStyle={{ backgroundColor: 'red' }} classes="upload-drop-file"
                                                label={`Drop a new template or use the current one: ${advancedConfig?.documentGenerator?.documentTemplatePath?.originalName}`}
                                                name="file" types={['docx']} />

                                        </div>

                                        <div class="mb-3">
                                            <label class="form-check-label" for="sendMessageToUser">
                                                <b>Upload Source Material</b>
                                            </label>
                                            <div class="form-text mb-2">
                                                This is where you can upload the file or document that the AI will use to extract content for generating the final document. The content from this source document will be analyzed and incorporated into the generated document based on your instructions and the provided template.
                                            </div>
                                            <FileUploader handleChange={onUploadSourceMaterials}

                                                disabled={!enableDocumentGenerator} multiple={true} dropMessageStyle={{ backgroundColor: 'red' }} classes="upload-drop-file"
                                                label={advancedConfig?.documentGenerator?.sourceMaterials?.length > 0
                                                    ? `Drop a new template or use the current one(s): ${advancedConfig.documentGenerator.sourceMaterials.map((file, index) => `${file.originalName}`).join(', ')}`
                                                    : 'Drop Your Document File Here'}

                                                name="file" types={['docx', 'csv', 'pdf']} />
                                        </div>


                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}



