const AFRAME = window.AFRAME;

const RegiterComponent = (
    name, config
) => {
    if(!AFRAME.components[name]){
        AFRAME.registerComponent(name, config);
    }
}

export { RegiterComponent }