import { ServerAPIUrl } from "./Env";
const sentTestMessage = (data) => {
    return fetch(ServerAPIUrl + "/api/chatbot/test", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(err => {
            console.error(err);
        });
}

const saveChatbot = (data) => {
    return fetch(ServerAPIUrl + "/api/chatbot/save", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(err => {
            console.error(err);
        });
}

const getChatbot = (data) => {
    return fetch(ServerAPIUrl + "/api/chatbot/get", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(err => {
            console.error(err);
        });
}

const getChatbotMessages = (data) => {
    return fetch(ServerAPIUrl + "/api/chatbot/get/messages", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(err => {
            console.error(err);
        });
}

const getChatbotMessageEntries = (data) => {
    return fetch(ServerAPIUrl + "/api/chatbot/get/message-entries", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(err => {
            console.error(err);
        });
}

const chatbotAnalyzeConversation = (data) => {
    return fetch(ServerAPIUrl + "/api/chat-analysis", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(err => {
            console.error(err);
        });
}

const deleteChatbot = (data) => {
    return fetch(ServerAPIUrl + "/api/delete/chatbot", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(err => {
            console.error(err);
        });
}

const verifyTelegramBotToken = async (data, sessionToken) => {
    return fetch(ServerAPIUrl + "/api/telegram/check", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionToken}`
        },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(err => {
            console.error(err);
        });
}

const addTelegramBotToken = async (data, sessionToken) => {
    return fetch(ServerAPIUrl + "/api/telegram/addbot", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionToken}`
        },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(err => {
            console.error(err);
        });
}

const removeTelegramBotToken = async (data, sessionToken) => {
    return fetch(ServerAPIUrl + "/api/telegram/removebot", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionToken}`
        },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(err => {
            console.error(err);
        });
}

export {
    sentTestMessage, saveChatbot, getChatbot, getChatbotMessages, getChatbotMessageEntries, chatbotAnalyzeConversation, deleteChatbot, verifyTelegramBotToken, addTelegramBotToken, removeTelegramBotToken
}