
import React, { useEffect, useState, useCallback } from 'react';
import { UploadFileForm } from "./forms";
import { Table } from "./table";
import { getDocument, deleteDatasetdocument } from '../../pages/Request/Datasets'

import { ToastContainer, toast } from 'react-toastify';
import { RequestErrorHandler } from '../../pages/Request/ErrorHandler';
export const Document = (props) => {
    const { datasetId, user } = props

    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        console.log("datasetId", datasetId);
        getStorageDocument();
    }, []);

    const getStorageDocument = async () => {

        const res = await getDocument({
            dataset_id: datasetId,
            token: user.token,
        })
        if (res) {
            // 
            if (res?.result?.code != -1) {
                setDocuments(res.result)
                console.log(res);
            } else {
                // RequestErrorHandler(res?.result?.code);
            }
        } else {
            RequestErrorHandler(-1);
        }
    }

    const onDeleteDocument = async (id, datasets_id) => {
        const res = await deleteDatasetdocument({
            token: user.token,
            id: id,
            datasets_id: datasets_id,
        })
        if (res) {
            if(res.result.code == 1){
                toast("Document has been deleted!", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
                getStorageDocument();
            }
        } else {
            RequestErrorHandler(-1);
        }
        
    }

    return (
        <div className="row">

            {
                (datasetId != 'new') ? <>
                    <div className="col-lg-8">
                        <Table documents={documents} onDeleteDocument={onDeleteDocument} />
                    </div>
                    <div className="col-lg-4">
                        <UploadFileForm datasetId={datasetId} reloadTable={getStorageDocument} user={user} documents={documents} setDocuments={setDocuments} />
                    </div>
                </>
                    :
                    <div className="col-lg-8">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">No Datasets</h5>
                                <p className="card-text">
                                    Please add datasets first to access this feature.
                                </p>
                            </div>
                        </div>
                    </div>


            }

        </div>
    );
}