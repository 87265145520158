import React, { useEffect, useRef, useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import csvtojson from 'csvtojson';
// import EditorJS from '@editorjs/editorjs';
// import Header from '@editorjs/header';
// import EditorJSParser from 'editorjs-parser';

import { EditorJSDefault } from '../plugins/editorJS';

export function DatasetsForms(props) {
    const { promptField, setpromptField, contentField, setContentField, addDataset, datasetsName, setDatesetsName, datasetsDescription, setDatasetsDescription, saveDataSet, datasets, setDataset } = props;

    const [file, setFile] = useState(null);
    const [jsonData, setJsonData] = useState([]);
    const [isUploadError, setIsUploadError] = useState(false);

    const [formMenuSelected, setFormMenuSelected] = useState(0);
    const handleChange = (file) => {
        setIsUploadError(false);
        const reader = new FileReader();

        reader.onload = async (e) => {
            const csvData = e.target.result;
            const convertedJsonData = await csvtojson().fromString(csvData);
            const isValidFile = convertedJsonData.length > 0 && convertedJsonData.every(item => {
                const lowercaseKeys = Object.keys(item).map(key => key.toLowerCase());
                return lowercaseKeys.includes('prompt') && lowercaseKeys.includes('content');
            });
            if (isValidFile) {
                const lowercaseJsonData = convertedJsonData.map((item) => {
                    const newItem = {};
                    for (let key in item) {
                        newItem[key.toLowerCase()] = item[key];
                    }
                    return newItem;
                });
                console.log(lowercaseJsonData);
                setDataset([...datasets, ...lowercaseJsonData])
            } else {
                setIsUploadError(true);
            }
        };

        reader.readAsText(file);
    };
    return (
        <div>
            <div className="card">
                <div className="card-body">
                    <p className="card-text">

                    </p>
                    <div className="form-group">
                        <label >Name</label>
                        <input type="email" className="form-control" value={datasetsName} onChange={(event) => setDatesetsName(event.target.value)} placeholder="Enter Name" />
                    </div>
                    <div className="form-group">
                        <label >Description</label>
                        <textarea type="email" className="form-control" value={datasetsDescription} onChange={(event) => setDatasetsDescription(event.target.value)} placeholder="Enter Description" >
                        </textarea>
                    </div>
                    <button type="submit" onClick={saveDataSet} className="btn btn-primary" disabled={datasets.length ? false : true}>Save</button>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <nav class="navbar navbar-expand-lg round-nav ">
                        <div class="container-fluid center p-0">
                            <div class="navbar-collapse ">
                                <div class="navbar-nav border">
                                    <a class={`nav-link ${formMenuSelected == 0 ? 'active' : ''}`} onClick={() => setFormMenuSelected(0)}>Manually</a>
                                    <a class={`nav-link ${formMenuSelected == 1 ? 'active' : ''}`} onClick={() => setFormMenuSelected(1)}>Upload File</a>
                                </div>
                            </div>
                        </div>
                    </nav>
                    {
                        (formMenuSelected == 0) ?
                            <FormManuallyFunction
                                promptField={promptField}
                                setpromptField={setpromptField}
                                contentField={contentField}
                                setContentField={setContentField}
                                addDataset={addDataset}
                            /> : <></>
                    }
                    {
                        (formMenuSelected == 1) ? <FileUploaderFunction handleChange={handleChange} isUploadError={isUploadError} /> : <></>
                    }


                </div>
            </div>
        </div>
    )
}

export const FileUploaderFunction = (props) => {
    const { handleChange, isUploadError } = props;
    const fileTypes = ["CSV"];
    return (
        <>


            <FileUploader handleChange={handleChange} classes="upload-drop-file" label="Drop Your CSV File Here" name="file" types={fileTypes} />
            <p className={`error ${isUploadError ? '' : 'hide'}`}>An error occurred while uploading the file. Please ensure that you have followed the instructions provided at the bottom of this page.</p>
            <ol>
                <li>Open a spreadsheet application, such as Microsoft Excel or Google Sheets.</li>
                <li>Create a new sheet or select an existing one.</li>
                <li>In the first row, enter the following headers:</li>
                <ul>
                    <li>Column A: <strong>Prompt</strong></li>
                    <li>Column B: <strong>Content</strong></li>
                </ul>
                <li>Starting from the second row, enter your data in the corresponding columns:</li>
                <ul>
                    <li>Column A: Enter the prompts or questions.</li>
                    <li>Column B: Enter the content or answers related to each prompt.</li>
                </ul>
                <li>Once you have entered all the data, save the file in CSV format:</li>
                <ul>
                    <li>In most spreadsheet applications, you can go to "File" then "Save As" or "Export" and choose the CSV file format.</li>
                    <li>Provide a filename and choose a suitable location on your computer to save the file.</li>
                </ul>
            </ol>
        </>
    );
}

export const FormManuallyFunction = (props) => {
    const { promptField, setpromptField, contentField, setContentField, addDataset } = props;
    const editorRef = useRef(null);
    const onError = (e) => {
        console.error('ERROR', e)
    }

    const onSave = async() => {
        if (editorRef.current) {
            addDataset();
            const content = await editorRef.current.clear();
        }
    }

    return (
        <>
            <div className="form-group">
                <label >Prompt</label>
                <input type="text" className="form-control" value={promptField} onChange={(event) => setpromptField(event.target.value)} placeholder="Enter Promt" />
            </div>

            <div className="form-group">
                <label >Content</label>
                {/* <textarea type="text" className="form-control" rows={8} value={contentField} onChange={(event) => setContentField(event.target.value)} placeholder="Enter Content" >
                </textarea> */}
                {/* <div className='prompt-text-editor' ref={editorRef}></div> */}
                <EditorJSDefault ref={editorRef} className="prompt-text-editor" content={contentField} onChange={(val) => setContentField(val)} onError={(e) => onError(e)} />
            </div>
            <ol>
                <li>Locate the field labeled "Prompt" or "Question".</li>
                <li>Enter your desired prompt or question in the corresponding field.</li>
                <li>Locate the field labeled "Content" or "Answer".</li>
                <li>Enter the relevant content or answer related to the prompt in the corresponding field.</li>
                <li>Click the "Add" button to submit the current prompt and content, and repeat the process for additional entries.</li>
            </ol>

            <button type="submit" onClick={onSave} className="btn btn-primary">Add</button>
        </>
    );
}