import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Swal from 'sweetalert2'
const PublishChatbotModal = (token, onUnplished, onPublished) => {

    const pluginCode = `
    // Import the plugin library
    <script src="./chatbot-plugin.js"></script>
    // Initialize the chatbot
    <script>
      new ChatbotPlugin().config("${token}")
    </script>
    `;
    const chatbotIdCode = `
    // Your chatbot token
    ${token}`;

    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='row justify-content-center'>
                    <div className='col'>
                        <h1>Do you want to Publish?</h1>

                        {
                            token ?
                                <>
                                    <p>To publish your chatbot on your website, copy the code below and paste it into your web page's HTML code.</p>
                                    <p>Your Chatbot Token</p>
                                    <SyntaxHighlighter language="javascript" style={docco}>
                                        {chatbotIdCode}
                                    </SyntaxHighlighter>
                                    <p>Plugin Code</p>
                                    <SyntaxHighlighter language="javascript" style={docco}>
                                        {pluginCode}
                                    </SyntaxHighlighter>
                                </> : <p>After publishing your chatbot, you'll receive a unique plugin token. To view your ID and the corresponding code that you can copy to your website, simply return to this page.</p>
                        }

                        <button className='btn btn-secondary me-2' onClick={onClose}>Cancel</button>
                        <button className='btn btn-danger me-2'
                            onClick={() => {
                                onUnplished();
                                onClose();
                            }}
                        >
                            Save and Unpublish
                        </button>
                        <button className='btn btn-success'
                            onClick={() => {
                                onPublished();
                                onClose();
                            }}
                        >
                            Save and Publish
                        </button>
                    </div>
                </div>
            );
        }
    });
}

const ConfirmationModal = (headerText, content, buttons) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='row justify-content-center'>
                    <div className='col-8'>
                        <h1>{headerText}</h1>
                        {content}
                        <button className='btn btn-secondary me-2' onClick={onClose}>No</button>
                        {
                            buttons.map((button, index) => (
                                <button className={`${button.class}`} onClick={() => {
                                    button.onClick();
                                    onClose();
                                }}>{button.text}</button>
                            ))
                        }
                    </div>
                </div>
            );
        }
    });
}


const ConfirmationModel = (title, onConfirm, onCancel) => {
    Swal.fire({
        title: title,
        showDenyButton: true,
        confirmButtonText: 'Delete',
        denyButtonText: `Cancel`,
        confirmButtonColor: '#f13776',
        denyButtonColor: '#0b1354',
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            onConfirm();
        } else if (result.isDenied) {
            onCancel();
        }
    })
}

export {
    ConfirmationModal, PublishChatbotModal,ConfirmationModel
}