
import DataTable from 'react-data-table-component';
export function Table(props) {
    const { documents, isLoading,onDeleteDocument } = props;
    const columns = [
        {
            name: 'Content',
            selector: row => row.content.substring(0, 150)+"...",
            sortable: true,
            width: '600px',
            maxWidth: '300px',
            minWidth: '200px',
            wrap: true
        },
        {
            name: 'Token Used',
            selector: row => row.attributes.total_tokens,
            sortable: true,
            width: '200px',
            maxWidth: '200px',
            minWidth: '100px',
        },
        {
            name: 'Lenght',
            selector: row => row.content.length,
            sortable: true,
            width: '200px',
            maxWidth: '200px',
            minWidth: '100px',
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };
    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Documents</h5>
                <p className="card-text">
                The table is designed to display the chunked content of an uploaded PDF file. Each row of the table represents a chunk or a piece of the PDF's content. 
                </p>
                <DataTable
                    columns={columns}
                    pagination
                    data={documents}
                    customStyles={customStyles}
                    progressPending={isLoading}
                    responsive
                    highlightOnHover
                    pointerOnHover
                    expandOnRowClicked
                    expandableRows
                    expandableRowsComponent={(props) => TableExpandableComponent(props, documents,onDeleteDocument)}
                />
            </div>
        </div>
    )
}


const TableExpandableComponent = (props, data,onDeleteDocument) => {
    const { prompt, content, id,datasets_id } = props.data;
    const index = data.findIndex(obj => obj.id === id);
    
    return (
        <div className='table-expand-container'>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p class="prompt">
                            {prompt}
                        </p>
                        <p class="content">
                            {content}
                        </p>
                        <button type="button" class="btn btn-danger btn-sm" onClick={() => onDeleteDocument(id,datasets_id)}>Delete this document</button>
                    </div>
                </div>
            </div>

        </div>
    );
}