import React, { useEffect, useState } from 'react';

const VoiceSelect = ({ voices, defaultId, onChange }) => {
    return (
        <select class="form-select" onChange={(e) => onChange(e.target.value)}>
            {voices.map((voice, index) => (
                <option key={voice.voice_id} value={voice.voice_id} selected={voice.voice_id === defaultId}>
                    {`${voice.name} (${voice.labels.gender}, ${voice.labels.age}, ${voice.labels.accent})`}
                </option>
            ))}
        </select>
    );
};


const VoiceTable = ({ attributes, sampleAudioUrl }) => {
    if (!attributes)
        return <></>

    let audio;

    const playAudio = () => {
        // Stop the current audio if it's playing
        if (audio) {
            audio.pause();
            audio.currentTime = 0;
        }

        // Start a new audio
        audio = new Audio(sampleAudioUrl);
        audio.play();
    };
    return (
        <table class="table mt-2">
            <tbody>
                {Object.keys(attributes).map((key, index) => (
                    <tr key={index}>
                        <td width={'30%'}>{key}</td>
                        <td><b>{attributes[key]}</b></td>
                    </tr>
                ))}
                <tr>
                    <td>Sample</td>
                    <td><button class="btn btn-primary me-2" onClick={playAudio}>Play</button></td>
                </tr>
            </tbody>
        </table>
    );
}

export function AIConfigForm(props) {
    const { NLPSimilarity, setNLPSimilarity, isChatGPTEnable, setIsChatGPTEnable, AIBehaviour, setAIBehaviour, selectedVoiceId, setSelectedVoiceId } = props;
    const [voices, setVoices] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState([]);
    useEffect(() => {
        fetch('https://api.elevenlabs.io/v1/voices', {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'xi-api-key': '54e93afcd03a87d33bfd22fbbbb14f7d'
            }
        })
            .then(response => response.json())
            .then(data => {
                setVoices(data.voices);
            })
            .catch(error => console.error('Error:', error));

    }, [])




    const handleVoiceChange = (voiceId) => {
        setSelectedVoiceId(voiceId);

        // Find the voice object with the matching voiceId
        const selectedVoiceObj = voices.find(voice => voice.voice_id === voiceId);
        setSelectedVoice(selectedVoiceObj)
    };


    return (
        <>
            <div className='row justify-content-center mt-3'>
                <div class="row">
                    <div className='col'>
                        <h4>Configure Your AI</h4>
                        <p>Adjust settings for your chatbot's voice, fine-tune its understanding with minimum similarity thresholds, and define its interactive behavior to optimize user interactions.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="pb-3 form-group">
                            <label for="customRange3" class="form-label">Avatar Voice</label>
                            {voices.length > 0 && <VoiceSelect voices={voices} defaultId={selectedVoiceId} onChange={handleVoiceChange} />}
                            <VoiceTable attributes={selectedVoice.labels} sampleAudioUrl={selectedVoice.preview_url} />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="pb-3 form-group">
                            <label for="customRange3" class="form-label">Minimum Similarity: {NLPSimilarity}</label>
                            <input type="range" class="form-range" value={NLPSimilarity} onChange={(e) => { setNLPSimilarity(e.target.value) }} min="0" max="100" step="0.2" id="customRange3"></input>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="pb-3 form-group">
                            <label for="customRange3" class="form-label">ChatGPT / Open AI</label>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" checked={isChatGPTEnable} onChange={() => setIsChatGPTEnable(!isChatGPTEnable)} id="flexCheckDefault" />
                                <label class="form-check-label" for="flexCheckDefault">
                                    Enable ChatGPT
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="pb-3 form-group">
                            <label for="exampleFormControlTextarea1" class="form-label">AI Behaviour</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="8" value={AIBehaviour} onChange={(e) => setAIBehaviour(e.target.value)}></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
