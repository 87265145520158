import React, { useEffect, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { TwitterPicker } from 'react-color';
export function Deployment(props) {
    const { token } = props

    const [previewWebsiteUrl, setPreviewWebsiteUrl] = useState("https://www.gridsynergy.sg");
    const [previewExpiryDays, setPreviewExpiryDays] = useState(3);
    const onPreview = (type) => {

        const currentTime = new Date().getTime();

        const threeDaysInMilliseconds = (previewExpiryDays ? previewExpiryDays : 1) * 24 * 60 * 60 * 1000; // 3 days in milliseconds
        const expirationTime = currentTime + threeDaysInMilliseconds;

        window.open(`https://chat.synergybot.io/preview/?id=${token}&type=${type}&time=${expirationTime}&website=${previewWebsiteUrl ? previewWebsiteUrl : "https://www.gridsynergy.sg"}`, '_blank');
    }
    const pluginCode = `
    // Import the plugin library
    <script src="./chatbot-plugin.js"></script>
    // Initialize the chatbot
    <script>
      new ChatbotPlugin().config("${token}")
    </script>
    `;
    const chatbotIdCode = `
    // Your chatbot token
    ${token}`;
    return (
        <>
            <div className='row justify-content-center mt-3'>
                <div class="row">
                    <div className='col'>
                        <h4>Testing And Deployment</h4>
                        <p>Access code snippets for integration, utilize libraries for deployment, and preview your chatbot's performance to ensure readiness before going live.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="pb-3 form-group">
                            {
                                token ?
                                    <>
                                        <p><b>To publish your chatbot on your website, copy the code below and paste it into your web page's HTML code.</b></p>
                                        <p>Your Chatbot Token</p>
                                        <SyntaxHighlighter language="javascript" style={docco}>
                                            {chatbotIdCode}
                                        </SyntaxHighlighter>
                                        <p>Plugin Code</p>
                                        <SyntaxHighlighter language="javascript" style={docco}>
                                            {pluginCode}
                                        </SyntaxHighlighter>
                                    </> : <p>After publishing your chatbot, you'll receive a unique plugin token. To view your ID and the corresponding code that you can copy to your website, simply return to this page.</p>
                            }
                        </div>
                    </div>
                </div>
                {
                    token ?

                        <div class="row">
                            <div class="col">
                                <div class="pb-3 form-group">
                                    <h4>Preview</h4>
                                    <div class="row mb-3">
                                        <div class="col">
                                            <label for="exampleFormControlInput1" class="form-label">Website Url</label>
                                            <input type="text" class="form-control" value={previewWebsiteUrl} onChange={(e) => setPreviewWebsiteUrl(e.target.value)} placeholder="https://www.gridsynergy.sg" aria-label="First name" />
                                        </div>
                                        <div class="col">
                                            <label for="exampleFormControlTextarea1" class="form-label">Expiry Days</label>
                                            <input type="number" min={1} max={30} value={previewExpiryDays} onChange={(e) => setPreviewExpiryDays(e.target.value)} class="form-control" placeholder="Last name" aria-label="Last name" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <button type="button" class="btn btn-primary float-end  btn-sm" onClick={() => { onPreview("default") }} >Preview</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : <></>
                }

            </div>
        </>
    )
}
