import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addDatasets, getDatasets } from '../Request/Datasets'
import Header from '../../components/header'
import SideBar from '../../components/sidebar'
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RequestErrorHandler } from '../Request/ErrorHandler';
import { DatasetsTable } from '../../components/datasets/datasetsTable';
import { DatasetsForms } from '../../components/datasets/datasetsForms';
import { MyDatasets } from '../../components/datasets/myDatasets';
import { DocumentAnalyzer } from '../../components/datasets/documentAnalyzer';

import { Document } from '../../components/document';
const md5 = require('md5');
export function CreateDatasets(props) {
    const { user } = props;
    const navigate = useNavigate();
    // const router = useRouter();
    const { id } = useParams();
    const [datasets, setDataset] = useState([]);

    const [promptField, setpromptField] = useState("");
    const [contentField, setContentField] = useState("");
    const [editSelectedEntry, setEditSelectedEntry] = useState(-1);
    const [datasetsName, setDatesetsName] = useState("");
    const [datasetsDescription, setDatasetsDescription] = useState("");
    const [datasetsId, setDatasetsId] = useState("");
    const toastId = React.useRef(null);
    const [formMenuSelected, setFormMenuSelected] = useState(0);

    // AI Document Analyzer
    const [documentResult, setDocumentResult] = useState({});
    const [previousDataEntriesResult, setPreviousDataEntriesResult] = useState([])
    const [documentAnalyzerData, setDocumentAnalyzerData] = useState([]);
    const [documentAnalyzerSelectedTableEntries, setDocumentAnalyzerSelectedTableEntries] = useState([]);



    useEffect(() => {
        initRequest();
    }, []);

    const initRequest = async () => {
        if (id) {
            if (id == "new") {
            } else {
                const res = await getDatasets({
                    id: id,
                    token: user.token
                });
                
                RequestErrorHandler(res?.result?.code);
                if (res?.result?.data?.length == 1) {
                    const result = res.result.data[0];
                    setDataset(result.data_entries);
                    setDatasetsId(result.datasets_id);
                    setDatesetsName(result.name);
                    setDatasetsDescription(result.description);
                } else {
                    navigate("/datasets/new")
                }
            }

        }
    }


    const addDataset = () => {

        console.log(contentField);
        if (!promptField || !contentField) return;

        const newData = {
            prompt: promptField,
            content: contentField,
        }
        if (editSelectedEntry < 0) {
            setDataset([...datasets, newData])
        } else {
            const updatedEntry = datasets.map((entry, i) => {
                if (i === editSelectedEntry) {
                    return newData;
                } else {
                    return entry;
                }
            });
            setDataset(updatedEntry);
        }
        setEditSelectedEntry(-1);
        setpromptField("");
        setContentField("");

    }

    const saveDataSet = () => {
        toastId.current = toast("Saving Datasets", {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
        });
        console.log("datasetsId: ", datasetsId);
        addDatasets({
            datasets: datasets,
            datasetsId: (id != 'new') ? id : "",
            token: user.token,
            datasetsName: datasetsName,
            datasetsDescription: datasetsDescription,
        }, (result) => {
            if (result.id) {
                toast.update(toastId.current, { render: "Saving Datasets Successful", type: toast.TYPE.SUCCESS, autoClose: 5000 });
                navigate("/datasets/" + result.id)
            }
        })
    }

    const onDeleteEntry = (index) => {
        setDataset([...datasets.slice(0, index), ...datasets.slice(index + 1)]);
    };

    const onEdit = (index) => {
        const editSelectedEntry = datasets[index];
        setpromptField(editSelectedEntry.prompt);
        setContentField(editSelectedEntry.content);
        setEditSelectedEntry(index);
        console.log("setEditSelectedEntry: ", index);
    }



    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <ToastContainer
                        position="top-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Train New Brain</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8">
                                <nav class="navbar round-nav navbar-expand-lg ">
                                    <div class="container-fluid p-0">
                                        <div class="navbar-collapse">
                                            <div class="navbar-nav border">
                                                <a class={`nav-link ${formMenuSelected == 0 ? 'active' : ''}`} onClick={() => setFormMenuSelected(0)}>Datasets</a>
                                                <a class={`nav-link ${formMenuSelected == 1 ? 'active' : ''}`} onClick={() => setFormMenuSelected(1)}>Documents</a>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        {
                            (formMenuSelected == 0) ?
                                <MyDatasets
                                    data={datasets} onEdit={onEdit}
                                    onDeleteEntry={onDeleteEntry}
                                    promptField={promptField}
                                    setpromptField={setpromptField}
                                    contentField={contentField}
                                    setContentField={setContentField}
                                    addDataset={addDataset}
                                    datasetsName={datasetsName}
                                    setDatesetsName={setDatesetsName}
                                    datasetsDescription={datasetsDescription}
                                    setDatasetsDescription={setDatasetsDescription}
                                    saveDataSet={saveDataSet}
                                    datasets={datasets}
                                    setDataset={setDataset}
                                /> : <></>
                        }
                        {
                            (formMenuSelected == 1) ?
                                <Document datasetId={id} user={user} /> : <></>
                        }

                        {/* <div className="row">
                            <div className="col-lg-8">
                                <DatasetsTable data={datasets} onEdit={onEdit} onDeleteEntry={onDeleteEntry} />
                            </div>
                            <div className="col-lg-4">
                                <DatasetsForms
                                    promptField={promptField}
                                    setpromptField={setpromptField}
                                    contentField={contentField}
                                    setContentField={setContentField}
                                    addDataset={addDataset}
                                    datasetsName={datasetsName}
                                    setDatesetsName={setDatesetsName}
                                    datasetsDescription={datasetsDescription}
                                    setDatasetsDescription={setDatasetsDescription}
                                    saveDataSet={saveDataSet}
                                    datasets={datasets}
                                    setDataset={setDataset}
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
