
import React, { useEffect, useState, useRef } from 'react';
import Lottie from 'react-lottie';

import * as animationData from '../loading/lottieFiles/ZKDM337F9j.json'


const AnalyzingLoading = () => {
    const animationInstance = useRef(null);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        const changeSpeedRandomly = () => {
            // Generate a random speed between 0.5 (slower) and 3 (faster)
            const randomSpeed = Math.random() * 2.5 + 0.5; // Adjust these values as needed
            if (animationInstance.current) {
                animationInstance.current.setSpeed(randomSpeed);
            }

            // Generate a random time interval between 1 and 15 seconds
            const randomTime = Math.random() * 14000 + 1000; // 1,000 to 15,000 milliseconds
            setTimeout(changeSpeedRandomly, randomTime);
        };

        changeSpeedRandomly();

        return () => clearTimeout(changeSpeedRandomly); // Cleanup on unmount
    }, []);

    return (
        <div style={{ pointerEvents: 'none' }}>

            <Lottie options={defaultOptions}
                height={170}
                width={200}
                isStopped={false}
                isPaused={false}
                ref={animationInstance} />
        </div>
    );
}

export { AnalyzingLoading }