
import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { createAnalyst, getAnalyst, getRecords, getThreads } from '../Request/Records';
import { humanizeDateDiff } from '../../utils/helpers';
import ReactECharts from 'echarts-for-react';
import { SelectV2, SelectV3 } from '../../components/select';
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Overview } from '../../components/records/overview';
import { CreateAnalysisReport } from '../../components/records/create-analysis-report';
import { FileUploader } from "react-drag-drop-files";
import { getChatbot } from '../Request/Chatbot';
import { CreateAnalysisReportV2 } from '../../components/records/create-analysis-report-v2';

export function CustomDataAnalysis(props) {
    let navigate = useNavigate();
    const { user } = props;
    const { id } = useParams();
    const [analystList, setAnalystList] = useState([]);
    const [selectedAnalyst, setSelectedAnalyst] = useState({});
    const [selectedAnalystUsage, setSelectedAnalystUsage] = useState({});
    const [mustIntroduce, setMustIntroduce] = useState(false);

    useEffect(() => {
        getAnalystList();

    }, [])

    const getAnalystList = async () => {
        const result = await getAnalyst({
            token: user.token
        })
        if (!result.error && result.length > 0) {
            setAnalystList(result)
            console.log(result, id);
            const currAnalyst = result.find(obj => obj.hasOwnProperty("id") && obj.id === parseInt(id));
            console.log(currAnalyst);
            if (currAnalyst) {
                onSelectAnalyst(currAnalyst)
            }
        }

    }

    const onSelectAnalyst = (analyst) => {
        navigate('/custom-data-analysis/' + analyst.id);
        setSelectedAnalyst(analyst)
    }

    const onCreateAnalyst = async (formData) => {
        formData.append('token', user.token);
        console.log(formData);
        let analyst = await createAnalyst(formData);
        if (analyst.error) {
            return analyst.error
        }
        analyst = analyst.result
        setMustIntroduce(true)
        console.log(analyst);
        getAnalystList();
        navigate('/custom-data-analysis/' + analyst.id);
        setSelectedAnalyst(analyst)
        console.log(analyst);

    }

    const onClickCreateAnalyst = async () => {
        setSelectedAnalyst({})
        navigate('/custom-data-analysis/create');
    }

    const calCost = (model) => {
        // console.log("usage", selectedAnalystUsage, model);
        if (model === 'gpt-4-0125-preview' || model === 'gpt-4-1106-preview') {

            let completion_tokens = (selectedAnalystUsage.completion_tokens / 1000) * 0.03;
            let prompt_tokens = (selectedAnalystUsage.prompt_tokens / 1000) * 0.01;
            let total_tokens = completion_tokens + prompt_tokens;

            return { completion_tokens, prompt_tokens, total_tokens }
        }

        if (model === 'gpt-3.5-turbo-0125') {
            let completion_tokens = (selectedAnalystUsage.completion_tokens / 1000) * 0.0015;
            let prompt_tokens = (selectedAnalystUsage.prompt_tokens / 1000) * 0.0005;
            let total_tokens = completion_tokens + prompt_tokens;

            return { completion_tokens, prompt_tokens, total_tokens }
        }

        let completion_tokens = 0
        let prompt_tokens = 0
        let total_tokens = completion_tokens + prompt_tokens;

        return { completion_tokens, prompt_tokens, total_tokens }


    }

    return (
        <>

            <div className="content-wrapper d-flex flex-column">
                <div className="content" id="custom-data-analysis">
                    <div className="container-fluid d-flex flex-column flex-grow-1">
                        <nav class="navbar bg-body-tertiary p-2">
                            <div class="container-fluid">
                                <a class="navbar-brand" href="#">Custom Data Analysis</a>
                                <div class="d-flex" role="search">
                                    {/* <h4>$420</h4> */}
                                </div>
                            </div>
                        </nav>
                        <div className="row flex-grow-1 100vh">
                            <div className="col-lg-3 d-flex flex-column border-r">
                                <div class="list-group menu mt-2 flex-grow-1 overflow-auto">
                                    <button className='btn btn-primary btn-sm me-1 mt-2 mb-3' onClick={() => { onClickCreateAnalyst() }}>Create New Analyst</button>
                                    {
                                        analystList.map((analyst, index) => (
                                            <NavLink to={'/custom-data-analysis/' + analyst.id} reloadDocument={true}>
                                                <button type="button" class={`list-group-item  mb-2 list-group-item-action ${id == analyst.id ? 'active' : ' '}`}
                                                    // onClick={() => { onSelectAnalyst(analyst) }} 
                                                    aria-current="true" key={index}>

                                                    <h6 class="mb-0">{analyst.analyst_info.name}</h6>
                                                    <small class="mb-2">{analyst.analyst_info.description}</small>
                                                    <div class="d-flex w-100 justify-content-between">
                                                        <small>{humanizeDateDiff(analyst.analyst_info.created_at * 1000)}</small>
                                                    </div>
                                                </button>
                                            </NavLink>
                                        ))
                                    }

                                </div>
                            </div>
                            <div className="col-lg-9 border-r overflow-auto">
                                {
                                    (selectedAnalyst && Object.keys(selectedAnalyst).length > 0) ? <div className="flex-grow-1 d-flex flex-column">
                                        <div className="row flex-grow-1">
                                            <div className="col-lg-9 border-r">
                                                <CreateAnalysisReportV2 mustIntroduce={mustIntroduce} setMustIntroduce={setMustIntroduce} user={user} id={id} analyst={selectedAnalyst} setSelectedAnalystUsage={setSelectedAnalystUsage} />
                                            </div>
                                            <div className="col-lg-3 col-3-1 mt-3">
                                                <dl class="row">
                                                    <dt class="col-sm-12">Name</dt>
                                                    <dd class="col-sm-12">{selectedAnalyst.analyst_info.name}</dd>
                                                    <dt class="col-sm-12">Description</dt>
                                                    <dd class="col-sm-12">{selectedAnalyst.analyst_info.description}</dd>
                                                    <dt class="col-sm-12">Model</dt>
                                                    <dd class="col-sm-12">{selectedAnalyst.analyst_info.model}</dd>
                                                    {/* <dt class="col-sm-12">Files</dt>
                                                    <dd class="col-sm-12">{selectedAnalyst.file_info?.name}</dd> */}
                                                    <dt class="col-sm-12">Created</dt>
                                                    <dd class="col-sm-12">{humanizeDateDiff(selectedAnalyst.analyst_info.created_at * 1000)}</dd>

                                                    {/* <dt class="col-sm-12 mt-3">Prompt Cost</dt>
                                                    <dd class="col-sm-12">${calCost(selectedAnalyst.analyst_info.model).prompt_tokens.toFixed(3)}</dd>
                                                    <dt class="col-sm-12">Response Cost</dt>
                                                    <dd class="col-sm-12">${calCost(selectedAnalyst.analyst_info.model).completion_tokens.toFixed(3)}</dd>
                                                    <dt class="col-sm-12">Total Cost</dt>
                                                    <dd class="col-sm-12">${calCost(selectedAnalyst.analyst_info.model).total_tokens.toFixed(3)}</dd> */}

                                                </dl>
                                            </div>
                                        </div>
                                    </div> :
                                        <CreateNewAnalyst user={user} onCreateAnalyst={onCreateAnalyst} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function CreateNewAnalyst(props) {
    const { onCreateAnalyst, user } = props
    const fileTypes = ["JSONL", "CSV"];
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [aiModel, setAiModel] = useState('3');
    const [aiInstruction, setAiInstruction] = useState('You are an exceptionally talented data analyst, recognized for your ability to interpret and derive insights from complex datasets. In this task, you are provided with JSON data containing conversation logs. Before proceeding with any analysis or crafting a response, it is imperative that you meticulously review the data. Always ensure that the data you are working with is fully synchronized with the data provided to you. By doing so, you ensure that your analysis remains accurate and contextually relevant. Consistently checking for synchronization allows you to maintain data integrity, leading to insightful, well-informed results. Your thorough attention to detail will guarantee that your conclusions are both precise and reliable.');
    const [submissionError, setSubmissionError] = useState('');
    const [chatbotList, setChatbotList] = useState([]);
    const [selectedChatbot, setSelectedChatbot] = useState("");
    const [selectedDates, setSelectedDates] = useState({});
    const [isLoading, setIsLoading] = useState(false);




    const handleChange = (file) => {
        setUploadedFiles([file]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        if (!name || !aiModel || !aiInstruction) {
            // console.log(name, aiModel, aiInstruction);
            setSubmissionError('Please fill in all required fields and ensure at least one file is uploaded.');
            return;
        }

        // const formDataObj = {
        //     name: name,
        //     model: aiModel,
        //     description: description,
        //     instruction: aiInstruction,
        //     selected_chatbot: selectedChatbot,
        //     dates: selectedDates // assuming selectedDates is already an object
        // };

        // const jsonData = JSON.stringify({
        //     action: "create",
        //     data: formDataObj
        // });
        const formData = new FormData();
        formData.append('name', name);
        formData.append('model', aiModel);
        formData.append('description', description);
        formData.append('instruction', aiInstruction);
        formData.append('selected_chatbot', selectedChatbot);
        formData.append('date_range', JSON.stringify(selectedDates));
        const onError = await onCreateAnalyst(formData)
        setSubmissionError(onError?.message ? onError.message : "Something went wrong");
        setIsLoading(false)
        // console.log(formData);
    };

    const removeFile = async (index) => {
        setUploadedFiles(currentFiles => currentFiles.filter((_, idx) => idx !== index));
    }

    useEffect(() => {
        setSelectedDates({
            startDate: addDays(new Date(), -30),
            endDate: addDays(new Date(), 1),
        })
        initChatbotList();
        // const result = await getRecords({
        //     token: user.token,
        //     id: chatbotId,
        //     startDate: startDate,
        //     endDate: endDate,
        // })
    }, [])

    const initChatbotList = async () => {
        const res = await getChatbot({
            id: "",
            token: user.token
        });
        if (res.result.code === 1) {
            const chatbots = res?.result?.data.map((chatbot, index) => (
                <option value={chatbot.chatbot_id}>{chatbot.name}</option>
            ))
            setChatbotList(chatbots)
            console.log(chatbots);
        }

    }

    const [showPicker, setShowPicker] = useState(false);

    const togglePicker = () => setShowPicker(!showPicker);

    const [state, setState] = useState({
        "selection": {
            startDate: addDays(new Date(), -30),
            endDate: addDays(new Date(), 1),
            key: 'selection'
        }
    });

    const formatDateRange = () => {
        const { startDate, endDate } = state.selection;
        if (startDate && endDate) {
            return `${startDate.toLocaleDateString()} to ${endDate.toLocaleDateString()}`;
        }
        return 'Select Date Range';
    };

    const onDateChange = (ranges) => {
        setState({ ...state, ...ranges });
        togglePicker();
        setSelectedDates({
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate
        })
    }

    useEffect(() => {
        console.log(aiModel);
    }, [aiModel])

    return <>
        <form onSubmit={handleSubmit} >
            <div className="row flex-grow-1 d-flex justify-content-center align-items-center pt-4">
                <div className="col-lg-8">
                    <legend className='m-0'>Build Your Own Analyst</legend>
                    <p><small>Build your personalized AI Analyst by configuring custom parameters and providing specific instructions.</small></p>
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Name</label>
                        <input type="text" className="form-control" id="nameInput" placeholder="Your Name" value={name} onChange={e => setName(e.target.value)} required />
                    </div>
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Description</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" value={description} onChange={e => setDescription(e.target.value)}></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">AI Model</label>
                        <select class="form-select" aria-label="Default select example" value={aiModel} onChange={e => setAiModel(e.target.value)} required>
                            {/* <option selected value="0">gpt-3.5-turbo-0125 [input: $0.0005 / 1K tokens] [Output: $0.0015 / 1K tokens]</option>
                            <option value="1">gpt-4-0125-preview [input: $0.01 / 1K tokens] [Output: $0.03 / 1K tokens]</option>
                            <option value="2">gpt-4-1106-preview [input: $0.01 / 1K tokens] [Output: $0.03 / 1K tokens]</option> */}
                            <option selected value="3">gpt-4o [input: $$0.0050 / 1K tokens] [Output: $0.0150 / 1K tokens]</option>
                            {/* <option selected value="4">gpt-4-turbo-2024-04-09 </option> */}
                            
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">AI Instruction</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="8" value={aiInstruction} onChange={e => setAiInstruction(e.target.value)} required></textarea>
                    </div>
                    {/* <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">File</label>
                        <FileUploader handleChange={handleChange} classes="upload-drop-file" label="Drop Your JSONL OR CSV File Here" name="file" multiple={false} maxSize={15} types={fileTypes} />
                    </div> */}
                    <div class="mb-3">
                        <div class="row ">
                            <div class="col-md-6">
                                <label for="exampleFormControlTextarea1" class="form-label">Select Chatbot</label>
                                <select class="form-select" aria-label="Default select example" value={selectedChatbot} onChange={e => setSelectedChatbot(e.target.value)} >
                                    <option selected value="">Select Chatbot</option>
                                    {chatbotList}
                                </select>
                            </div>
                            <div class="col-md-6">
                                <div class="col">
                                    <label for="inputEmail4" class="form-label">Date Range</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onClick={togglePicker}

                                        value={formatDateRange()}
                                    />
                                </div>
                                {showPicker && (
                                    <div className="row">
                                        <DateRangePicker
                                            onChange={onDateChange}
                                            months={1}
                                            minDate={addDays(new Date(), -300)}
                                            maxDate={addDays(new Date(), 900)}
                                            direction="vertical"
                                            scroll={{ enabled: true }}
                                            ranges={[state.selection]}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="row ">
                            <div class="col-md-6">
                                <ul class="list-group">
                                    {
                                        uploadedFiles.map((file, index) => (
                                            <li class="list-group-item d-flex justify-content-between align-items-center b-0">
                                                {file.name}
                                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                                    <button className='btn btn-danger btn-sm me-1 mt-2 mb-3' onClick={() => { removeFile(index) }}>Remove</button>
                                                </div>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        {submissionError && <div className="alert alert-danger" role="alert">{submissionError}</div>}
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button type="submit" className="btn btn-primary btn-sm me-1 mt-2 mb-3" 
                            // disabled={isLoading ? true : false}
                            >{isLoading ? "Creating Analyst..." : "Create"}</button>
                        </div>

                    </div>
                </div>
            </div>

        </form>

        {/* <div className="row flex-grow-1 d-flex justify-content-center align-items-center pt-4">
            <div className="col-lg-8">
                <legend className='m-0'>Build Your Own Analyst</legend>
                <p><small>Build your personalized AI Analyst by configuring custom parameters and providing specific instructions.</small></p>
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Name</label>
                    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Description</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">AI Model</label>
                    <select class="form-select" aria-label="Default select example">
                        <option selected>Open this select menu</option>
                        <option value="1">gpt-3.5-turbo-0125 [input: $0.0005 / 1K tokens] [Output: $0.0015 / 1K tokens]</option>
                        <option value="2">gpt-4-0125-preview [input: $0.01 / 1K tokens] [Output: $0.03 / 1K tokens]</option>
                        <option value="3">gpt-4-1106-preview [input: $0.01 / 1K tokens] [Output: $0.03 / 1K tokens]</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">AI Instruction</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="8"></textarea>
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">File</label>
                    <FileUploader handleChange={handleChange} classes="upload-drop-file" label="Drop Your JSONL OR CSV File Here" name="file" types={fileTypes} />
                </div>
                <div class="mb-3">
                    <div class="row ">
                        <div class="col-md-6">
                            <ul class="list-group">
                                {
                                    uploadedFiles.map((file, index) => (
                                        <li class="list-group-item d-flex justify-content-between align-items-center b-0">
                                            {file.name}
                                            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <button className='btn btn-danger btn-sm me-1 mt-2 mb-3'>Remove</button>
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button className='btn btn-primary btn-sm me-1 mt-2 mb-3'>Build</button>
                    </div>
                </div>
            </div>
        </div> */}
    </>
}