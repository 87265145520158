import React, { useEffect, useState } from 'react';
export function ChatbotInfoForm(props) {
    const { name, setName, description, setDescription, selectedScene, onSelectScene, selectedDatasets, setSelectedDatasets, datasets, scenes, suggestions, setSuggestions } = props;
    const suggestionLimit = 10;
    const handleInputChange = (index, value) => {
        const newSuggestions = [...suggestions];

        if (index === suggestions.length - 1 && value.trim() !== '') {
            if (suggestions.length < suggestionLimit) {
                newSuggestions.push('');
            }
        } else if (index === suggestions.length - 2 && value.trim() === '') {
            newSuggestions.pop();
        }

        newSuggestions[index] = value;
        setSuggestions(newSuggestions);
    };

    const handleInputBlur = (index) => {
        if (suggestions.length < suggestionLimit) {
            if (index > 0 && index < suggestions.length - 1 && suggestions[index].trim() === '') {
                const newSuggestions = [...suggestions];
                newSuggestions.splice(index, 1);
                setSuggestions(newSuggestions);
            }
        }
    };



    return (
        <><div className='row justify-content-center mt-3'>
            <div class="row">
                <div className='col'>
                    <h4>About Your Chatbot</h4>
                    <p>Customize your chatbot's profile, select conversation datasets, set up the initial dialogue, and choose an avatar to create a unique and engaging user experience.</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="pb-3 form-group">
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Name</label>
                            <input type="email" class="form-control" value={name} onChange={(e) => { setName(e.target.value) }} id="exampleFormControlInput1" placeholder="name@example.com" />
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Description</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" value={description} onChange={(e) => { setDescription(e.target.value) }} rows="3"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="pb-3 form-group">
                        <label for="exampleFormControlTextarea1" class="form-label">Select Scene</label>
                        <select class="form-select" value={selectedScene} onChange={(e) => onSelectScene(e.target.value)}>
                            <option value="" selected>Select Your Scene</option>
                            {
                                scenes?.map((row, index) => (
                                    <option value={index}>{row.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="pb-3 form-group">
                        <label for="exampleFormControlTextarea1" class="form-label">Select Datasets</label>
                        <select class="form-select" value={selectedDatasets} onChange={(e) => setSelectedDatasets(e.target.value)} aria-label="Default select example">
                            <option selected value="">Select Your Datasets</option>
                            {
                                datasets?.map((row, index) => (
                                    row.data_entries ?
                                        <option value={index}>{row.name} / {row.data_entries.length} Entries</option> : <></>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="pb-3 form-group">
                        <label class="form-label">Startup Suggestions</label>
                        {suggestions.map((suggestion, index) => (
                            <input
                                key={index}
                                type="text"
                                className="form-control mb-2"
                                placeholder="Enter your suggestion here"
                                value={suggestion}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                                onBlur={() => handleInputBlur(index)}
                            />
                        ))}
                        <button type="button" onClick={() => setSuggestions([...suggestions, ''])} class={`btn btn-link float-right ${suggestions.length >= suggestionLimit ? 'disabled' : ''}`}>Add New Field</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
