import React, { useEffect, useState, useRef } from 'react';
import { checkStatusAnalysis, createRunAnalysis, getResultAnalysis } from '../../pages/Request/Records';
import { useNavigate } from 'react-router-dom';
import { ServerAPIUrl } from '../../pages/Request/Env';
import { RequestErrorHandler } from '../../pages/Request/ErrorHandler';
import Markdown from 'marked-react';
import ReactECharts from 'echarts-for-react';
import { AnalyzingLoading } from '../loading/loading';
import { Navigate } from 'react-router-dom';
import { humanizeDateDiff } from '../../utils/helpers';
import Plot from 'react-plotly.js';

export function CreateAnalysisReport(props) {
    let navigate = useNavigate();
    const { user, analyst, setSelectedAnalystUsage } = props;
    const [inputPrompt, setInputPrompt] = useState('');
    const [analysisStatus, setAnalysisStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [threads, setThreads] = useState([]);
    // const [threadId, setThreadId] = useState(id == "all" ? "" : id);
    const [threadId, setThreadId] = useState("");

    // thread_0rW45wVH4sYOM1V6fpEfcgkq - top 10 current jobs for individuals who are employed and possess a diploma or higher.
    // thread_ewn9UQVypvfTnnqp3YaKSg5T - 3 params
    // thread_x2Zj6WsnfZedg9TxGvesD9yR - SFC
    // thread_BcwHb2ZyYObVF0bBbEhX7ORB - tree
    // "thread_5mxtCUoUWGzjVSiGhGCuYfkB" - age group
    // "thread_5dbyTRVS3lZNa4YnFG6ACuPx"

    const [echartsData, setEchartsData] = useState({});
    const intervalRef = useRef(null);

    // console.log(filter);
    useEffect(() => {
        console.log(analyst);
        setThreadId("")
        setInputPrompt("")
        setThreads([])
        loadAnalyst(analyst.thread_id);

        return () => {
            // Cleanup function to clear the interval when the component unmounts or the analyst changes
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
            setThreadId("")
            setInputPrompt("")
            setThreads([])
            setIsLoading(false)
        };
    }, [analyst])

    const loadAnalyst = async (thread_id) => {
        setIsLoading(true)
        setThreadId(thread_id)
        await runAnalysis(true)
    }

    const onAnalysisComplete = async (thread_id) => {
        const analysisResult = await getResultAnalysis({
            thread_id: thread_id,
            token: user.token
        })




        if (analysisResult?.result?.data?.data?.length > 0) {
            setThreads(currentThreads => {
                const updatedThreads = [...currentThreads]; // Clone the current threads
                analysisResult.result.data.data.slice().reverse().forEach((thread) => {
                    if (!updatedThreads.some(existingThread => existingThread.id === thread.id)) {
                        // if (thread.content[0]?.text?.value.length > 0) {
                        updatedThreads.push(thread); // Add the new thread if the first content's text has length > 0
                        // }
                    }
                });
                return updatedThreads; // Return the updated list
            });
            setSelectedAnalystUsage(analysisResult.result.usage)
        }

    }

    const runAnalysis = async (isInit = false) => {
        if (isLoading) return;

        setInputPrompt("")

        let thread_id = analyst.thread_id;
        let run_id = analyst.run_id;
        if (!isInit) {
            const createRunAnalysisResult = await createRunAnalysis({
                token: user.token,
                message: inputPrompt,
                id: analyst.id
            })

            if (createRunAnalysisResult?.result?.code != 0) {
                if (createRunAnalysisResult?.result?.message_code == 404) {
                    alert("Invalid Thread ID")
                    window.location.href = `all`;
                    // navigate('/custom-data-analysis/all');
                } else {
                    RequestErrorHandler(0)
                }
                return;
            }

            thread_id = createRunAnalysisResult.result.data.thread_id;
            run_id = createRunAnalysisResult.result.data.run_id;
        }


        setThreadId(thread_id)
        await onAnalysisComplete(run_id);
        setIsLoading(true)

        intervalRef.current = setInterval(async () => {
            try {

                const checkStatusAnalysisResult = await checkStatusAnalysis({
                    thread_id: thread_id,
                    run_id: run_id,
                    token: user.token,
                });
                // console.log("checking... ", checkStatusAnalysisResult, checkStatusAnalysisResult.result.data.status);


                if (checkStatusAnalysisResult.result.data.status === 'completed') {
                    clearInterval(intervalRef.current);
                    await onAnalysisComplete(thread_id);
                    // console.log("Completed:", checkStatusAnalysisResult);
                    setIsLoading(false)
                }

                if (checkStatusAnalysisResult.result.data.status === 'in_progress' && checkStatusAnalysisResult.result.data.status !== analysisStatus) {
                    // console.log("in_progress");
                }

                if (checkStatusAnalysisResult.result.data.status === 'error' || checkStatusAnalysisResult.result.data.status === "expired" && checkStatusAnalysisResult.result.data.status !== analysisStatus) {
                    setIsLoading(false)
                    clearInterval(intervalRef.current);
                    // console.log(checkStatusAnalysisResult.result.data.status, checkStatusAnalysisResult.result.data);
                }

                await onAnalysisComplete(thread_id);



            }

            catch (error) {
                if (error?.result?.message_code == 404) {
                    // Navigate(`custom-data-analysis/all`)

                    navigate('/custom-data-analysis/all');
                }
                //     clearInterval(interval); 
                //     // setIsLoading(false)
                //     // RequestErrorHandler(0)
                console.log("Error during status check:", error);
                //     // clearInterval(interval); // Optional: stop interval on error

            }
        }, 1000);

    }

    return (
        <div className='row ' id='create-analysis-report'>
            <div class="col-md-10 offset-md-1 ca-container">
                <div className="row mt-4 ca-result-container">
                    <ThreadListComponent isLoading={isLoading} threads={threads} />
                    {/* {
                        threads.length > 0 ?
                            <ThreadListComponent threads={threads} /> :
                            <div class="col-12 text-center">
                                <p>Please enter your prompt below, and the AI will generate it for you.</p>
                            </div>
                    } */}
                </div>
                <div className="row  ca-input-container">
                    <div class="col-12">
                        <div className="input-prompt mt-1">
                            <textarea
                                value={inputPrompt}
                                onChange={(e) => { setInputPrompt(e.target.value) }}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter' && !event.shiftKey) {
                                        event.preventDefault();
                                        runAnalysis();
                                    }
                                }}
                                placeholder="Type here and press Enter, or Shift+Enter for a new line"
                            />
                            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button class="btn btn-primary me-md-2" type="button" onClick={runAnalysis}>Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const ThreadListComponent = (props) => {
    const { isLoading, threads } = props
    // console.log(threads);
    useEffect(() => {
        const handleError = (event) => {
            // You might want to filter or check the error message to determine if it's from ECharts
            console.error('Global error captured:', event.message);
            // Implement your logic to handle the error, like showing an alert or logging the error
        };

        window.addEventListener('error', handleError);

        return () => {
            window.removeEventListener('error', handleError);
        };
    }, threads)

    function convertMarkdownToHTML(text) {
        // Convert headers (### Header)
        text = text.replace(/### (.*?)(\n|$)/g, '<h3>$1</h3>');

        // Convert bold text (**bold**)
        text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

        // Convert italic text (*italic*)
        text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');

        // Replace line breaks with <br> tags
        text = text.replace(/\n/g, '<br>');

        return text;
    }


    function detectJSON(data) {
        // console.log(data);
        // Check if data is already an object
        if (typeof data === 'object' && data !== null) {
            // console.log('Data is a JSON object');
            return data;
        }

        // Check if data is a string that can be parsed into a JSON object
        if (typeof data === 'string') {
            try {
                const parsed = JSON.parse(data);
                // If parsing succeeds without throwing an error, it's a valid JSON string
                // console.log('Data is a JSON string');
                return parsed;
            } catch (error) {
                // Parsing failed, so it's not a JSON string
                // console.log('Data is a string, but not a valid JSON string');
                return {};
            }
        }

        // If data is neither a string nor an object
        // console.log('Data is neither a JSON object nor a JSON string');
        return {};
    }


    const EventsDict = (event) => {
        console.log("EventsDict", event);
    }

    const generateEChart = () => {

    }


    return (
        <div class="col-12">
            <div class="list-group">
                {
                    threads
                        .map((thread, index) => (
                            <div class="list-group-item  border-0" key={index}>
                                <div class="d-flex w-100 justify-content-between">
                                    <h6 class="mb-1"><b >{thread.role === 'assistant' ? "SynergyBot" : "You"}</b></h6>
                                    <small>{humanizeDateDiff(thread.created_at * 1000)}</small>
                                </div>
                                {/* <p className='m-0'><b >{thread.role === 'assistant' ? "SynergyBot" : "You"}</b></p> */}
                                <p >
                                    {
                                        thread?.content && [...thread.content] // Create a shallow copy of the array
                                            .reverse() // Reverse the order
                                            .map((content, index) => {
                                                // Debugging: Log the index and content type
                                                if (content?.type === 'text') {
                                                    return <Markdown>{content?.text?.value}</Markdown>;
                                                } else if (content?.type === 'image_file') {
                                                    return <img key={index} className='img-assist' src={`${ServerAPIUrl}/${content?.filename}`} alt="" />;
                                                } else if (content?.type === 'chart') {
                                                    const options = detectJSON(content?.data);

                                                    const data = options?.data
                                                    const layout = options?.layout ? options?.layout : { width: "100%", height: "auto", title: 'Report Generated' }
                                                    var config = {
                                                        showEditInChartStudio: true,
                                                        plotlyServerURL: "https://chart-studio.plotly.com",
                                                        scrollZoom: true,
                                                        editable: true,
                                                        displaylogo: false,
                                                        // responsive: true
                                                      };
                                                    console.log(options);
                                                    if (data) {
                                                        return <Plot
                                                            data={options.data}
                                                            layout={layout}
                                                            config={config}
                                                        />
                                                    }



                                                    // return JSON.stringify(content?.data)
                                                    // try {

                                                    //     const options = detectJSON(content?.data);

                                                    //     return <ReactECharts
                                                    //         notMerge={true}
                                                    //         lazyUpdate={true}
                                                    //         theme={"theme_name"}
                                                    //         onEvents={EventsDict}
                                                    //         // showLoading={true}
                                                    //         option={detectJSON(options)} />;
                                                    // } catch (error) {
                                                    //     console.log("ReactECharts", error);
                                                    // }
                                                } else {
                                                    return null;
                                                }
                                            })
                                    }
                                </p>

                            </div>
                        ))
                }

                {
                    isLoading ? <AnalyzingLoading /> : ""
                }

            </div>
        </div>
    )
}