import { ServerAPIUrl } from "./Env";

const AccountLogin = (data) => {
    return fetch(ServerAPIUrl+"/api/account/login", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(err => {
            console.error(err);
        });
}

export { AccountLogin }