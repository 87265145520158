
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { getRecords, getThreads } from '../Request/Records';
import { humanizeDateDiff } from '../../utils/helpers';
import ReactECharts from 'echarts-for-react';
import { SelectV2, SelectV3 } from '../../components/select';
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Overview } from '../../components/records/overview';
import { CreateAnalysisReport } from '../../components/records/create-analysis-report';

function ViewSelectedRecords(props) {

    const { id, conversation_id, analysis_result, created_at } = props.selectedThread


    return (

        <div className="container-fluid">
            <div className='row border-b'>
                <div className="col pt-2">
                    <b>{analysis_result.themeTopic}</b>
                    <p>{analysis_result.overview}</p>

                    <table class="table table-hover table-bordered align-middle">
                        <tbody>
                            <tr >
                                <td width={"30%"}>Keywords Used</td>
                                <td>
                                    {
                                        analysis_result.listOfKeywords.map((keywords, index) => (
                                            <span key={index} class="badge text-bg-secondary mr-1">{keywords}</span>
                                        ))
                                    }
                                </td>
                            </tr>
                            <tr >
                                <td>Sentiment Analysis</td>
                                <td><span class="badge text-bg-success mr-1">{analysis_result.sentiment}</span><span class="badge text-bg-warning mr-1">{analysis_result.reaction}</span></td>
                            </tr>
                            <tr >
                                <td>Name</td>
                                <td>{analysis_result.userName}</td>
                            </tr>
                            <tr >
                                <td>Email Address</td>
                                <td>{analysis_result.userEmailAddress}</td>
                            </tr>
                            <tr >
                                <td>Contact Number</td>
                                <td>{analysis_result.userContactNumber}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className='row mt-3'>
                {
                    analysis_result.emailMessageForClient.body !== "N/A" ?
                        <div className="col-6">
                            <p><b>Email Sent To Customer:</b></p>
                            <div class="pb-3 form-group">
                                <b>{analysis_result.emailMessageForClient.subject}</b>
                                <p dangerouslySetInnerHTML={{ __html: analysis_result.emailMessageForClient.body }}></p>
                            </div>
                        </div>
                        : <></>
                }
                {
                    analysis_result.emailMessageForAdmin.body !== "N/A" ?
                        <div className="col-6">
                            <p><b>Email Sent To Admin:</b></p>
                            <div class="pb-3 form-group">
                                <b>{analysis_result.emailMessageForAdmin.subject}</b>
                                <p dangerouslySetInnerHTML={{ __html: analysis_result.emailMessageForAdmin.body }}></p>
                            </div>
                        </div>
                        : <></>
                }
            </div>

        </div>
    )

}

export function Records(props) {
    const { user } = props;
    const { id } = useParams();
    const [records, setRecords] = useState([]);
    const [threads, setThreads] = useState([]);
    const [selectedThread, setSelectedThread] = useState(null);
    const [threadMessages, setThreadMessages] = useState(null);

    const [selectedTab, setSelectedTab] = useState(1);  
    const [filter, setFilter] = useState({
        startDate: addDays(new Date(), -30),
        endDate: addDays(new Date(), 1),
        chatbot_id: ''
    });  
 



    const { data, onEdit, onDeleteEntry } = props;

    const ThreadContainer = ({ row }) => (

        <div className='thread' data-tag="allowRowEvents">
            <div class="list-group-item list-group-item-action">
                <div class="d-flex w-100 justify-content-between">
                    <b class="mb-1">{row.analysis_result.themeTopic} </b>
                    <small>{humanizeDateDiff(row.created_at)}</small>
                </div>


                <p class="mb-1">{row.analysis_result.overview.slice(0, 200)}...</p>
                {
                    row.analysis_result.listOfKeywords.map((keywords, index) => (
                        <small key={index}><span class="badge text-bg-secondary mr-1">{keywords}</span></small>
                    ))
                }
                <small>
                    <span class="badge text-bg-success mr-1">{row.analysis_result.sentiment}</span>
                    <span class="badge text-bg-warning mr-1">{row.analysis_result.reaction}</span>
                </small>

                <small><span class="badge text-bg-primary mr-1">{row.chatbot_name}</span></small>
            </div>
        </div>
    );

    const columns = [
        {
            name: 'Threads',
            selector: row => row,
            wrap: true,
            maxWidth: '600px',
            cell: row => <ThreadContainer row={row} />,
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    const onSelectThread = async (selectedThread) => {
        setSelectedThread(selectedThread);
        const result = await getThreads({
            token: user.token,
            conversation_id: selectedThread.conversation_id,
            chatbot_id: selectedThread.chatbot_id
        })
        setThreadMessages(result)
    }



    useEffect(() => {
        initGetRecords("", addDays(new Date(), -30), addDays(new Date(), 1))
    }, [])

    const initGetRecords = async (chatbotId, startDate, endDate) => {
        console.log( chatbotId, startDate, endDate);
        const result = await getRecords({
            token: user.token,
            id: chatbotId,
            startDate: startDate,
            endDate: endDate,
        })
        const newThreads = result.reduce((acc, currentResult) => {
            const threadsWithToken = currentResult.threads.map(thread => ({
                ...thread,
                chatbot_id: currentResult.chatbot_id,
                chatbot_name: currentResult.name
            }));
            return acc.concat(threadsWithToken); // assuming currentResult.threads is an array
        }, []);

        newThreads.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setThreads(newThreads);
        setRecords(result);
        setSelectedThread(null)
        setThreadMessages(null)
    }

    const reloadRecords = (chatbotId, startDate, endDate) => {
        initGetRecords(chatbotId, startDate, endDate)
    }



    return (
        <>
            <div className="content-wrapper" id="records-page">
                {/* <div className="content-header p-0">
                    <div className="container-fluid">
                        <div className="row ">
                            <div className="col-sm-6">
                                <h1 className="m-0">Conversations</h1>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="content vh-100 overflow-auto">
                    <div className="container-fluid vh-100">
                        <div className='row border-b p-2'>
                            <HeaderSection records={records} setFilter={setFilter} reloadRecords={reloadRecords} />
                        </div>
                        {
                            selectedTab == -1 ? 
                            <CreateAnalysisReport filter={filter} user={user}/>
                        :<div className='row'>
                        <div className='col-3 border-r'>
                            <div className=" thread-container p-0">
                                <DataTable
                                    columns={columns}
                                    data={threads}
                                    noTableHead
                                    highlightOnHover
                                    pagination
                                    paginationPerPage={7}
                                    onRowClicked={(row, event) => {
                                        onSelectThread(row);
                                        setSelectedTab(1)
                                    }}

                                />
                            </div>
                        </div>
                        <div className='col-6 border-r'>
                            <div className='row border-b'>
                                <nav class="tab-nav nav nav-justified pr-0" >
                                    <a class={`nav-link nav-item ${selectedTab == 0 ? 'active' : ''}`} 
                                    // onClick={() => setSelectedTab(0)}
                                     aria-current="page" href="#" >Consolidated Conversations Analysis</a>
                                    <a class={`nav-link nav-item ${selectedTab == 1 ? 'active' : ''}`} onClick={() => setSelectedTab(1)}>Individual Conversation Analysis</a>
                                </nav>
                            </div>

                            {
                                selectedTab == 0 ? <Overview records={records} onClickCreateAnalysis={()=>{
                                    setSelectedTab(-1)
                                }}/> : <></>
                            }
                            {
                                (selectedTab == 1) ?
                                    <>
                                        {selectedThread ? <ViewSelectedRecords selectedThread={selectedThread} /> : <div>No Conversation Selected</div>}
                                    </> : <></>
                            }
                        </div>
                        <div className='col-3 border-r'>
                            <ThreadView user={user} threadMessages={threadMessages} />
                        </div>

                    </div>
                            
                        }
                        
                    </div>
                </div>
            </div>

        </>
    )
}


function HeaderSection({ records, reloadRecords,setFilter }) {

    const [selectedDates, setSelectedDates] = useState({});
    const [selectedChatbots, setSelectedChatbots] = useState("");

    const chatbotList = records.map(chatbot => ({
        value: chatbot.chatbot_id,
        label: chatbot.name,
    }))
    // const [chatbotList, setChatbotList] = useState( );
    // console.log("records",records);
    // console.log("chatbotList",chatbotList);

    useEffect(() => {
        setSelectedDates({
            startDate: addDays(new Date(), -30),
            endDate: addDays(new Date(), 1),
        })
    }, [records])

    const [state, setState] = useState({
        "selection": {
            startDate: addDays(new Date(), -30),
            endDate: addDays(new Date(), 1),
            key: 'selection'
        }
    });

    

    const formatDateRange = () => {
        const { startDate, endDate } = state.selection;
        if (startDate && endDate) {
            return `${startDate.toLocaleDateString()} to ${endDate.toLocaleDateString()}`;
        }
        return 'Select Date Range';
    };

    const [showPicker, setShowPicker] = useState(false);

    const togglePicker = () => setShowPicker(!showPicker);

    const handleSelect = (ranges) => {

        setState({ ...state, ...ranges });
        togglePicker();

        setSelectedDates({
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate
        })
        setFilter({
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
            chatbot_id: selectedChatbots
        })

        reloadRecords(selectedChatbots, ranges.selection.startDate, ranges.selection.endDate)

    };

    const onSelectChatbot = (chatbot) => {
        var chatbotIds = [];
        chatbot.map((row, index) => {
            chatbotIds.push(row.value)
        })
        const chatbotIdsList = chatbotIds.join(', ')
        setSelectedChatbots(chatbotIdsList);

        setFilter({
            startDate:selectedDates.startDate,
            endDate: selectedDates.endDate,
            chatbot_id: chatbotIdsList
        })
        reloadRecords(chatbotIdsList, selectedDates.startDate, selectedDates.endDate)
    }

    return (<>
        <div className="col-sm-6">
            <h1 className="m-0">Conversation Analysis</h1>
        </div>
        <div className="col-sm-6">
            <div class="row g-3">
                <div class="col">
                    <label for="inputEmail4" class="form-label">Chatbot</label>
                    <SelectV3
                        defaultFormat={chatbotList}
                        selectedFormat={chatbotList}
                        placeholder={"Filter Chatbots (Default: All)"}
                        onChange={(e) => { onSelectChatbot(e) }}
                    />
                </div>

                <div class="col">
                    <label for="inputEmail4" class="form-label">Date Range</label>
                    <input
                        type="text"
                        className="form-control"
                        onClick={togglePicker}

                        value={formatDateRange()}
                    />
                </div>
                {showPicker && (
                    <div className="row">
                        <DateRangePicker
                            onChange={handleSelect}
                            months={1}
                            minDate={addDays(new Date(), -300)}
                            maxDate={addDays(new Date(), 900)}
                            direction="vertical"
                            scroll={{ enabled: true }}
                            ranges={[state.selection]}
                        />
                    </div>
                )}
            </div>
        </div>
    </>)
}

function ThreadView(props) {
    const { user, threadMessages } = props
    const [selectedTab, setSelectedTab] = useState(0);

    if (threadMessages) {
        return (
            <div className="container-fluid p-0">
                <div className='row border-b'>
                    <nav class="tab-nav nav nav-justified pr-0" >
                        <a class={`nav-link nav-item ${selectedTab == 0 ? 'active' : ''}`} onClick={() => setSelectedTab(0)} aria-current="page" href="#">Raw Message</a>
                        {/* <a class={`nav-link nav-item ${selectedTab == 1 ? 'active' : ''}`} onClick={() => setSelectedTab(1)}>Insights</a> */}
                    </nav>
                </div>
                {
                    selectedTab == 0 ?
                        <div className='row border-b vh-100 overflow-auto '>
                            <div className="col p-0 thread-view">
                                {
                                    threadMessages.map((message, index) => (
                                        <div key={index}>
                                            {/* USER Message */}
                                            <div className='thread-message user' data-tag="allowRowEvents">
                                                <div class="list-group-item list-group-item-action">
                                                    <div class="d-flex w-100 justify-content-between">
                                                        <b class="mb-1">Customer</b>
                                                        <small>{humanizeDateDiff(message.created_at)}</small>
                                                    </div>
                                                    <p class="mb-1" dangerouslySetInnerHTML={{ __html: message.message }}></p>
                                                </div>
                                            </div>
                                            {/* AI Response */}
                                            <div className='thread-message assistant' data-tag="allowRowEvents">
                                                <div class="list-group-item list-group-item-action">
                                                    <div class="d-flex w-100 justify-content-between">
                                                        <b class="mb-1">SynergyBot</b>
                                                        <small>{humanizeDateDiff(message.created_at)}</small>
                                                    </div>
                                                    <p class="mb-1" dangerouslySetInnerHTML={{ __html: message.response }}></p>
                                                    <p><span class="badge text-bg-secondary mr-1 text-capitalize">{message.source}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div> : ""
                }

                {
                    selectedTab == 1 ?
                        <div className='row border-b vh-100 overflow-auto '>
                            <div className="col p-0">
                                <p>Insights</p>
                            </div>
                        </div> : ""
                }
            </div>
        )

    } else {

        

        return (
            <div className="container-fluid">
                <div className='row'>
                    <div className="col pt-2">
                        <div>No Conversation Selected</div>
                    </div>
                </div>
            </div>
        )
    }

}

