
import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import EditorJSParser from 'editorjs-parser';
import Embed from '@editorjs/embed';
import InlineImage from 'editorjs-inline-image';

class FacebookEmbed extends Embed {
    static get toolbox() {
        return {
            title: 'Facebook',
            icon: '<svg>...</svg>' // An SVG icon representing Facebook, or use any other icon you like.
        };
    }

    get CSS() {
        return {
            ...super.CSS,
            // Add custom CSS if needed.
        };
    }

    // Any other customization you'd like can go here...
}

const EditorJSDefault = forwardRef((props, ref) => {

    const { className, id, content, onChange, onReady, onError, isClear } = props;
    const parser = new EditorJSParser();
    const editorRef = useRef(null);
    const [editorInstance, setEditorInstance] = useState(null);

    const [localContent, setLocalContent] = useState(content);
    const userMadeChange = useRef(true);

    const tools = {
        header: Header,
        embed: {
            class: Embed,
            config: {
                services: {
                    facebook: {
                        // ... keep your existing regex, embedUrl, etc.
                        height: 200,
                        width: 300,
                        html: "<iframe width='300' height='20' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='border:none;overflow:hidden;width: 100%;'></iframe>",
                        // ... your other settings
                    },
                    // ... other services if needed
                }
            }
        },
        image: {
            class: InlineImage,
            inlineToolbar: true,
            config: {
                embed: {
                    display: true,
                }
            }
        }
        // ... your other tools
    };

    useEffect(() => {


        if (editorRef.current && !editorInstance) {
            const editorJs = new EditorJS({
                holder: editorRef.current,
                tools: tools,
                onChange: async () => {
                    if (!userMadeChange.current) {
                        userMadeChange.current = true;
                        return;
                    }
                    const savedContent = await editorJs.save();
                    const htmlOutput = parser.parse(savedContent);
                    setLocalContent(htmlOutput);  // set local state
                    if (htmlOutput !== content) {
                        onChange(htmlOutput);  // propogate the change outside
                    }
                }
            });

            setEditorInstance(editorJs);

            return () => {
                editorJs.isReady
                    .then(() => {
                        editorJs.destroy();
                    })
                    .catch(e => console.error('EditorJS error:', e));
            };
        }
    }, []);

    useEffect(() => {
        if (editorInstance && localContent !== content) {
            userMadeChange.current = false;
            editorInstance.blocks.renderFromHTML(content);
        }

    }, [content]);

    useImperativeHandle(ref, () => ({
        save: async () => {
            if (editorInstance) {
                return await editorInstance.save();
            }
        },
        clear: () => {
            if (editorInstance) {
                editorInstance.blocks.clear();
                setLocalContent("")
            }
        }
        // any other methods you'd like to expose...
    }));

    return (
        <div className={className} id={id} ref={editorRef}></div>
    )
})

export { EditorJSDefault }