import { ServerAPIUrl } from "./Env";
const getRecords = async (data) => {
    try {
        const response = await fetch(ServerAPIUrl + "/api/records/get", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        const jsonData = await response.json();
        return jsonData;
    } catch (err) {
        console.error(err);
        // Depending on your use case, you might want to rethrow the error or return a default value.
        throw err;  // or return some default/error value
    }
}

const getThreads = async (data) => {
    try {
        const response = await fetch(ServerAPIUrl + "/api/records/get/conversation", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        const jsonData = await response.json();
        return jsonData;
    } catch (err) {
        console.error(err);
        // Depending on your use case, you might want to rethrow the error or return a default value.
        throw err;  // or return some default/error value
    }
}

const createRunAnalysis = async (data) => {
    try {
        const response = await fetch(ServerAPIUrl + "/api/analysis/create-row-analysis", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        const jsonData = await response.json();
        return jsonData;
    } catch (err) {
        console.error(err);
        // Depending on your use case, you might want to rethrow the error or return a default value.
        throw err;  // or return some default/error value
    }
}

const checkStatusAnalysis = async (data) => {
    try {
        const response = await fetch(ServerAPIUrl + "/api/analysis/check-thread-status", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        const jsonData = await response.json();
        return jsonData;
    } catch (err) {
        console.error(err);
        // Depending on your use case, you might want to rethrow the error or return a default value.
        throw err;  // or return some default/error value
    }
}

const getResultAnalysis = async (data) => {
    try {
        const response = await fetch(ServerAPIUrl + "/api/analysis/get-response", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        const jsonData = await response.json();
        return jsonData;
    } catch (err) {
        console.error(err);
        // Depending on your use case, you might want to rethrow the error or return a default value.
        throw err;  // or return some default/error value
    }
}

const getAnalyst = async (data) => {
    try {
        const response = await fetch(ServerAPIUrl + "/api/analysis/get-all-analyst", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        const jsonData = await response.json();
        return jsonData;
    } catch (err) {
        console.error(err);
        // Depending on your use case, you might want to rethrow the error or return a default value.
        throw err;  // or return some default/error value
    }
}

const createAnalyst = async (data) => {
    try {
        const response = await fetch(ServerAPIUrl + "/api/analysis/create-analystV2", {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: data
        });

        const jsonData = await response.json();
        return jsonData;
    } catch (err) {
        console.error(err);
        // Depending on your use case, you might want to rethrow the error or return a default value.
        throw err;  // or return some default/error value
    }
}

export {
    getRecords, getThreads, createRunAnalysis, checkStatusAnalysis, getResultAnalysis, getAnalyst, createAnalyst
}