import React, { useEffect, useState } from 'react';
import { TwitterPicker } from 'react-color';

import { FileUploader } from "react-drag-drop-files";
import { uploadFile } from '../../pages/Request/Global';
import { ServerAPIUrl } from '../../pages/Request/Env';
export function AppearanceForm(props) {
    const { user, themeConfig, setThemeConfig, chatbotIconUrl, setChatbotIconUrl, chatbotBackground, setChatbotBackground } = props;

    const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

    const CBIconOnHandleChange = async (file) => {
        const res = await uploadFile(file, user.token);
        if (res.path) {
            // setChatbotIconUrl(res.path)
            setThemeConfig((themeConfig) => ({ ...themeConfig, iconUrl: res.path }))
        }
        console.log(res);
    }
    const CBBackgroundOnHandleChange = async (file) => {
        const res = await uploadFile(file, user.token);
        if (res.path) {
            setThemeConfig((themeConfig) => ({ ...themeConfig, backgroundUrl: res.path }))
            // setChatbotBackground(res.path)
        }
    }
    return (
        <>
            <div className='appearance-ui'>
                <div className='row justify-content-center mt-3'>
                    <div class="row">
                        <div className='col'>
                            <h4>Appearance Settings</h4>
                            <p>Personalize the visual elements of your chatbot interface, including background color, font styles, and sizes. Upload your custom chatbot icon and background image to align with your brand identity.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="pb-3 form-group">
                                <h6>Background Color</h6>
                                <TwitterPicker
                                    color={themeConfig.background}
                                    colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#FFFFFF', '#000000']}
                                    triangle="hide"
                                    onChange={(color) => { setThemeConfig((themeConfig) => ({ ...themeConfig, background: color.hex })) }}
                                    width="100%" />

                                <h6>Font Color</h6>
                                <TwitterPicker
                                    color={themeConfig.fontColor}
                                    colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#FFFFFF', '#000000']}
                                    triangle="hide"
                                    onChange={(color) => { setThemeConfig((themeConfig) => ({ ...themeConfig, fontColor: color.hex })) }}
                                    width="100%" />
                                <div class="row mt-2">
                                    <div class="col">
                                        <div class="input-group mb-3">
                                            <span class="input-group-text" id="basic-addon1">Font Size</span>
                                            <input type="number" class="form-control" value={themeConfig.fontSize} onChange={(e) => setThemeConfig((themeConfig) => ({ ...themeConfig, fontSize: e.target.value }))} />
                                            <span class="input-group-text">px</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="input-group mb-3">
                                            <span class="input-group-text" id="basic-addon1">Border Radius</span>
                                            <input type="number" class="form-control" value={themeConfig.borderRadius} onChange={(e) => setThemeConfig((themeConfig) => ({ ...themeConfig, borderRadius: e.target.value }))} />
                                            <span class="input-group-text">px</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="pb-3 form-group">
                                <div class="row">
                                    <div class="col-6">
                                        <label for="exampleFormControlInput1" class="form-label">Change Chatbot Icon</label>
                                        <FileUploader handleChange={CBIconOnHandleChange} classes="upload-drop-file" label="Drop Your Image File Here" name="file" types={fileTypes} />
                                    </div>
                                    <div class="col-6">
                                        <label for="exampleFormControlInput1" class="form-label">Change Chatbot Background</label>
                                        <FileUploader handleChange={CBBackgroundOnHandleChange} classes="upload-drop-file" label="Drop Your Image File Here" name="file" types={fileTypes} />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div className='appearance-image-container' style={{ backgroundImage: `url(${ServerAPIUrl + '/' + themeConfig.iconUrl})` }}></div>
                                    </div>
                                    <div class="col">
                                        <div className='appearance-image-container' style={{ backgroundImage: `url(${ServerAPIUrl + '/' + themeConfig.backgroundUrl})` }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </>
    )
}
