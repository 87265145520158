
import React, { useEffect, useState, useRef } from 'react';
import { checkStatusAnalysis, createRunAnalysis, getResultAnalysis } from '../../pages/Request/Records';
import { useNavigate } from 'react-router-dom';
import { ServerAPIUrl } from '../../pages/Request/Env';
import { RequestErrorHandler } from '../../pages/Request/ErrorHandler';
import Markdown from 'marked-react';
import ReactECharts from 'echarts-for-react';
import { AnalyzingLoading } from '../loading/loading';
import { Navigate } from 'react-router-dom';
import { humanizeDateDiff } from '../../utils/helpers';
import Plot from 'react-plotly.js';
import { analystWebSocket } from './handlers/analyst-web-socket';

export function CreateAnalysisReportV2(props) {
    let navigate = useNavigate();
    const wsClientRef = useRef(null);
    const { user, analyst, setSelectedAnalystUsage, setMustIntroduce, mustIntroduce } = props;

    const [inputPrompt, setInputPrompt] = useState('');
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const currentMessageRef = useRef(null);
    const messageEndRef = useRef(null);

    useEffect(() => {
        const wsClient = new analystWebSocket(user.token, analyst.id);

        wsClient.setCallback('onConnected', () => {
            console.log('WebSocket connection established');
            wsClientRef.current = wsClient;
        });

        wsClient.setCallback('onMessagesReceived', (receivedMessages) => {
            setMessages(receivedMessages.reverse());
            console.log('onMessagesReceived:', receivedMessages);


        });

        wsClient.setCallback('onMessageReceived', (message) => {
            console.log('onMessageReceived:', message);
        });

        wsClient.setCallback('onEventReceived', (event) => {
            console.log('onEventReceived:', event);
            onEventHandler(event)

        });

        wsClient.setCallback('onChartReceived', (chart) => {
            console.log('onChartReceived:', chart);
            updateLastMessage({ charts: chart })
        });

        wsClient.setCallback('onTextReceived', (text) => {
            console.log('onTextReceived:', text);
            if (!currentMessageRef.current) {
                console.log(" currentMessageRef.current created via onTextReceived");
                currentMessageRef.current = {
                    id: Date.now(), // or any unique identifier
                    content: {
                        text: text.delta
                    },
                    role: 'assistant',
                    created_at: Math.floor(Date.now() / 1000)
                };
                setIsLoading(true);
                setMessages((prevMessages) => [...prevMessages, currentMessageRef.current]);
            } else {
                currentMessageRef.current.content.text += text.delta;

                setMessages((prevMessages) => {
                    const updatedMessages = [...prevMessages];
                    const lastIndex = updatedMessages.length - 1;
                    updatedMessages[lastIndex] = { ...currentMessageRef.current };
                    return updatedMessages;
                });
            }
        });

        wsClient.setCallback('onTextDoneReceived', (textDone) => {
            console.log('onTextDoneReceived:', textDone);
            if (currentMessageRef.current) {
                currentMessageRef.current = null;
                setIsLoading(false);
                setMessages((prevMessages) => {
                    const updatedMessages = [...prevMessages];
                    const lastIndex = updatedMessages.length - 1;
                    updatedMessages[lastIndex] = { ...updatedMessages[lastIndex], status: null, status_code: null, content: { text: textDone.content } };
                    return updatedMessages;
                });
                console.log(" currentMessageRef.current has been cleared");
            }
        });



        wsClient.setCallback('messageDoneReceived', (messageDone) => {
            console.log('messageDoneReceived:', messageDone);
            if (currentMessageRef.current) {
                currentMessageRef.current = null;
                setIsLoading(false);
                console.log(" currentMessageRef.current has been cleared");
            }
        });
        wsClient.setCallback('onErrorReceived', (error) => {
            console.log('onErrorReceived:', error);
        });

        wsClient.connect();



        // Cleanup on unmount
        return () => {
            wsClient.disconnect();
        };
    }, []);

    const updateLastMessage = (message) => {

        if (!currentMessageRef.current) {

            console.log(" currentMessageRef.current created via updateLastMessage", message);
            currentMessageRef.current = {
                id: Date.now(), // or any unique identifier
                content: {
                    text: ""
                },
                created_at: Math.floor(Date.now() / 1000),
                role: 'assistant'
            };
            setIsLoading(true);
            setMessages((prevMessages) => [...prevMessages, currentMessageRef.current]);
        }

        setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            const lastIndex = updatedMessages.length - 1;
            updatedMessages[lastIndex] = { ...updatedMessages[lastIndex], ...message };
            currentMessageRef.current = updatedMessages[lastIndex]
            return updatedMessages;
        });
    }


    const onEventHandler = (event) => {
        switch (event.status) {
            case "queued":
                console.log(" currentMessageRef.current created via queued");
                currentMessageRef.current = {
                    id: Date.now(), // or any unique identifier
                    content: {
                        text: ""
                    },
                    role: 'assistant',
                    created_at: Math.floor(Date.now() / 1000),
                    status: "Queueing",
                    status_code: event.status
                };
                setIsLoading(true);
                setMessages((prevMessages) => [...prevMessages, currentMessageRef.current]);

                break;
            case "in_progress":
                updateLastMessage({ status: "In Progress", status_code: event.status })
                break;
            case "coding":
                updateLastMessage({ status: "Writting a Code", status_code: event.status })
                break;
            case "generating":
                updateLastMessage({ status: "Generating", status_code: event.status })
                break;
            case "validating":
                updateLastMessage({ status: "Validating Data", status_code: event.status })
                break;
            case "completed":
                updateLastMessage({ status: "Completed", status_code: event.status })

                setMessages((prevMessages) => {
                    const updatedMessages = [...prevMessages];
                    const lastIndex = updatedMessages.length - 1;
                    updatedMessages[lastIndex] = { ...updatedMessages[lastIndex], content: { text: updatedMessages[lastIndex].content.text + "\n\n" } };
                    return updatedMessages;
                });

                break;
        }


        // messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    useEffect(() => {
        // messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const getWsClient = () => {
        return wsClientRef.current;
    };

    const onSubmitMessage = (message) => {
        if (message) {
            console.log(getWsClient());
            getWsClient().sendMessage({
                "action": "createThread",
                "data": {
                    "message": message
                }
            });

            const newMessages = [
                ...messages,
                {
                    content: {
                        text: message
                    },
                    role: 'user',
                    created_at: Math.floor(Date.now() / 1000)
                }
            ];

            setMessages(newMessages);
            setInputPrompt("");
        }
    };

    return (
        <div className='row' id='create-analysis-report'>
            <div className="col-md-10 offset-md-1 ca-container">
                <div className="row mt-4 ca-result-container">
                    <ThreadListComponent messages={messages} isLoading={isLoading} />
                </div>
                <div className="row ca-input-container">
                    <div className="col-12">
                        <div className="input-prompt mt-1">
                            <textarea
                                value={inputPrompt}
                                onChange={(e) => { setInputPrompt(e.target.value); }}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter' && !event.shiftKey) {
                                        event.preventDefault();
                                        onSubmitMessage(inputPrompt);
                                    }
                                }}
                                placeholder="Type here and press Enter, or Shift+Enter for a new line"
                            />
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button className="btn btn-primary me-md-2" type="button" onClick={() => onSubmitMessage(inputPrompt)}>Send</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={messageEndRef} />
            </div>
        </div>
    );
}

const ThreadListComponent = (props) => {
    const { isLoading, messages } = props;

    const messageEndRef = useRef(null);

    useEffect(() => {
        // messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const config = {
        showEditInChartStudio: true,
        plotlyServerURL: "https://chart-studio.plotly.com",
        scrollZoom: true,
        editable: true,
        displaylogo: false,
    };


    const generateChart = (message) => {
        if (message?.charts?.length) {
            const chartData = message?.charts[0]?.data?.data || message?.charts[0]?.chart?.data || message?.charts[0]?.data || message?.charts[0]?.chart;
            const layoutData = message?.charts[0]?.data?.layout || message?.charts[0]?.chart?.layout || message?.charts[0]?.layout;
            if (chartData) {
                return (
                    <Plot
                        data={typeof chartData === 'string' ? JSON.parse(chartData) : chartData}
                        layout={{ ...layoutData, autosize: true, title: 'Generated Interactive Chart (Double click to edit)' }}
                        config={config}
                    />
                );
            } else {
                return <p><b>Generated chart is invalid</b></p>;
            }
        }
        return null;
    };

    return (
        <div className="col-12">
            <div className="list-group" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                {

                    messages.map((message, index) => (
                        <div className="list-group-item border-0" key={index}>

                            <div className="d-flex w-100 justify-content-between">
                                <h6 className="mb-1"><b>{message.role === 'assistant' ? "SynergyBot" : "You"}</b></h6>
                                <small>{humanizeDateDiff(message.created_at * 1000)}</small>
                            </div>
                            {generateChart(message)}
                            {/* {
                                message?.charts?.length > 0 && message?.charts[0]?.data ?
                                    <Plot
                                        data={message?.charts[0]?.data}
                                        layout={{ ...message?.charts[0]?.data?.layout, autosize: true, title: 'Generated Interactive Chart (Double click to edit)' }}
                                        config={config}
                                    /> : <></>
                            }
                            {
                                message?.charts?.length > 0 && message?.charts[0]?.chart?.data ?
                                    <Plot
                                        data={message?.charts[0]?.chart?.data}
                                        layout={{ ...message?.charts[0]?.chart?.data?.layout, autosize: true, title: 'Generated Interactive Chart (Double click to edit)' }}
                                        config={config}
                                    /> : <></>
                            }
                            {
                                message?.charts?.length > 0 && message?.charts[0]?.data?.data ?
                                    <Plot
                                        data={message?.charts[0]?.data?.data}
                                        layout={{ ...message?.charts[0]?.data?.data?.layout, autosize: true, title: 'Generated Interactive Chart (Double click to edit)' }}
                                        config={config}
                                    /> : <></>
                            } */}
                            <p>
                                <Markdown>{message.content.text}</Markdown>
                            </p>
                            {
                                isLoading && message?.status_code ? <span class="badge text-bg-primary">{message?.status}</span> : <></>
                            }


                        </div>
                    ))
                }
                <div ref={messageEndRef} />
            </div>
        </div>
    );
}
