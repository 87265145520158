import { ServerAPIUrl, env, LIVE, STAGGING } from "../pages/Request/Env";
import { RequestErrorHandler } from "../pages/Request/ErrorHandler";

function createWebSocketUrl(httpUrl) {
    let wsProtocol = (env === LIVE) ? 'wss://' : 'ws://';
    return httpUrl.replace(/^http(s)?:\/\//, wsProtocol);
}

function startLoginStatusCheck(token) {
    const wsUrl = createWebSocketUrl(ServerAPIUrl) + "/api/login/check-status";
    let ws = new WebSocket(wsUrl);
    let statusCheckInterval;

    function sendStatusCheck() {
        if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({ token: token }));
        } else {
            // console.log("WebSocket is not open.");
        }
    }

    ws.onopen = () => {
        // console.log("WebSocket connected.");
        statusCheckInterval = setInterval(sendStatusCheck, 1000); // Send status check every second
    };

    ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if(data.status === 0 ){
            RequestErrorHandler(-1);
        }
    };

    ws.onerror = (error) => {
        console.log("WebSocket error:", error);
    };

    ws.onclose = () => {
        // console.log("WebSocket disconnected. Attempting to reconnect...");
        clearInterval(statusCheckInterval); // Clear the existing interval
        setTimeout(() => startLoginStatusCheck(token), 3000);
    };
}

export default startLoginStatusCheck;
