import Header from "./header";
import SideBar from "./sidebar";
import { Outlet } from "react-router-dom";

import {  ToastContainer } from 'react-toastify';
const Template = (props) => {
    const { user } = props;
    return (<div className="hold-transition sidebar-mini" >
        <div className="wrapper">
            <ToastContainer />
            {/* <Header user={user}/> */}
            <SideBar user={user} />
            <Outlet />
        </div>
    </div>)
}

export {
    Template
}