import { ServerAPIUrl } from "./Env";
const uploadAsset = async (file, callback) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        fetch(ServerAPIUrl+'/api/upload', {
            method: 'POST',
            body: formData
        }).then(res => res.json())
            .then(result => {
                callback(result)
            })
            .catch(err => {
                // throw new Error('Failed to upload file');
                console.error(err);
            });;

    } catch (error) {
        console.error(error);
    }
}

const saveScene = (data, callback) => {
    fetch(ServerAPIUrl+"/api/scene/save", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .then(result => {
            callback(result)
        })
        .catch(err => {
            console.error(err);
        });
}

const getScene = (data) => {
    return fetch(ServerAPIUrl+"/api/scene/get", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        // .then(result => {
        //     callback(result)
        // })
        .catch(err => {
            console.error(err);
        });
}

const deleteScene = (data) => {
    return fetch(ServerAPIUrl+"/api/delete/scene", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(err => {
            console.error(err);
        });
}

export { uploadAsset,saveScene,getScene,deleteScene }