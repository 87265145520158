import React, { useEffect, useState, useRef } from 'react';
const LoginForm = (props) => {
    const [usernameInput, setUsernameInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");

    return <>
        <input type="text" placeholder="USERNAME" onChange={(e) => setUsernameInput(e.target.value)} />
        <input type="password" placeholder="PASSWORD" onChange={(e) => setPasswordInput(e.target.value)} />
        <button class="submit" onClick={()=>props.Submit(usernameInput, passwordInput)}>LET'S GO</button></>;
}

export {
    LoginForm
}