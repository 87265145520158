import React, { useEffect, useState } from 'react';
import { SelectV2 } from '../select';
import { addTelegramBotToken, removeTelegramBotToken, verifyTelegramBotToken } from '../../pages/Request/Chatbot';
export function Integrations(props) {
    const { user, setIntegrationsConfig, integrationsConfig, chatbotToken } = props
    const [verifiedStatus, setVerifiedStatus] = useState();
    const [token, setToken] = useState(integrationsConfig?.telegram?.token);
    const [botInfo, setBotInfo] = useState();

    useEffect(() => {
        if(integrationsConfig?.telegram?.token){
            verifyToken(integrationsConfig?.telegram?.token)
        }
    }, [])

    const verifyToken = async (token) => {
        setVerifiedStatus({})
        const res = await verifyTelegramBotToken({
            token: token
        }, user.token)
        console.log(res);
        setVerifiedStatus(res)
        if (res.botInfo) {
            setBotInfo(res.botInfo)
        }
    }

    const startBot = async () => {
        const res = await addTelegramBotToken({
            token: token,
            chatbot_token: chatbotToken
        }, user.token)
        if (!res.error) {
            setIntegrationsConfig((integrationsConfig) => ({ ...integrationsConfig, telegram: { ...integrationsConfig?.telegram, token: token } }))
        }
        setVerifiedStatus(res)
        console.log(res);
    }

    const removeBot = async () => {

        setVerifiedStatus({ loading: { message: "Please wait... stopping and removing the bot."} })
        const res = await removeTelegramBotToken({
            token: token
        }, user.token)
        if (!res.error) {
            setToken("")
            setBotInfo()
            setVerifiedStatus({ status: { status: res.status } })
            setIntegrationsConfig((integrationsConfig) => ({ ...integrationsConfig, telegram: { ...integrationsConfig?.telegram, token: "" } }))
        }
        console.log(res);

    }


    const onChangeTelegramTokenInput = (val) => {
        setToken(val)

    }


    return (
        <>
            <div className='row justify-content-center mt-3'>
                <div class="row">
                    <div className='col'>
                        <h4>Integration Settings</h4>
                        <p>This is your one-stop destination for configuring various integrations, including Telegram. Connect your external services and platforms here to enhance the capabilities of your chatbot. Easily manage and customize each integration to fit your specific needs and workflows.</p>
                    </div>
                </div>
                {
                    chatbotToken ?
                        <div class="row">
                            <div class="col">
                                <div class="pb-3 form-group">
                                    <h6>Telegram Bot</h6>
                                    <p>Just add your bot token to link it with this chatbot. Once connected, your Telegram bot will seamlessly integrate, enabling direct interactions with users through this platform.</p>
                                    <div class="row form-group m-2">
                                        <div class="col">
                                            <div class="">
                                                <div class="form-check">
                                                    <label for="exampleInputEmail1" class="form-label">Bot Token:</label>
                                                    <div class="input-group mb-1">
                                                        <input type="text" disabled={botInfo ? 'disabled' : ''} class={`form-control ${verifiedStatus?.botInfo ? 'is-valid' : ''}  ${verifiedStatus?.error ? 'is-invalid' : ''}`} value={token} onChange={(e) => { onChangeTelegramTokenInput(e.target.value) }} placeholder="Enter Your Telegram Bot Token" />
                                                        {
                                                            integrationsConfig?.telegram?.token ?
                                                                <button class="btn btn-outline-danger" disabled={verifiedStatus?.loading ? 'disabled' : ""} onClick={removeBot} type="button" >Remove</button>
                                                                :
                                                                (verifiedStatus?.botInfo ?
                                                                    <button class="btn btn-outline-success" onClick={startBot} type="button" >Connect</button>
                                                                    :
                                                                    <button class="btn btn-outline-primary" onClick={() => { verifyToken(token) }} type="button" >Validate</button>)

                                                        }
                                                    </div>

                                                    <div class="form-text text-danger">
                                                        {verifiedStatus?.status?.error}
                                                        {verifiedStatus?.error ? verifiedStatus?.message : ""}
                                                    </div>
                                                    <div class="form-text text-success">
                                                        {verifiedStatus?.status?.status}
                                                    </div>

                                                    <div class="form-text text-dark">
                                                        {verifiedStatus?.loading?.message}
                                                    </div>
                                                    {
                                                        botInfo ?
                                                            <>
                                                                <p className='m-0'>Telegram Bot Name: <b>{botInfo.first_name}</b></p>
                                                                <p>Username: <a target='_blank' href={`https://web.telegram.org/k/#@${botInfo.username}`}><b>@{botInfo.username}</b></a></p>
                                                            </>
                                                            : <></>
                                                    }


                                                    <div class="form-text">
                                                        This unique identifier connects your application with the Telegram API. Find it in the BotFather chat after creating your bot.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div class="row">
                            <div class="col">
                                <div class="alert alert-dark" role="alert">
                                    Please save your chatbot before configuring your integrations.
                                </div>
                            </div>
                        </div>
                }

            </div >

        </>
    )
}



